import React, { useEffect, useState } from 'react';
import './App.css';
import theme from './theme';
import { Provider } from 'react-redux';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import { Grommet, Box, Layer, Text, Button } from 'grommet';
import store from './store';
import { debouncedRefreshToken } from './auth';
import navItems, { isAuthorizedRoute } from './nav/nav-items';
import ToastsContainer from './toasts/toasts.container';
import Loader from './components/loader';
import { Lock } from 'grommet-icons';
import { getPermissions } from './auth';
import TabNavigation from './nav/tab-navigation';
import TopNavigation from './nav/top-navigation';
import ErrorFallback from './components/errors/error-fallback';
import { getConfig } from './environment-config/environment-config';

export let configuredStore = null;

const routes = Object.values(navItems)
  .filter(i => i.path)
  .sort((a, b) => b.path?.length - a.path?.length); //shorter paths last because first route match wins in switch

const RestorePrompt = ({ onConfirm, onDeny }) => (
  <Grommet
    theme={theme}
    style={{
      display: 'flex',
      flex: 1,
      width: '100%',
      height: '100%',
      flexDirection: 'column'
    }}
  >
    <Layer position='center' modal={true}>
      <Box pad='large' gap='large'>
        <Text>
          You have unsaved progress from your last session.
          <br />
          Would you like to restore?
        </Text>
        <Box direction='row' gap='medium' align='center' justify='end'>
          <Button label='No' primary onClick={onDeny} data-testid='No' />
          <Button label='Yes' onClick={onConfirm} data-testid='Yes' />
        </Box>
      </Box>
    </Layer>
  </Grommet>
);

const StatefulApp = ({ rebuildFromStorage, cachedDirtyState }) => {
  const [initialResourcesLoaded, setInitialResourcesLoaded] = useState(false);
  useEffect(() => {
    Promise.all([getPermissions(), getConfig()]).then(() => {
      setInitialResourcesLoaded(true);
    });
  }, []);

  if (!initialResourcesLoaded) {
    return <Loader />;
  }

  return (
    <Provider
      store={store(
        rebuildFromStorage && !!cachedDirtyState ? cachedDirtyState : {}
      )}
    >
      <Grommet
        theme={theme}
        style={{
          display: 'flex',
          flex: 1,
          width: '100%',
          height: '100%',
          flexDirection: 'row'
        }}
      >
        <Router>
          <TabNavigation />
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <DndProvider backend={Backend}>
              <Box fill overflow='auto' direction='column'>
                <TopNavigation />
                <Routes>
                  {routes.map(r => (
                    <Route
                      key={r.path}
                      path={r.path}
                      element={
                        isAuthorizedRoute(r) ? (
                          r.component
                        ) : (
                          <Box fill align='center' justify='center' gap='small'>
                            <Lock size='xlarge' />
                            <Text size='large'>Unauthorized</Text>
                          </Box>
                        )
                      }
                    />
                  ))}
                </Routes>
              </Box>
            </DndProvider>
          </ErrorBoundary>
        </Router>
        <ToastsContainer />
      </Grommet>
    </Provider>
  );
};

const App = () => {
  const [rebuildFromStorage, setRebuildFromStorage] = useState();
  const cachedDirtyState = JSON.parse(localStorage.getItem('cachedDirtyState'));

  window.addEventListener('keyup', debouncedRefreshToken);
  window.addEventListener('mousemove', debouncedRefreshToken);

  if (typeof rebuildFromStorage === 'undefined' && !!cachedDirtyState) {
    return (
      <RestorePrompt
        onConfirm={() => {
          setRebuildFromStorage(true);
        }}
        onDeny={() => {
          setRebuildFromStorage(false);
          localStorage.removeItem('cacheDirtyState');
        }}
      />
    );
  } else {
    return (
      <StatefulApp
        rebuildFromStorage={rebuildFromStorage}
        cachedDirtyState={cachedDirtyState}
      />
    );
  }
};

export default App;
