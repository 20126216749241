import React, { useContext } from 'react';
import { Box, Text } from 'grommet';
import { CustomThemeContext } from './adunit-theme';
import {
  SELECT_TEXT_COLOR_ONE,
  SELECT_TEXT_COLOR_TWO,
  SELECT_TEXT_COLOR_THREE,
  AD_UNIT_THEME
} from './adunit-theme-constant';
import ColorPicker from '../../../../../components/color-picker';
import { parseCSSProperties } from '../../../traffic-partner-utils';

const AdunitAccentTextOnly = () => {
  const { state, dispatch, canEdit } = useContext(CustomThemeContext);

  return (
    <Box gap='small'>
      <ColorPicker
        label='Text Color 1'
        color={parseCSSProperties(state.textColorOne.css.properties)}
        onChange={newValue => {
          dispatch({
            type: SELECT_TEXT_COLOR_ONE,
            payload: AD_UNIT_THEME.textColors.textColorOne(newValue)
          });
        }}
        canEdit={canEdit}
      />
      <ColorPicker
        label='Text Color 2'
        color={parseCSSProperties(state.textColorTwo.css.properties)}
        onChange={newValue => {
          dispatch({
            type: SELECT_TEXT_COLOR_TWO,
            payload: AD_UNIT_THEME.textColors.textColorTwo(newValue)
          });
        }}
        canEdit={canEdit}
      />
      <ColorPicker
        label='Text Color 3'
        color={parseCSSProperties(state.textColorThree.css.properties)}
        onChange={newValue => {
          dispatch({
            type: SELECT_TEXT_COLOR_THREE,
            payload: AD_UNIT_THEME.textColors.textColorThree(newValue)
          });
        }}
        canEdit={canEdit}
      />
    </Box>
  );
};

export default AdunitAccentTextOnly;
