import { createAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import * as itemTypes from '../../items/item-types';
import {
  getItem as getItemInternal,
  saveItem as saveItemInternal
} from '../../items/item.actions';

export const receiveCallCampaign = createAction('receiveCallCampaign');
export const setCallCampaignIsBusy = createAction('setCallCampaignIsBusy');
export const modifyCallCampaign = createAction('modifyCallCampaign');
export const closeCallCampaign = createAction('closeCallCampaign');

const callCampaignModel = (id, name) => ({
  id,
  name,
  type: itemTypes.CALL_CAMPAIGN
});

export const getItem = getItemInternal(
  callCampaignModel,
  receiveCallCampaign,
  setCallCampaignIsBusy,
  item => {
    item.callTrackingTags = Object.entries(item.callTrackingTags).map(
      ([key, value]) => {
        return { id: uuidv4(), key: value.name, value: value.value };
      }
    );
  }
);

export const saveItem = saveItemInternal(
  modifyCallCampaign,
  undefined,
  item => {
    item.callTrackingTags = item.callTrackingTags?.reduce(
      (obj, item) => Object.assign(obj, { [item.key]: item.value }),
      {}
    );
  }
);
