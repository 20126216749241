import React from 'react';
import { Box } from 'grommet';
import { Ad } from 'grommet-icons';

const AdGroup = ({ width = '24px', height = '24px' }) => {
  return (
    <Box style={{ position: 'relative', width, height }}>
      <Ad
        size='xsmall'
        style={{
          position: 'absolute',
          left: '-5px',
          top: '-5px',
          transform: 'scale(0.7)'
        }}
      />
      <Ad
        size='xsmall'
        style={{
          position: 'absolute',
          left: '5px',
          top: '5px',
          transform: 'scale(0.7)'
        }}
      />
    </Box>
  );
};

AdGroup.displayName = 'AdGroup';

export default AdGroup;
