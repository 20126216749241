import React, { useEffect, useState } from 'react';
import { Box } from 'grommet';
import { sortBy } from 'lodash';
import ReferencedItems from './referenced-items';
import RowItem from '../row-item';
import { authorizedFetch, cleanDate } from '../../utils.js';
import * as itemTypes from '../../items/item-types';

const CampaignDetail = ({ item }) => {
  const {
    name,
    description,
    categories,
    dateModified,
    lastModifiedBy,
    version,
    advertiserName,
    goal,
    dateCreated
  } = item;
  const [assignedFlows, setAssignedFlows] = useState([]);
  const [activeAdGroups, setActiveAdGroups] = useState([]);
  const [inactiveAdGroups, setInactiveAdGroups] = useState([]);

  const pathBuilder = item => {
    return `/${itemTypes.CAMPAIGN}/${item.campaignId}/${itemTypes.AD_GROUP}/${item.id}`;
  };

  useEffect(() => {
    setAssignedFlows([]);

    authorizedFetch(`/api/search/eventflow-flowreadmodel/_search`, 'POST', {
      _source: ['id', 'name'],
      query: {
        terms: {
          'id.keyword': item.flowIds
        }
      }
    })
      .then(response => {
        const assignedFlows = sortBy(
          response.hits.hits.map(h => h._source),
          e => e.name
        );
        setAssignedFlows(assignedFlows);
      })
      .catch(e => console.log(e));
  }, [item.flowIds]);

  const getAdGroups = async () => {
    try {
      const adGroups = await authorizedFetch(
        `api/${itemTypes.CAMPAIGN}/${item.id}/${itemTypes.AD_GROUP}/search`,
        'POST',
        {
          from: 0,
          size: 10000,
          orderByAsc: true
        }
      );

      return adGroups;
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    setActiveAdGroups([]);
    setInactiveAdGroups([]);

    getAdGroups().then(adGroups => {
      setActiveAdGroups(adGroups.items.filter(ag => ag.status === 'active'));
      setInactiveAdGroups(
        adGroups.items.filter(ag => ag.status === 'inactive')
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  return (
    <>
      <Box flex={false}>
        <RowItem label='Name' value={name} />
        <RowItem label='Desc' value={description} truncate={false} />
        <RowItem label='Advertiser' value={advertiserName} />
        <RowItem label='Goal' value={goal} />
        <RowItem
          label='Tags'
          value={Array.isArray(categories) && categories.join(', ')}
          truncate={false}
        />
        <RowItem
          label='Created'
          value={dateCreated && cleanDate(dateCreated)}
        />
        <RowItem
          label='Modified'
          value={dateModified && cleanDate(dateModified)}
        />
        <RowItem label='By' value={lastModifiedBy} />
        <RowItem label='Version' value={version} />
      </Box>

      <Box margin={{ top: 'large' }} overflow='auto'>
        <ReferencedItems
          title='Assigned Flows:'
          itemType={itemTypes.FLOW}
          items={assignedFlows}
        />

        <ReferencedItems
          title='Active Ad Groups:'
          itemType={itemTypes.AD_GROUP}
          items={activeAdGroups}
          pathBuilder={pathBuilder}
        />
        <ReferencedItems
          title='Inactive Ad Groups:'
          itemType={itemTypes.AD_GROUP}
          items={inactiveAdGroups}
          pathBuilder={pathBuilder}
        />
      </Box>
    </>
  );
};

export default CampaignDetail;
