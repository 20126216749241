import React, { useState } from 'react';
import { Box, Text } from 'grommet';
import ImageUploader from '../../../../components/image-uploader';
import { readFile } from '../../traffic-partner-utils';
import { FTTextInput } from '../../../../components/inputs';

export const REMOVE_LOGO_FIELDS = {
  logoUrl: 'removeLogo',
  mobileLogoUrl: 'removeMobileLogo'
};

const PartnerLogo = ({ props }) => {
  const [isDesktopImageRemoved, setIsDesktopImageRemoved] = useState(false);
  const [isMobileImageRemoved, setIsMobileImageRemoved] = useState(false);
  const { item, modifyItem, addError } = props;

  const handleUpload = async (thumbnailFile, imageField) => {
    if (thumbnailFile === null || thumbnailFile === undefined) {
      modifyItem({
        [REMOVE_LOGO_FIELDS[imageField]]: true,
        [imageField]: null
      });
    } else {
      try {
        const data = await readFile(thumbnailFile);
        modifyItem({
          [imageField]: data
        });
      } catch (error) {
        addError('Image upload failed', error.Message);
      }
    }
  };

  return (
    <Box gap='small'>
      <Text size='large'>Partner Logos</Text>
      <Text>
        Allowed file types: JPG, GIF, PNG. We recommend using a transparent PNG.
        1MB max file size.
      </Text>
      <Box direction='row' gap='medium'>
        <Box>
          <Box margin={{ bottom: 'xsmall' }}>
            <Text weight='bold'>Desktop Logo</Text>
          </Box>
          <ImageUploader
            imageUrl={item.thumbnailDataUrl || item.logoUrl}
            onFileUpload={thumbnailFile => {
              handleUpload(thumbnailFile, 'logoUrl');
            }}
            isImageRemoved={isDesktopImageRemoved}
            setIsImageRemoved={setIsDesktopImageRemoved}
          />
        </Box>
        <Box>
          <Box margin={{ bottom: 'xsmall' }}>
            <Text weight='bold'>Mobile Logo</Text>
          </Box>
          <ImageUploader
            imageUrl={item.mobileLogoUrl}
            onFileUpload={thumbnailFile => {
              handleUpload(thumbnailFile, 'mobileLogoUrl');
            }}
            isImageRemoved={isMobileImageRemoved}
            setIsImageRemoved={setIsMobileImageRemoved}
          />
        </Box>
      </Box>
      <FTTextInput
        label='Alt Text '
        value={item.logoAltText}
        title='Alt text for the image'
        tipContent='Enter a brief description of the partner logo for accessibility for example: Fluent Logo'
        onChange={newValue => {
          modifyItem({ logoAltText: newValue });
        }}
      />
    </Box>
  );
};

export default PartnerLogo;
