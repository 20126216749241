import { createAction } from '@reduxjs/toolkit';
import * as itemTypes from '../../../items/item-types';
import {
  getItem as getItemInternal,
  saveItem as saveItemInternal
} from '../../../items/item.actions';
import { keyFromItemType } from '../../../routing-utils';
import { authorizedFetch, isItemNew } from '../../../utils';
import { REMOVE_LOGO_FIELDS } from './adunit-styling/partner-logo';
import { initialState } from './adunit-styling/adunit-theme/adunit-theme';
import { generateCssBlob } from '../traffic-partner-utils';
import { IMPLEMENTATION_TYPES_LABELS } from './embedded-flow-config-grid';

export const DEFAULT_AD_UNIT_NUMBER_OFFERS = 4;
export const EMBEDONPAGE = 'embedonpage';
export const PAGEOVERLAY = 'pageoverlay';

export const receiveEmbeddedFlowConfig = createAction(
  'receiveEmbeddedFlowConfig'
);
export const setEmbeddedFlowConfigIsBusy = createAction(
  'setEmbeddedFlowConfigIsBusy'
);
export const modifyEmbeddedFlowConfig = createAction(
  'modifyEmbeddedFlowConfig'
);
export const closeEmbeddedFlowConfig = createAction('closeEmbeddedFlowConfig');

export const EmbeddedFlowConfigModel = (id, name, params) => ({
  id,
  name,
  type: itemTypes.EMBEDDED_FLOW_CONFIG,
  [keyFromItemType(itemTypes.TRAFFIC_PARTNER)]: params.trafficpartnerid,
  customCss: generateCssBlob(initialState(null)), // initialize with default css from adunit-theme
  adUnitTemplateId: '',
  adUnitNumberOffers: DEFAULT_AD_UNIT_NUMBER_OFFERS,
  requestingUrlContainsFilters: [
    {
      url: '',
      useForIntegration: false
    }
  ]
});

async function uploadAdUnitImage(
  imageDataArray,
  trafficPartnerId,
  id,
  version,
  dispatch,
  modifyItemAction
) {
  const uploadedImageData = [];
  const dispatchItemObj = {
    id: id,
    newProperties: {}
  };

  try {
    for (const { type, url } of imageDataArray) {
      const formData = new FormData();
      const imageData = await fetch(url).then(r => r.blob());
      formData.append('file', imageData);
      formData.append('type', type === 'mobileLogoUrl' ? 'mobile' : '');
      formData.append(
        'expectedVersion',
        dispatchItemObj.newProperties.version
          ? dispatchItemObj.newProperties.version // get latest version if an image was just uploaded
          : version
      );
      const response = await authorizedFetch(
        `/api/${itemTypes.TRAFFIC_PARTNER}/${trafficPartnerId}/embeddedFlowConfig/${id}/upload`,
        'POST',
        formData,
        null,
        'multipart/form-data'
      );
      uploadedImageData.push(response);
      dispatchItemObj.newProperties[type] = response.data.url;
      dispatchItemObj.newProperties[REMOVE_LOGO_FIELDS[type]] = false;
      dispatchItemObj.newProperties.version = response.version;
      dispatchItemObj.newProperties.id = response.id;
    }
    await dispatch(modifyItemAction(dispatchItemObj));
    return uploadedImageData;
  } catch (err) {
    return {
      isError: true,
      primary: `Unable to upload image.`,
      secondary: err.Message
    };
  }
}

export const getItem = getItemInternal(
  EmbeddedFlowConfigModel,
  receiveEmbeddedFlowConfig,
  setEmbeddedFlowConfigIsBusy,
  item => {
    const requestingUrlContainsFilters =
      item?.requestingUrlContainsFilters || [];
    const testRequestingUrlContainsFilters =
      item?.testRequestingUrlContainsFilters || [];
    const result = requestingUrlContainsFilters
      .map(url => ({
        url,
        useForIntegration: requestingUrlContainsFilters.includes(url)
          ? false
          : true
      }))
      .concat(
        testRequestingUrlContainsFilters.map(url => ({
          url,
          useForIntegration: testRequestingUrlContainsFilters.includes(url)
            ? true
            : false
        }))
      );
    item.requestingUrlContainsFilters = result;
  }
);

function validateDuplicate(array = []) {
  const urlSet = new Set();
  for (const item of array) {
    if (urlSet.has(item.url.toLowerCase())) {
      return true;
    }
    urlSet.add(item.url.toLowerCase());
  }
  return false;
}

export const saveItem = (() => {
  let imageDataArray = [];

  return saveItemInternal(
    modifyEmbeddedFlowConfig,
    item => {
      if (!item.trafficSourceId) {
        return 'Traffic Source is required.';
      }
      if (!item.flowId && item.adUnitTemplateId === null) {
        return 'Assigned Flow is required.';
      }
      if (!item.publishedFlowUrl && item.adUnitTemplateId === null) {
        return 'Assigned Flow Domain is required.';
      }
      if (!item.implementationType) {
        return 'Implementation Type is required.';
      }
      if (
        item.implementationType === IMPLEMENTATION_TYPES_LABELS.adUnitConfig
      ) {
        if (item.adUnitNumberOffers < 1 || item.adUnitNumberOffers > 20) {
          return 'Quantity of offers shown must be between 1 and 20.';
        }
      }
      if (
        isItemNew(item.id) &&
        Array.isArray(item.requestingUrlContainsFilters) &&
        item.requestingUrlContainsFilters.some(
          filter => filter.url.trim() === ''
        )
      ) {
        return 'Publisher Website Targeting is required.';
      }
      if (validateDuplicate(item.requestingUrlContainsFilters)) {
        return 'Publisher Website Targeting cannot have duplicate values.';
      }
      if ((item.logoUrl || item.mobileLogoUrl) && !item.logoAltText) {
        return 'Alt text is required for the image.';
      }
    },
    async itemCopy => {
      if (itemCopy.logoUrl?.startsWith('data:')) {
        imageDataArray.push({ type: 'logoUrl', url: itemCopy.logoUrl });
        itemCopy.logoUrl = null;
      }
      if (itemCopy.mobileLogoUrl?.startsWith('data:')) {
        imageDataArray.push({
          type: 'mobileLogoUrl',
          url: itemCopy.mobileLogoUrl
        });
        itemCopy.mobileLogoUrl = null;
      }

      const requestingUrlContainsFilters = [];
      const testRequestingUrlContainsFilters = [];
      if (Array.isArray(itemCopy.requestingUrlContainsFilters)) {
        itemCopy.requestingUrlContainsFilters.forEach(obj => {
          if (obj.useForIntegration) {
            testRequestingUrlContainsFilters.push(obj.url);
          } else {
            requestingUrlContainsFilters.push(obj.url);
          }
        });
        itemCopy.requestingUrlContainsFilters = requestingUrlContainsFilters;
        itemCopy.testRequestingUrlContainsFilters = testRequestingUrlContainsFilters;
      }
    },
    async (item, { id, version }, dispatch, modifyItemAction) => {
      const { trafficPartnerId } = item;
      let expectedVersion = version;

      if (imageDataArray.length > 0) {
        const thumbnailResponse = await uploadAdUnitImage(
          imageDataArray,
          trafficPartnerId,
          id,
          expectedVersion,
          dispatch,
          modifyItemAction
        );

        imageDataArray.length = 0;
        expectedVersion = thumbnailResponse.version;
      }
    }
  );
})();
