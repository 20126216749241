import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Box, Button, Text } from 'grommet';
import { Desktop, Edit } from 'grommet-icons';
import { generateNewId } from '../utils';
import { itemTypeNames } from './item-types';
import ItemGrid, { DEFAULT_COLUMNS } from '../components/item-grid';
import { hasPermission } from '../auth';
import { searchItemsByType } from './items.actions';
import { debounce } from 'lodash';

const actionButtons = [
  {
    icon: <Desktop />,
    path: 'info'
  },
  {
    icon: <Edit />,
    path: 'editor'
  }
];

const ActionIcon = (itemType, item) => ({ icon, path }) => (
  <NavLink
    data-testid={`${itemType}-${path} Link`}
    to={`/${itemType}/${item.id}/${path}`}
    style={{ color: 'inherit', textDecoration: 'inherit' }}
    key={`${item.id}-${path}`}
  >
    {icon}
  </NavLink>
);

const Items = ({ itemType, addError, columns }) => {
  const navigate = useNavigate();
  return (
    <Box
      data-testid={`${itemTypeNames[itemType]}s Screen`}
      flex
      margin='medium'
    >
      <ItemGrid
        testType={itemType}
        onError={addError}
        itemTypes={[itemType]}
        onItemDoubleClick={item => {
          navigate(`/${itemType}/${item.id}/info`);
        }}
        headerButtons={
          hasPermission(itemType, 'Create') && (
            <NavLink
              data-testid={`New ${itemTypeNames[itemType]} Link`}
              to={`/${itemType}/${generateNewId()}/info`}
              style={{ color: 'inherit', textDecoration: 'inherit' }}
            >
              <Button
                data-testid={`New ${itemTypeNames[itemType]} Button`}
                label={`New ${itemTypeNames[itemType]}`}
                primary
              />
            </NavLink>
          )
        }
        columns={
          columns || [
            ...DEFAULT_COLUMNS,
            {
              property: 'actions',
              header: 'Actions',
              width: '100px',
              render: item => (
                <Box gap='small' direction='row'>
                  {actionButtons.map(ActionIcon(itemType, item))}
                </Box>
              )
            }
          ]
        }
      />
    </Box>
  );
};

export default Items;

export const getSuggestions = (
  searchValue,
  itemType,
  excludeIds,
  setSuggestions,
  filter
) => {
  searchItemsByType({
    itemTypes: Array.isArray(itemType) ? itemType : [itemType],
    additionalFilters: filter,
    query: searchValue,
    excludeIds,
    size: 10
  })
    .then(({ items }) => {
      setSuggestions(items.map(getSuggestionItem));
    })
    .catch(err => {
      console.log(
        'Unable to retrieve Items',
        err && err.message ? err.message : JSON.stringify(err)
      );
    });
};

export const getSuggestionItem = item => {
  return {
    label: (
      <Box pad='small' direction='row' gap='small' align='center'>
        <Text title={item.name} truncate>
          {item.name}
        </Text>
      </Box>
    ),
    value: item
  };
};

export const getDebouncedSuggestions = debounce(getSuggestions, 200);
