import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Text } from 'grommet';

const ReferencedItems = ({ title, itemType, pathBuilder, items = [] }) => {
  if (items.length > 0) {
    return (
      <Box margin={{ vertical: 'small' }} flex='grow'>
        <Text>{title}</Text>
        <Box>
          {items.map(item => (
            <Box key={item.id} pad='xsmall' flex={{ shrink: 0 }}>
              <NavLink
                data-testid={`Ref ${itemType} Link`}
                to={
                  typeof pathBuilder === 'function'
                    ? pathBuilder(item)
                    : `/${itemType}/${item.id}/info`
                }
              >
                <Text truncate>{item.name}</Text>
              </NavLink>
            </Box>
          ))}
        </Box>
      </Box>
    );
  }
  return null;
};

export default ReferencedItems;
