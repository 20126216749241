import { v4 as uuidv4 } from 'uuid';

export const targetIndices = {
  EVENT: 'event'
};

export const conditionTypes = {
  BOOLEAN_CONDITION: 'booleanCondition',
  EVENT_PROPERTY_CONDITION: 'eventPropertyCondition',
  NOT_CONDITION: 'notCondition'
};

export const dropPositions = {
  BEFORE: 'BEFORE',
  AFTER: 'AFTER',
  INTO: 'INTO'
};

export const APPLICABLE_EVENT_TYPES = [
  {
    label: 'Flow View',
    value: 'flowView',
    condition: {
      type: 'booleanCondition',
      parameterValues: {
        operator: 'and',
        subConditions: [
          {
            type: 'eventTypeCondition',
            parameterValues: {
              eventTypeId: 'view'
            }
          },
          {
            type: 'eventPropertyCondition',
            parameterValues: {
              propertyName: 'target.itemType',
              propertyValue: 'flow',
              comparisonOperator: 'equals'
            }
          }
        ]
      }
    }
  },
  {
    label: 'Campaign View',
    value: 'offerView',
    condition: {
      type: 'booleanCondition',
      parameterValues: {
        operator: 'and',
        subConditions: [
          {
            type: 'eventTypeCondition',
            parameterValues: {
              eventTypeId: 'view'
            }
          },
          {
            type: 'eventPropertyCondition',
            parameterValues: {
              propertyName: 'target.itemType',
              propertyValue: 'offer',
              comparisonOperator: 'equals'
            }
          }
        ]
      }
    }
  },
  {
    label: 'Campaign Click',
    value: 'offerClick',
    condition: {
      type: 'booleanCondition',
      parameterValues: {
        operator: 'and',
        subConditions: [
          {
            type: 'eventTypeCondition',
            parameterValues: {
              eventTypeId: 'click'
            }
          },
          {
            type: 'eventPropertyCondition',
            parameterValues: {
              propertyName: 'target.itemType',
              propertyValue: 'offer',
              comparisonOperator: 'equals'
            }
          }
        ]
      }
    }
  },
  {
    label: 'Campaign Convert',
    value: 'offerConvert',
    condition: {
      type: 'booleanCondition',
      parameterValues: {
        operator: 'and',
        subConditions: [
          {
            type: 'eventTypeCondition',
            parameterValues: {
              eventTypeId: 'convert'
            }
          },
          {
            type: 'eventPropertyCondition',
            parameterValues: {
              propertyName: 'target.itemType',
              propertyValue: 'offer',
              comparisonOperator: 'equals'
            }
          }
        ]
      }
    }
  },
  {
    label: 'Registration',
    value: 'register',
    condition: {
      type: 'booleanCondition',
      parameterValues: {
        operator: 'and',
        subConditions: [
          {
            type: 'eventTypeCondition',
            parameterValues: {
              eventTypeId: 'submit'
            }
          },
          {
            type: 'eventPropertyCondition',
            parameterValues: {
              propertyName: 'target.itemType',
              propertyValue: 'form',
              comparisonOperator: 'equals'
            }
          },
          {
            type: 'eventPropertyCondition',
            parameterValues: {
              propertyName: 'properties.isFullReg',
              propertyValue: 'true',
              comparisonOperator: 'equals'
            }
          }
        ]
      }
    }
  }
];

export const eventConditionModel = () => {
  return {
    parameterValues: {
      propertyName: '',
      comparisonOperator: 'in',
      propertyValues: []
    },
    type: conditionTypes.EVENT_PROPERTY_CONDITION,
    _id: uuidv4()
  };
};

export const ruleEventBooleanConditionModel = () => {
  return {
    parameterValues: {
      subConditions: [],
      operator: 'and'
    },
    _id: uuidv4()
  };
};

export const booleanConditionModel = () => {
  return {
    parameterValues: {
      subConditions: [],
      operator: 'and'
    },
    type: conditionTypes.BOOLEAN_CONDITION,
    _id: uuidv4()
  };
};

export const ruleEventConditionModel = ruleEventCondition => {
  const eventCondition = ruleEventBooleanConditionModel();
  if (ruleEventCondition) {
    eventCondition.parameterValues.subConditions.push(
      ruleEventCondition.condition
    );
  }

  return {
    ...eventCondition,
    _id: uuidv4(),
    type: conditionTypes.BOOLEAN_CONDITION
  };
};

// future me: this is assuming the subCondition is created using buildSourceIdRuleConition below.
export const getSourceIdDisplayCondition = condition => {
  //split out properties.url.queryParams.sourceid.
  if (condition?.parameterValues?.subConditions?.length > 1) {
    return condition.parameterValues.subConditions[1];
  } else {
    return condition;
  }
};

export const buildSourceIdRuleCondition = propertyValues => {
  return {
    type: 'booleanCondition',
    parameterValues: {
      operator: 'or',
      subConditions: [
        {
          type: 'booleanCondition',
          parameterValues: {
            operator: 'and',
            subConditions: [
              {
                type: 'eventPropertyCondition',
                parameterValues: {
                  propertyName: 'properties.url.queryParams.sourceid',
                  comparisonOperator: 'missing'
                }
              },
              {
                type: 'eventPropertyCondition',
                parameterValues: {
                  propertyName: 'properties.url.queryParams.subaff2',
                  propertyValues: propertyValues,
                  comparisonOperator: 'in'
                }
              }
            ]
          }
        },
        {
          type: 'eventPropertyCondition',
          parameterValues: {
            propertyName: 'properties.url.queryParams.sourceid',
            propertyValues: propertyValues,
            comparisonOperator: 'in'
          }
        }
      ]
    }
  };
};
