import React from 'react';
import { Box } from 'grommet';

const AdunitPreview = ({ templateType }) => {
  const renderTemplate = templateType => {
    switch (templateType) {
      case 'Progression|Overlay':
        return 'adunit-templates/progression-overlay/progression-overlay.html';
      case 'Progression|PageEmbed':
        return 'adunit-templates/progression-pageembed/progression-pageembed.html';
      case 'Progression|SDKEmbed':
        return 'adunit-templates/progression-pageembed-sdk/progression-pageembed-sdk.html';
      case 'Stacked|Overlay':
        return 'adunit-templates/stacked-overlay/stacked-overlay.html';
      case 'Stacked|PageEmbed':
        return 'adunit-templates/stacked-pageembed/stacked-pageembed.html';
      default:
        return 'adunit-templates/custom-adunit-template.html';
    }
  };

  return (
    <Box
      border='border400'
      pad='small'
      round='small'
      fill='horizontal'
      margin={{ right: 'small' }}
    >
      <iframe
        id='previewFrame'
        src={renderTemplate(templateType)}
        title='Preview'
        height='100%'
        style={{
          border: 'none'
        }}
      ></iframe>
    </Box>
  );
};

export default AdunitPreview;
