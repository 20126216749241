import React, { useEffect, useState } from 'react';
import { Box, Grid, Text, CheckBox, TextInput, Button, Tip } from 'grommet';
import { FTSelect, FTTextInput } from '../../../components/inputs';
import { FormClose, CircleQuestion } from 'grommet-icons';
import * as itemTypes from '../../../items/item-types';
import { authorizedFetch, getItemsList } from '../../../utils';
import ConfigurationTemplateButton from './configuration-template-button';
import AdUnitTemplatePickerModal from './ad-unit-template-picker-modal';

export const IMPLEMENTATION_TYPES = [
  { label: 'Page Overlay', value: 'pageoverlay' },
  { label: 'Embed on Page', value: 'embedonpage' }
];

export const getFlowPublications = async (flowId, previewOnly = false) => {
  try {
    let response = await authorizedFetch(
      `/api/flow/${flowId}/publications?previewOnly=${previewOnly}`,
      'GET'
    );

    return response;
  } catch (e) {
    throw new Error(e);
  }
};

export const getPartnerTrafficSources = async trafficPartnerId => {
  try {
    const response = await authorizedFetch(
      'api/search/eventflow-trafficsourcereadmodel/_search',
      'POST',
      {
        _source: ['id', 'name'],
        size: 10000,
        sort: {
          dateModified: 'desc'
        },
        query: {
          term: {
            'trafficPartnerId.keyword': trafficPartnerId
          }
        }
      }
    );
    const trafficSources = response?.hits?.hits
      ? response.hits.hits.map(d => d._source)
      : [];
    const remappedSourceList = trafficSources.map(source => {
      return { label: source.name, id: source.id };
    });
    return remappedSourceList;
  } catch (e) {
    throw new Error(e.error.reason);
  }
};

const EmbeddedFlowConfigInfo = props => {
  const {
    item,
    modifyItem,
    addError,
    canEdit,
    adUnitTemplates,
    customAdUnitTemplates,
    addMessage,
    removeToast
  } = props;

  const {
    trafficSourceId,
    flowId,
    publishedFlowUrl,
    requestingUrlContainsFilters,
    enabled,
    trafficPartnerId,
    implementationType,
    adUnitNumberOffers,
    adUnitTemplateId,
    adUnitOptimizelyIntegration,
    campaignPlacementId
  } = item;

  const [trafficSourceList, setTrafficSourceList] = useState([]);
  const [filteredTrafficSourceList, setFilteredTrafficSourceList] = useState(
    []
  );
  const [flowList, setFlowList] = useState([]);
  const [filteredFlowList, setFilteredFlowList] = useState([]);
  const [placementList, setPlacementList] = useState([]);
  const [filteredPlacementList, setFilteredPlacementList] = useState([]);
  const [domainList, setDomainList] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const loadItems = async () => {
      try {
        const partnerTrafficSources = await getPartnerTrafficSources(
          trafficPartnerId
        );

        const fetchedFlowList = await getItemsList(itemTypes.FLOW);
        const fetchedPlacementList = await getItemsList(
          itemTypes.CAMPAIGN_PLACEMENT
        );

        const remappedFlowList = fetchedFlowList.map(flow => {
          return { label: flow.name, id: flow.id };
        });
        const remappedPlacementList = fetchedPlacementList.map(placement => {
          return { label: placement.name, id: placement.id };
        });

        setTrafficSourceList(partnerTrafficSources);
        setFilteredTrafficSourceList(partnerTrafficSources);
        setFlowList(remappedFlowList);
        setFilteredFlowList(remappedFlowList);
        setPlacementList(remappedPlacementList);
        setFilteredPlacementList(remappedPlacementList);
      } catch (e) {
        addError('There was an error getting config data', e.message);
      }
    };

    loadItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (flowId) {
      const loadDomains = async () => {
        const fetchedFlowPublications = await getFlowPublications(flowId);
        setDomainList(
          fetchedFlowPublications.map(pub => {
            return { label: pub.publicationName, url: pub.url };
          })
        );
      };

      loadDomains();
    }
  }, [flowId]);

  const addNewFilter = () => {
    const newFilterList = [
      ...requestingUrlContainsFilters,
      {
        url: '',
        useForIntegration: false
      }
    ];
    modifyItem({
      requestingUrlContainsFilters: newFilterList
    });
  };

  return (
    <Box fill overflow={{ vertical: 'auto' }} pad='medium'>
      <Grid
        columns={['repeat(3, minmax(250px, 1fr))']}
        gap='medium'
        justifyContent='start'
      >
        <Box gridArea='auto/span 3'>
          <ConfigurationTemplateButton
            adUnitTemplates={adUnitTemplates}
            customAdUnitTemplates={customAdUnitTemplates}
            embeddedFlowConfig={item}
            setOpenModal={setOpenModal}
          />
        </Box>
        <FTSelect
          disabled={!canEdit}
          title={`Select a traffic source`}
          placeholder={
            trafficSourceList.length > 0
              ? `Select a traffic source`
              : 'Assign or create a traffic source for this partner'
          }
          labelKey='label'
          valueKey='id'
          options={filteredTrafficSourceList}
          value={trafficSourceList.find(s => s.id === trafficSourceId)}
          label='Traffic Source'
          onChange={({ id }) => {
            modifyItem({
              trafficSourceId: id
            });
          }}
          handleSearch={search => {
            const lowercaseSearch = search.toLowerCase();
            if (search === '') {
              setFilteredTrafficSourceList(trafficSourceList);
            } else {
              setFilteredTrafficSourceList(
                trafficSourceList.filter(source => {
                  const sourceLabel = source.label.toLowerCase();
                  return sourceLabel.includes(lowercaseSearch);
                })
              );
            }
          }}
          onClose={() => {
            setFilteredTrafficSourceList(trafficSourceList);
          }}
        />
        {adUnitTemplateId === null && (
          <FTSelect
            disabled={!canEdit}
            title={`Select a flow`}
            placeholder={`Select a flow`}
            labelKey='label'
            valueKey='id'
            options={filteredFlowList}
            value={flowList.find(f => f.id === flowId)}
            label='Assigned Flow'
            onChange={({ id }) => {
              modifyItem({
                flowId: id
              });
            }}
            handleSearch={search => {
              const lowercaseSearch = search.toLowerCase();
              if (search === '') {
                setFilteredFlowList(flowList);
              } else {
                setFilteredFlowList(
                  flowList.filter(flow => {
                    const flowLabel = flow.label.toLowerCase();
                    return flowLabel.includes(lowercaseSearch);
                  })
                );
              }
            }}
            onClose={() => {
              setFilteredFlowList(flowList);
            }}
          />
        )}
        {adUnitTemplateId === null && (
          <FTSelect
            title={`Select a domain`}
            placeholder={`Select a domain`}
            labelKey='label'
            valueKey='url'
            options={domainList}
            value={domainList.find(d => d.url === publishedFlowUrl)}
            label='Assigned Flow Domain'
            onChange={({ url }) => {
              modifyItem({
                publishedFlowUrl: url
              });
            }}
            disabled={!flowId || !canEdit}
          />
        )}
        {adUnitTemplateId === null && (
          <FTSelect
            title={`Select implementation type`}
            placeholder={`Select implementation type`}
            labelKey='label'
            valueKey='value'
            options={IMPLEMENTATION_TYPES}
            value={IMPLEMENTATION_TYPES.find(
              it => it.value === implementationType
            )}
            label='Implementation Type'
            onChange={({ value }) => {
              modifyItem({
                implementationType: value
              });
            }}
            disabled={!flowId || !canEdit}
          />
        )}
        {adUnitTemplateId !== null && (
          <FTTextInput
            disabled={!canEdit}
            label='Quantity of offers shown (only editable by an admin)'
            value={adUnitNumberOffers}
            min={1}
            max={20}
            type='number'
            onChange={value => {
              modifyItem({ adUnitNumberOffers: parseInt(value) });
            }}
          />
        )}
        <Box direction='column' gap='small'>
          <Text>Status</Text>
          <CheckBox
            disabled={!canEdit}
            toggle
            checked={enabled}
            onChange={event => {
              modifyItem({ enabled: event.target.checked });
            }}
          />
          {enabled && <Text color='green'>Active</Text>}
        </Box>
        <Box gap='xsmall' gridArea='auto/span 2'>
          <Box justify='between' direction='row'>
            <Text>Publisher Website Targeting</Text>
            <Button onClick={addNewFilter}>+ Add New</Button>
          </Box>
          {requestingUrlContainsFilters.map((filter, index) => {
            return (
              <Box key={index} direction='row' align='center'>
                <Box width='120px'>
                  {index === 0 ? (
                    <Text>URL contains:</Text>
                  ) : (
                    <Text>OR contains:</Text>
                  )}
                </Box>
                <Box
                  width='50%'
                  direction='row'
                  align='center'
                  border={{ color: 'border400' }}
                  round='xsmall'
                >
                  <TextInput
                    disabled={!canEdit}
                    plain
                    value={filter.url ?? ''}
                    onChange={({ target }) => {
                      const newFilters = [...requestingUrlContainsFilters];
                      newFilters[index] = {
                        url: target.value,
                        useForIntegration: filter.useForIntegration ?? false
                      };
                      modifyItem({
                        requestingUrlContainsFilters: [...newFilters]
                      });
                    }}
                  />
                  {index !== 0 && (
                    <Button
                      disabled={!canEdit}
                      style={{ display: 'flex' }}
                      plain
                      onClick={() => {
                        const newFilters = [...requestingUrlContainsFilters];
                        newFilters.splice(index, 1);
                        modifyItem({
                          requestingUrlContainsFilters: [...newFilters]
                        });
                      }}
                    >
                      <FormClose />
                    </Button>
                  )}
                </Box>
                {adUnitTemplateId !== null && (
                  <Box pad='small'>
                    <CheckBox
                      disabled={!canEdit}
                      label='use for integration testing (pre-prod env)'
                      checked={filter.useForIntegration}
                      onChange={event => {
                        const newFilters = [...requestingUrlContainsFilters];
                        newFilters[index] = {
                          ...newFilters[index],
                          useForIntegration: event.target.checked
                        };
                        modifyItem({
                          requestingUrlContainsFilters: [...newFilters]
                        });
                      }}
                    />
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
        {adUnitTemplateId !== null && (
          <>
            <Box
              border={{ side: 'top', color: 'border400' }}
              gridArea='auto/span 3'
            />
            <FTSelect
              disabled={!canEdit}
              title={`Select a placement`}
              placeholder={`Select a placement`}
              labelKey='label'
              valueKey='id'
              options={filteredPlacementList}
              value={placementList.find(p => p.id === campaignPlacementId)}
              label='Placement'
              onChange={({ id }) => {
                modifyItem({
                  campaignPlacementId: id
                });
              }}
              handleSearch={search => {
                const lowercaseSearch = search.toLowerCase();
                if (search === '') {
                  setFilteredPlacementList(placementList);
                } else {
                  setFilteredPlacementList(
                    placementList.filter(placement => {
                      const placementLabel = placement.label.toLowerCase();
                      return placementLabel.includes(lowercaseSearch);
                    })
                  );
                }
              }}
              onClose={() => {
                setFilteredPlacementList(placementList);
              }}
            />
            <Box
              border={{ side: 'top', color: 'border400' }}
              gridArea='auto/span 3'
            />
            <Box margin={{ top: 'small' }} direction='row' gap='small'>
              <Text>
                Test with Optimizely{' '}
                <Tip
                  width='medium'
                  content='If Active, the Optimizely script will be included in the configuration template.'
                >
                  <span>
                    <CircleQuestion size='small' />
                  </span>
                </Tip>
              </Text>
              <CheckBox
                disabled={!canEdit}
                toggle
                checked={adUnitOptimizelyIntegration}
                onChange={event => {
                  modifyItem({
                    adUnitOptimizelyIntegration: event.target.checked
                  });
                }}
              />
              {adUnitOptimizelyIntegration && <Text color='green'>Active</Text>}
            </Box>
          </>
        )}
      </Grid>
      {openModal && (
        <AdUnitTemplatePickerModal
          modifyItem={modifyItem}
          setModalOpen={setOpenModal}
          adUnitTemplates={adUnitTemplates}
          customAdUnitTemplates={customAdUnitTemplates}
          addMessage={addMessage}
          currentTemplate={adUnitTemplateId}
          wasLegacyTemplate={!!flowId}
          publishedFlowUrl={publishedFlowUrl}
          requestingUrlContainsFilters={requestingUrlContainsFilters}
          adUnitNumberOffers={adUnitNumberOffers}
          flowId={flowId}
          removeToast={removeToast}
        />
      )}
    </Box>
  );
};

EmbeddedFlowConfigInfo.displayName = 'EmbeddedFlowConfigInfo';

export default EmbeddedFlowConfigInfo;
