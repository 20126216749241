import React from 'react';
import {
  Box,
  Text,
  Select,
  TextArea,
  TextInput,
  Tip,
  CheckBox,
  Button
} from 'grommet';
import { CircleQuestion } from 'grommet-icons';

import TagSelector from './tag-selector';

export const LabeledInput = ({
  label,
  children,
  legacy = false,
  width = '400px',
  size = 'medium',
  margin,
  tipContent,
  onAddNewOption,
  ...delegated
}) => {
  if (legacy) margin = { bottom: 'medium', right: 'large' };

  if (!tipContent && !label) {
    return children;
  }

  const labelJsx = tipContent ? (
    <Text size={size}>
      <Tip content={tipContent} dropProps={{ align: { bottom: 'right' } }}>
        <Box direction='row' width='fit-content'>
          {label}
          <span>
            <CircleQuestion size='small' style={{ marginLeft: '3px' }} />
          </span>
        </Box>
      </Tip>
    </Text>
  ) : (
    <Text size={size}>{label}</Text>
  );

  return (
    <Box margin={margin} gap='xsmall' {...delegated}>
      {onAddNewOption &&
      typeof onAddNewOption === 'function' &&
      label !== 'Placement' ? (
        <Box direction='row' justify='between'>
          {labelJsx}
          <Button onClick={onAddNewOption}>+ Add New</Button>
        </Box>
      ) : (
        labelJsx
      )}
      {children}
    </Box>
  );
};

export const FTSelect = ({
  label,
  options = [],
  value = '',
  onChange,
  labelKey,
  valueKey,
  disabled,
  disabledKey,
  emptySearchMessage,
  placeholder,
  multiple,
  messages,
  handleSearch,
  handleOpen,
  onAddNewOption,
  closeOnChange = true,
  size = 'medium',
  name,
  ...delegated
}) => (
  <LabeledInput label={label} onAddNewOption={onAddNewOption} {...delegated}>
    <Select
      {...delegated}
      placeholder={placeholder}
      closeOnChange={closeOnChange}
      size={size}
      name={name?.toLowerCase() || label?.toLowerCase()}
      options={options}
      value={value}
      onChange={option => onChange(option.value)}
      labelKey={labelKey}
      valueKey={valueKey}
      disabled={disabled}
      disabledKey={disabledKey}
      multiple={multiple}
      messages={messages}
      emptySearchMessage={emptySearchMessage}
      onSearch={handleSearch}
      onOpen={handleOpen}
      children={(option, _, __, { selected }) => {
        // (option, index, options, { active, disabled, selected }) => {}
        let customOptionLabel;
        if (typeof labelKey === 'function') {
          customOptionLabel = labelKey(option);
        }
        return (
          <FTSelectOption
            label={customOptionLabel ?? option[labelKey] ?? option}
            selected={selected}
            multiple={multiple}
          />
        );
      }}
    />
  </LabeledInput>
);
FTSelect.displayName = 'FTSelect';

const FTSelectOption = React.memo(({ label, selected, multiple }) => (
  <Box
    direction='row'
    gap='small'
    align='center'
    pad='small'
    background={!multiple && selected && 'brand'}
  >
    {multiple && <CheckBox tabIndex='-1' checked={selected} />}
    {label}
  </Box>
));

export const FTTextArea = ({
  height = '5em',
  label,
  value,
  onChange,
  disabled,
  placeholder,
  onBlur,
  name,
  resize = true,
  ...delegated
}) => {
  if (value === null) {
    value = '';
  }
  return (
    <LabeledInput label={label} {...delegated}>
      <TextArea
        height={height}
        resize={resize}
        style={{ height }}
        value={value}
        onChange={e => onChange(e.target.value)}
        disabled={disabled}
        name={name?.toLowerCase() || label?.toLowerCase()}
        placeholder={placeholder}
        onBlur={e => {
          if (typeof onBlur === 'function') {
            onBlur(e);
          } else if (
            typeof onChange === 'function' &&
            e.target.value.trim() !== e.target.value
          ) {
            onChange(e.target.value.trim());
          }
        }}
      />
    </LabeledInput>
  );
};
FTTextArea.displayName = 'FTTextArea';

export const FTTextInput = ({
  label,
  value = '',
  onChange,
  disabled,
  title,
  type = 'text',
  min,
  max,
  icon,
  reverse,
  step,
  onBlur,
  placeholder,
  name,
  prefixLabel,
  ...delegated
}) => {
  return (
    <LabeledInput label={label} {...delegated}>
      <Box direction='row' align='center'>
        {prefixLabel && <Text>{prefixLabel}</Text>}
        <TextInput
          size='medium'
          value={value}
          onChange={e => onChange(e.target.value)}
          disabled={disabled}
          name={name?.toLowerCase() || label?.toLowerCase()}
          title={title}
          type={type}
          min={min}
          max={max}
          icon={icon}
          reverse={reverse}
          step={step}
          onBlur={e => {
            if (typeof onBlur === 'function') {
              onBlur(e);
            } else if (
              typeof onChange === 'function' &&
              e.target.value.trim() !== e.target.value
            ) {
              onChange(e.target.value.trim());
            }
          }}
          placeholder={placeholder}
        />
      </Box>
    </LabeledInput>
  );
};
FTTextInput.displayName = 'FTTextInput';

// size="medium" is implicitly set in TagSelector, so we don't need to include it here
export const FTTagInput = ({
  label,
  tags = [],
  onChange,
  disabled,
  suggestions,
  onTextChange,
  onBulkChange,
  bulkDelimiter,
  placeholder,
  tagTextBuilder,
  ...delegated
}) => {
  return (
    <LabeledInput label={label} {...delegated}>
      <TagSelector
        tags={tags}
        onTagAdd={t => onChange([...tags, t])}
        onTagRemove={t => {
          const newTags = [...tags];
          const existingTagIndex = newTags.indexOf(t);
          newTags.splice(existingTagIndex, 1);
          onChange(newTags);
        }}
        disabled={disabled}
        suggestions={suggestions}
        onTextChange={onTextChange}
        onBulkAdd={onBulkChange}
        bulkDelimiter={bulkDelimiter}
        placeholder={placeholder}
        tagTextBuilder={tagTextBuilder}
      />
    </LabeledInput>
  );
};
FTTagInput.displayName = 'FTTagInput';
