import React, { useEffect, useState } from 'react';
import { Box } from 'grommet';
import ReferencedItems from './referenced-items';
import RowItem from '../row-item';
import { authorizedFetch, cleanDate } from '../../utils.js';
import * as itemTypes from '../../items/item-types';

const UserAttributeDetail = ({ item }) => {
  const {
    name,
    description,
    categories,
    dateModified,
    lastModifiedBy,
    version
  } = item;
  const [usedBy, setUsedBy] = useState([]);

  useEffect(() => {
    setUsedBy([]);

    if (item.code) {
      authorizedFetch(`/api/search/eventflow-surveyreadmodel/_search`, 'POST', {
        _source: ['id', 'name'],
        query: {
          match_phrase: {
            surveyJson: `"valueName":"${item.code}"`
          }
        }
      })
        .then(response => {
          setUsedBy(response.hits.hits.map(h => h._source));
        })
        .catch(e => console.log(e));
    }
  }, [item.code]);

  return (
    <>
      <Box flex={false}>
        <RowItem label='Name' value={name} />
        <RowItem label='Desc' value={description} truncate={false} />
        <RowItem
          label='Tags'
          value={Array.isArray(categories) && categories.join(', ')}
          truncate={false}
        />
        <RowItem
          label='Modified'
          value={dateModified && cleanDate(dateModified)}
        />
        <RowItem label='By' value={lastModifiedBy} />
        <RowItem label='Version' value={version} />
      </Box>
      <Box margin={{ top: 'large' }} overflow='auto'>
        <ReferencedItems
          title='Used By:'
          itemType={itemTypes.SURVEY}
          items={usedBy}
        />
      </Box>
    </>
  );
};

export default UserAttributeDetail;
