import React, {
  memo,
  useCallback,
  useRef,
  useState,
  forwardRef,
  useMemo,
  useEffect
} from 'react';
import { Box, Button, Heading, Text, DropButton } from 'grommet';
import { Ascend, Descend, FormDown } from 'grommet-icons';
import Spinner from './spinner';
import { downloadCsv } from '../reporting-utils';
import { buildPathForitem } from '../routing-utils';
import './simple-table.css';
import {
  DraggingProvider,
  useColumnDragging
} from '../hooks/useColumnDragging';
import { useVirtual } from 'react-virtual';

const SortIcon = ({ direction, style }) => {
  const iconStyle = {
    width: '15px',
    height: '20px',
    marginRight: '5px',
    ...style
  };
  return direction === 'asc' ? (
    <Ascend style={iconStyle} />
  ) : (
    <Descend style={iconStyle} />
  );
};

const findItemInRow = row => {
  if (row.itemType || row.type) {
    return row;
  }

  const keys = Object.keys(row);
  for (const key of keys) {
    // we could recurse, but i dont think that is required
    if (typeof row[key] === 'object') {
      const obj = row[key];
      if (obj?.itemType || obj?.type) {
        return obj;
      }
    }
  }
};

export const generateCellData = gridElement => {
  let cellData = '';
  // Add exporting class which hides elements with the class 'export-omit'
  gridElement.classList.add('is-exporting');
  gridElement.querySelectorAll('.row').forEach(r => {
    const rowCells = r.querySelectorAll('.cell');

    rowCells.forEach((c, ci) => {
      let cleanedText = c.innerText.split('\n').join(' ');

      //if cell data contains comma, wrap the contents in quotes
      if (cleanedText.indexOf(',') !== -1) {
        cleanedText = `"${cleanedText}"`;
      }
      cellData += cleanedText;
      if (ci !== rowCells.length - 1) {
        cellData += ',';
      }
    });
    cellData += '\r\n';
  });
  // Clean up and remove class
  gridElement.classList.remove('is-exporting');
  return cellData;
};

export const TableHeader = ({ children }) => (
  <Box
    direction='row'
    border={{ side: 'bottom', color: 'border500' }}
    flex={{ shrink: 0 }}
    className='row'
  >
    {children}
  </Box>
);

const renderCellTitle = (rowData, column) => {
  if (column.property === 'deviceTypes') {
    const transform = rowData[column.property]?.map(obj => {
      switch (obj.value.toLowerCase()) {
        case 'android':
          return { value: 'Android' };
        case 'ios':
          return { value: 'iOS' };
        case 'desktop':
          return { value: 'Web' };
        default:
          return obj;
      }
    });
    return transform?.map(d => d.value).join(', ');
  } else {
    return rowData[column.property];
  }
};

export const ColumnHeader = ({
  column,
  onHeaderClick,
  searchFilter,
  searchOrder,
  isLast,
  index,
  tablebgColor
}) => {
  const {
    onResizeStart,
    widthMap,
    isResizing,
    draggingEnabled
  } = useColumnDragging();
  const headerRef = useRef(null);

  const style = useMemo(() => {
    let style = {
      position: 'relative',
      userSelect: 'none'
    };

    if (typeof column.headerStyle === 'object') {
      Object.assign(style, column.headerStyle);
    }

    if (widthMap[index]) {
      style.width = widthMap[index];
    } else {
      column.width ? (style.width = column.width) : (style.flex = '1 1');
    }

    return style;
  }, [column.headerStyle, column.width, index, widthMap]);

  return (
    <Box
      ref={headerRef}
      key={column.property}
      pad={{ horizontal: 'small' }}
      style={style}
      focusIndicator={false}
      className='cell'
      background={tablebgColor}
      justify='center'
    >
      <Box
        onClick={() => {
          onHeaderClick && onHeaderClick(column);
        }}
        style={{
          cursor: isResizing ? 'col-resize' : 'pointer'
        }}
      >
        <Heading
          level={5}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBlockStart: '1em',
            marginBlockEnd: '1em'
          }}
          truncate
        >
          {typeof column.sort !== 'undefined' &&
            column.sort === searchFilter && (
              <SortIcon direction={searchOrder} />
            )}
          {typeof column.header === 'function'
            ? column.header()
            : column.header}
        </Heading>
      </Box>
      {!isLast && draggingEnabled && (
        <Box
          onMouseDown={e =>
            onResizeStart(e, index, headerRef.current?.clientWidth)
          }
          height='100%'
          width='10px'
          border={{
            color: 'border400',
            size: 'xsmall',
            style: 'solid',
            side: 'right'
          }}
          style={{
            alignSelf: 'center',
            content: '',
            position: 'absolute',
            right: 0,
            top: 0,
            cursor: 'col-resize'
          }}
        />
      )}
    </Box>
  );
};

export const Row = ({
  rowData,
  index,
  children,
  testId,
  onClick,
  onDoubleClick,
  isSelected,
  tablebgColor
}) => {
  const styleObj =
    children.find(child => child.key === 'commitMessage') !== undefined
      ? { minHeight: '50px' }
      : { height: '50px' };

  return (
    <Box
      data-testid={
        rowData?.name ||
        rowData?.adGroup?.name ||
        rowData?.adCreative?.name ||
        `row-${index}`
      }
      direction='row'
      // key={rowKey}
      style={{ ...styleObj, alignItems: 'center' }}
      onMouseUp={onClick}
      focusIndicator={false}
      onDoubleClick={onDoubleClick}
      flex={{ shrink: 0 }}
      background={
        isSelected ? '#E9E9E9' : index % 2 === 0 ? 'white300' : tablebgColor
      }
      className='row'
    >
      {children}
    </Box>
  );
};

export const Column = ({ column, rowData, index }) => {
  const { widthMap } = useColumnDragging();
  return (
    <Box
      pad='small'
      style={Object.assign(
        // cascade dragged width => predefined column width => flex
        widthMap[index]
          ? { width: `${widthMap[index]}px` }
          : column.width
          ? { width: column.width }
          : { flex: '1 1' },
        typeof column.getStyle === 'function' ? column.getStyle(rowData) : {}
      )}
      fill
      justify='center'
      border={{
        color: 'border500',
        size: 'xsmall',
        style: 'solid',
        side: 'right'
      }}
      className='cell'
      title={renderCellTitle(rowData, column)}
    >
      {typeof column.render === 'function' ? (
        column.render(rowData)
      ) : (
        <Text
          truncate={!column.wrapText}
          style={{ maxHeight: '40px', overflow: 'hidden' }}
        >
          {rowData[column.property]}
        </Text>
      )}
    </Box>
  );
};

const MemoRowComponent = memo(
  ({
    columns,
    delegateMouseClick,
    index,
    onItemDoubleClick,
    onSelectItem,
    onSelectItems,
    row,
    RowComponent,
    selectedItem,
    selectedItems,
    tablebgColor,
    testType
  }) => {
    const isSelectedRow = selectedItem && selectedItem === row;

    return (
      <RowComponent
        data-testid={row.name}
        key={`key_${index}`}
        rowData={row}
        onClick={e => {
          //we have defined a selectedItems prop from outside, we want to keep track of multiple
          if (Array.isArray(selectedItems)) {
            if (e.ctrlKey || e.metaKey) {
              const matchingIndex = selectedItems.indexOf(row);
              const newItems = [...selectedItems];
              //already exists in the selected collection, need to remove it
              if (matchingIndex !== -1) {
                newItems.splice(matchingIndex, 1);
              } else {
                newItems.push(row);
              }
              onSelectItems(newItems);
            } else {
              onSelectItems([row]);
            }
          } else if (onSelectItem) {
            delegateMouseClick(e, row);
          }
        }}
        onDoubleClick={() => onItemDoubleClick && onItemDoubleClick(row)}
        isSelected={isSelectedRow}
        index={index}
        tablebgColor={tablebgColor}
      >
        {columns.map((c, idx) => (
          <Column index={idx} column={c} rowData={row} key={c.property} />
        ))}
      </RowComponent>
    );
  }
);

// using the arrow function as a default param was causing rerender on row virtualization. Quite annoying.
const defaultOnItemDoubleClick = () => {};

// SimpleTable accepts an optional ref
// so the contents can be exported outside of the component body.
// note that this retains our default export behaviour inside
// the component body
const SimpleTable = forwardRef(
  (
    {
      columns,
      data = [],
      keyBuilder = r => r.id,
      selectedItem,
      selectedItems,
      onSelectItem,
      onSelectItems,
      onItemDoubleClick = defaultOnItemDoubleClick,
      noItemsText,
      SelectedRowRenderer,
      additionalSelectedRowProps = {},
      searchFilter = 'dateModified',
      searchOrder = 'desc',
      onHeaderClick,
      style = { position: 'relative' },
      exportButtonStyle = {},
      exportFileName = 'export',
      testType,
      rowComponent,
      columnDragging = true,
      loadingData,
      tablebgColor = 'white400',
      useVirtualRows = false //sets up react-virtual and hides the export DropButton
    },
    ref
  ) => {
    const RowComponent = rowComponent || Row;
    const _selectedItems = Array.isArray(selectedItems)
      ? selectedItems
      : selectedItem
      ? [selectedItem]
      : [];

    // Since ref is passed optionally, create a local ref to use as a fallback
    const localRef = useRef(null);
    const parentRef = useRef(null);
    const gridRef = ref || localRef;

    // needed to use useCallback so rows wouldn't rerender
    const delegateMouseClick = useCallback(
      (e, row) => {
        e.preventDefault();
        e.stopPropagation();

        // There is no guarantee that the row will be an `item`
        // So we try to find the item in the row to continue
        const item = findItemInRow(row);
        // If the `item` is found, handle the event as if the user may be trying to open it in a new tab
        // otherwise select the row as usual
        if (item) {
          // buildPathForItem returns the relative path, so we need to construct the rest of it by hand
          const path = buildPathForitem(item);
          const url = `http://${window.location.host}/#${path}`;
          switch (e.button) {
            case 0: // left mouse button, item select callback
              if (e.ctrlKey || e.metaKey) {
                window.open(url, '_blank');
              } else if (onSelectItem) {
                onSelectItem(row);
              }
              break;
            case 1: // middle mouse button, open link in new tab (switches focus depending on browser)
              window.open(url, '_blank');
              break;
            default:
              break;
          }
        } else {
          onSelectItem(row);
        }
      },
      [onSelectItem]
    );

    const rowVirtualizer = useVirtual({
      size: data.length,
      parentRef: parentRef,
      overscan: 30
    });

    const [columnHeaders, setColumnHeaders] = useState([]);

    useEffect(() => {
      const headers = columns.map((c, idx) => {
        return (
          <ColumnHeader
            key={c.property}
            column={c}
            onHeaderClick={onHeaderClick}
            searchFilter={searchFilter}
            searchOrder={searchOrder}
            isLast={idx === columns.length - 1}
            index={idx}
            tablebgColor={tablebgColor}
          />
        );
      });

      setColumnHeaders(headers);
    }, [columns, searchFilter, searchOrder, onHeaderClick, tablebgColor]);

    // needed to use useCallback so rows wouldn't rerender
    const handleItemDoubleClick = useCallback(row => onItemDoubleClick(row), [
      onItemDoubleClick
    ]);

    // needed to use useCallback so rows wouldn't rerender
    const handleOnSelectItem = useCallback(
      row => {
        if (typeof onSelectItem === 'function') {
          onSelectItem(row);
        }
      },
      [onSelectItem]
    );

    // needed to use useCallback so rows wouldn't rerender
    const handleOnSelectItems = useCallback(
      rows => {
        if (typeof onSelectItems === 'function') {
          onSelectItems(rows);
        }
      },
      [onSelectItems]
    );

    let rows = [];

    if (useVirtualRows) {
      rows = rowVirtualizer.virtualItems.map(virtualRow => {
        const row = data[virtualRow.index];
        const rowKey = keyBuilder(row);
        const isSelectedRow = _selectedItems.indexOf(row) !== -1;
        if (isSelectedRow && SelectedRowRenderer) {
          return (
            <SelectedRowRenderer
              {...row}
              key={rowKey}
              {...additionalSelectedRowProps}
            />
          );
        }

        return (
          <div
            key={virtualRow.index}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '50px',
              transform: `translateY(${virtualRow.start}px)`
            }}
          >
            <MemoRowComponent
              row={data[virtualRow.index]}
              index={virtualRow.index}
              RowComponent={RowComponent}
              testType={testType}
              columns={columns}
              tablebgColor={tablebgColor}
              selectedItem={selectedItem}
              selecteditems={selectedItems}
              delegateMouseClick={delegateMouseClick}
              onItemDoubleClick={handleItemDoubleClick}
              onSelectItem={onSelectItem ? handleOnSelectItem : null}
              onselectitems={onSelectItems ? handleOnSelectItems : null}
              rowKey={rowKey}
            />
          </div>
        );
      });
    } else {
      rows = data.map((row, rowIndex) => {
        const rowKey = keyBuilder(row);
        const isSelectedRow = _selectedItems.indexOf(row) !== -1;
        if (isSelectedRow && SelectedRowRenderer) {
          return (
            <SelectedRowRenderer
              {...row}
              key={rowKey}
              {...additionalSelectedRowProps}
            />
          );
        }

        return (
          <div key={rowIndex}>
            <MemoRowComponent
              row={row}
              index={rowIndex}
              RowComponent={RowComponent}
              testType={testType}
              columns={columns}
              tablebgColor={tablebgColor}
              selectedItem={selectedItem}
              selecteditems={selectedItems}
              delegateMouseClick={delegateMouseClick}
              onItemDoubleClick={handleItemDoubleClick}
              onSelectItem={onSelectItem ? handleOnSelectItem : null}
              onselectitems={onSelectItems ? handleOnSelectItems : null}
              rowKey={rowKey}
            />
          </div>
        );
      });
    }

    return (
      <Box
        fill
        style={style}
        ref={gridRef}
        border={{ color: 'border500' }}
        round='small'
        overflow='hidden'
        className='simple-table'
        background={tablebgColor}
      >
        {!useVirtualRows && (
          <DropButton
            icon={<FormDown size='small' />}
            plain
            style={Object.assign(
              { position: 'absolute', right: 0, zIndex: 100 },
              exportButtonStyle
            )}
            dropAlign={{ top: 'bottom', right: 'right' }}
            dropContent={
              <Button
                plain
                hoverIndicator
                onClick={() => {
                  downloadCsv(
                    exportFileName,
                    generateCellData(gridRef.current)
                  );
                }}
              >
                <Box width='small' pad='small'>
                  <Text>Export</Text>
                </Box>
              </Button>
            }
          />
        )}

        <DraggingProvider enabled={columnDragging}>
          <TableHeader>{columnHeaders}</TableHeader>
          {loadingData ? (
            <Box pad='xlarge' gap='medium' align='center'>
              <Text size='large'>Loading data...</Text>
              <Spinner />
            </Box>
          ) : rows.length > 0 ? (
            <Box
              ref={parentRef}
              overflow='auto'
              fill
              round='small'
              style={{
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                position: 'relative',
                display: 'block'
              }}
            >
              <div
                style={{
                  height: `${rowVirtualizer.totalSize}px`,
                  width: '100%',
                  position: 'relative'
                }}
              >
                {rows}
              </div>
            </Box>
          ) : (
            <Box
              fill
              justify='center'
              round='small'
              style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
              pad='medium'
            >
              <Text alignSelf='center'>{noItemsText}</Text>
            </Box>
          )}
        </DraggingProvider>
      </Box>
    );
  }
);

SimpleTable.displayName = 'SimpleTable';

export default SimpleTable;
