import React, { useState, useEffect } from 'react';
import { orderBy } from 'lodash';
import { Box, Button, DropButton, Text } from 'grommet';
import { More } from 'grommet-icons';
import SimpleTable from './simple-table';
import { capitalize, get } from 'lodash';
import { canCreate } from '../auth';

const ChildGrid = ({
  columns,
  items,
  newLabel,
  noLabel,
  header,
  onNewClick,
  onItemDoubleClick = () => {},
  keyBuilder = r => r.id,
  defaultSortField = 'name',
  defaultSortDirection = 'asc',
  itemType,
  tablebgColor = 'white400'
}) => {
  const [selectedItem, setSelectedItem] = useState();
  const [columnSort, setColumnSort] = useState({
    field: defaultSortField,
    direction: defaultSortDirection
  });
  const [sortedItems, setSortedItems] = useState([]);

  useEffect(() => {
    setSortedItems(
      orderBy(
        items,
        row => {
          if (typeof columnSort.field === 'function') {
            const value = columnSort.field(row);
            return value.toLowerCase();
          } else {
            const value = get(row, columnSort.field, '');
            if (typeof value === 'string') {
              return value.toLowerCase();
            }
            return value;
          }
        },
        [columnSort.direction]
      )
    );
  }, [items, columnSort]);

  return (
    <Box flex={true} fill>
      <Box
        direction='row'
        justify={header ? 'between' : 'end'}
        pad={{ vertical: 'small' }}
        align='center'
      >
        {header && <Text weight={'bold'}>{header}</Text>}

        {canCreate(itemType) && (
          <Button label={newLabel} onClick={onNewClick} primary />
        )}
      </Box>
      <Box flex={true} fill>
        <SimpleTable
          tablebgColor={tablebgColor}
          columns={columns}
          data={sortedItems}
          searchFilter={columnSort.field}
          searchOrder={columnSort.direction}
          onHeaderClick={c => {
            if (c.sort) {
              const newColumnSort = {};
              if (c.sort === columnSort.field) {
                newColumnSort.field = columnSort.field;

                newColumnSort.direction =
                  columnSort.direction === 'desc' ? 'asc' : 'desc';
              } else {
                newColumnSort.field = c.sort;
                newColumnSort.direction = c.defaultOrder;
              }
              setColumnSort(newColumnSort);
            }
          }}
          noItemsText={noLabel}
          selectedItem={selectedItem}
          onSelectItem={setSelectedItem}
          keyBuilder={keyBuilder}
          onItemDoubleClick={onItemDoubleClick}
        />
      </Box>
    </Box>
  );
};

ChildGrid.displayName = 'ChildGrid';

export default ChildGrid;

/*
  type ExtraOptions = {
    label: string,
    icon?: JSX.Element,
    action: (e: React.MouseEvent) => any
  }
*/
export const MoreColumn = ({
  onEdit,
  status,
  onStatusChange,
  entity,
  canChangeStatus = true,
  onDuplicate,
  extraOptions // ExtraOptions[]?
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const hasActions =
    typeof onEdit === 'function' ||
    canChangeStatus ||
    typeof onDuplicate === 'function';
  return (
    <Box fill align='center' justify='center'>
      <DropButton
        plain
        icon={<More />}
        open={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        dropProps={{ onClickOutside: () => setIsOpen(false) }}
        dropAlign={{ top: 'bottom', right: 'right' }}
        dropContent={
          <Box>
            {typeof onEdit === 'function' && (
              <Button
                plain
                hoverIndicator={true}
                onClick={() => {
                  onEdit(entity);
                  setIsOpen(false);
                }}
              >
                <Box width='small' pad='small'>
                  <Text>Edit</Text>
                </Box>
              </Button>
            )}
            {canChangeStatus && (
              <Button
                plain
                hoverIndicator={true}
                onClick={() => {
                  onStatusChange(
                    entity,
                    status === 'Active' ? 'Inactive' : 'Active'
                  );
                  setIsOpen(false);
                }}
              >
                <Box width='small' pad='small'>
                  <Text>{status === 'Active' ? 'Deactivate' : 'Activate'}</Text>
                </Box>
              </Button>
            )}
            {typeof onDuplicate === 'function' && (
              <Button
                plain
                hoverIndicator={true}
                onClick={() => {
                  onDuplicate(entity);
                  setIsOpen(false);
                }}
              >
                <Box width='small' pad='small'>
                  <Text>Duplicate</Text>
                </Box>
              </Button>
            )}
            {extraOptions?.length ? (
              <>
                {extraOptions.map(option => (
                  <Button
                    key={option.label}
                    plain
                    hoverIndicator
                    onClick={e => {
                      option.action(e, entity);
                      setIsOpen(false);
                    }}
                  >
                    <Box
                      width='small'
                      pad='small'
                      direction='row'
                      align='center'
                      gap='xsmall'
                    >
                      {option.icon && <>{option.icon}</>}
                      <Text>{option.label}</Text>
                    </Box>
                  </Button>
                ))}
              </>
            ) : null}
            {!hasActions && (
              <Button
                plain
                hoverIndicator={true}
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <Box width='small' pad='small'>
                  <Text style={{ fontStyle: 'italic' }}>
                    No Actions Available
                  </Text>
                </Box>
              </Button>
            )}
          </Box>
        }
      />
    </Box>
  );
};

const getStatusColor = status => {
  if (status === 'Active') {
    return 'status-ok';
  } else if (status === 'Inactive') {
    return 'status-critical';
  } else {
    return 'status-warning';
  }
};

export const StatusIcon = ({ status }) => {
  return (
    <Box style={{ position: 'relative' }} flex={true}>
      <Box
        round='medium'
        fill
        background={getStatusColor(status)}
        align='center'
        justify='center'
        style={{ opacity: 0.2 }}
      ></Box>
      <Box
        style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 }}
        align='center'
        justify='center'
      >
        <Text weight='bold' color={getStatusColor(status)}>
          {status ? capitalize(status) : 'Draft'}
        </Text>
      </Box>
    </Box>
  );
};
