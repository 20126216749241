import React, { useEffect, useState } from 'react';
import { Box, Text } from 'grommet';
import Modal from '../../../components/modal';
import { IMPLEMENTATION_TYPES_LABELS } from './embedded-flow-config-grid';
import { LEGACY_TEMPLATE } from './ad-unit-template-selector';
import {
  DEFAULT_AD_UNIT_NUMBER_OFFERS,
  PAGEOVERLAY
} from './embedded-flow-config.actions';
import { getFlowPublications } from './embedded-flow-config-info';
import CustomAdUnitTemplateSelector from './adunit-templates/custom-adunit-template-selector';

const TemplateItem = (
  setSelectedTemplate,
  selectedTemplate,
  currentTemplate
) => template => {
  return (
    <Box
      key={template?.name}
      style={{
        cursor: currentTemplate === template.id ? 'default' : 'pointer'
      }}
      onClick={() => {
        if (currentTemplate !== template.id) {
          setSelectedTemplate(template.id);
        }
      }}
      round='small'
      height='200px'
      width={{ min: '290px', max: '290px' }}
      pad='26px'
      gap='xsmall'
      align='center'
      justify='center'
      background={currentTemplate === template.id ? 'white400' : 'light-3'}
      focusIndicator={false}
      border={
        selectedTemplate === template.id
          ? {
              color: 'border400',
              side: 'all',
              size: '5px'
            }
          : undefined
      }
    >
      <Text
        size='large'
        textAlign='center'
        weight='bold'
        color={currentTemplate === template.id ? 'lightgrey' : 'text400'}
      >
        {template?.name}
      </Text>
      <Text
        size='medium'
        textAlign='center'
        color={currentTemplate === template.id ? 'lightgrey' : 'text400'}
      >
        {template.description}
      </Text>
    </Box>
  );
};

const AdUnitTemplatePickerModal = ({
  modifyItem,
  setModalOpen,
  adUnitTemplates,
  customAdUnitTemplates,
  addMessage,
  currentTemplate,
  wasLegacyTemplate = false,
  publishedFlowUrl = '',
  requestingUrlContainsFilters = [],
  adUnitNumberOffers,
  flowId,
  removeToast
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState(undefined);
  const [previewDomains, setPreviewDomains] = useState([]);

  useEffect(() => {
    if (flowId) {
      const loadPreviewDomains = async () => {
        const fetchedFlowPublications = await getFlowPublications(flowId, true);
        setPreviewDomains(fetchedFlowPublications);
      };
      loadPreviewDomains();
    }
  }, [flowId]);

  const onSave = () => {
    const previewDomain = previewDomains.some(location => {
      return publishedFlowUrl && publishedFlowUrl.includes(location.url);
    });
    let modifyRequestingUrlContainsFilters = requestingUrlContainsFilters || [];
    if (previewDomain) {
      modifyRequestingUrlContainsFilters = requestingUrlContainsFilters.map(
        item => {
          return {
            ...item,
            useForIntegration:
              wasLegacyTemplate && currentTemplate !== null
                ? item.useForIntegration
                : true
          };
        }
      );
    }

    modifyItem({
      adUnitTemplateId: selectedTemplate,
      implementationType: selectedTemplate
        ? IMPLEMENTATION_TYPES_LABELS.adUnitConfig
        : PAGEOVERLAY,
      requestingUrlContainsFilters: modifyRequestingUrlContainsFilters,
      adUnitNumberOffers: adUnitNumberOffers
        ? parseInt(adUnitNumberOffers)
        : DEFAULT_AD_UNIT_NUMBER_OFFERS
    });
    setModalOpen(false);
    // Remove previous toasts before adding new ones
    removeToast();
    addMessage(
      'Preview updated to match selected template. Update all config settings before saving. Changes go live upon saving if configuration is active.'
    );
  };

  const onCancel = () => {
    setModalOpen(false);
  };

  return (
    <Modal
      width='vlarge'
      saveLabel='Change Template'
      cancelLabel={'Cancel'}
      canSave={selectedTemplate !== undefined}
      onSave={onSave}
      onCancel={onCancel}
    >
      <Box
        direction='column'
        gap='small'
        pad={{
          horizontal: 'medium',
          vertical: 'small'
        }}
        overflow='auto'
        height={{ max: '80vh' }}
      >
        <Box gap='small' pad='xsmall' height={{ min: '80px' }}>
          <Text size='xlarge' fill='horizontal'>
            {wasLegacyTemplate && currentTemplate === null
              ? `Switch from Flow Configuration to an Ad Unit Configuration template.`
              : `Select an Ad Unit Template`}
          </Text>
          <Text fill='horizontal'>
            Changing your configuration type requires additional updates. Prior
            to saving, ensure all required styling and configurations are
            finalized. Once saved, your changes will be live for the world to
            see. Verify everything is set before proceeding with the change.{' '}
            {wasLegacyTemplate && currentTemplate === null && (
              <strong>You will be able to revert if necessary.</strong>
            )}
          </Text>
        </Box>
        <Box direction='row-responsive' gap='medium' height={{ min: '200px' }}>
          {adUnitTemplates.map(
            TemplateItem(setSelectedTemplate, selectedTemplate, currentTemplate)
          )}
        </Box>
        <Box
          pad='xsmall'
          height={{ min: '100px' }}
          margin={{ vertical: 'medium' }}
        >
          <CustomAdUnitTemplateSelector
            customAdUnitTemplates={customAdUnitTemplates}
            setSelectedTemplate={setSelectedTemplate}
            currentTemplate={currentTemplate}
          />
        </Box>
        {wasLegacyTemplate && currentTemplate !== null && (
          <Box>
            <Box gap='small' pad='xsmall' height={{ min: '80px' }}>
              <Text size='xlarge' fill='horizontal'>
                <strong>LEGACY</strong>: Use Flow Configuration
              </Text>
              <Text>
                <strong>
                  Reverting back to a Flow Configuration is possible but not
                  recommended.
                </strong>{' '}
                Uses a traditional flow canvas to support multiple pages and
                conditional branching. Flows are shared across multiple partners
                in the network.
              </Text>
            </Box>
            <Box direction='row' gap='medium' height={{ min: '200px' }}>
              {LEGACY_TEMPLATE.map(
                TemplateItem(
                  setSelectedTemplate,
                  selectedTemplate,
                  currentTemplate
                )
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

AdUnitTemplatePickerModal.displayName = 'AdUnitTemplatePickerModal';

export default AdUnitTemplatePickerModal;
