import React from 'react';
import { Box, Text } from 'grommet';
import RowItem from '../row-item';
import { NavLink } from 'react-router-dom';
import { cleanDate } from '../../utils.js';
import * as itemTypes from '../../items/item-types';

const renderSelectedRecords = records => {
  return records.map(record => <Text>{record}</Text>);
};

const DataDeliveryDetail = ({ item }) => {
  const {
    id,
    name,
    dataCampaignName,
    dataCampaignId,
    ppr,
    epr,
    scrub,
    caps,
    recordsSold,
    revenueGenerated,
    selectedRecords,
    dateModified,
    lastModifiedBy
  } = item;
  return (
    <Box gap='medium' flex={false}>
      <Box>
        <RowItem
          label='Name'
          title={name}
          value={
            <NavLink
              to={`/${itemTypes.DATA_CAMPAIGN}/${dataCampaignId}/${itemTypes.DATA_DELIVERY}/${id}/edit`}
            >
              <Text truncate>{name}</Text>
            </NavLink>
          }
        />
        <RowItem
          label='Campaign'
          title={dataCampaignName}
          value={
            <NavLink to={`/${itemTypes.DATA_CAMPAIGN}/${dataCampaignId}/info`}>
              <Text truncate>{dataCampaignName || dataCampaignId}</Text>
            </NavLink>
          }
        />
        <RowItem label='PPR' value={ppr} />
        <RowItem label='EPR' value={epr} />
        <RowItem label='Scrub' value={scrub} />
        <RowItem label='Caps' value={caps} />
      </Box>
      <Box>
        <RowItem label='Sold (L30)' value={recordsSold} />
        <RowItem label='Rev (L30)' value={revenueGenerated} />
      </Box>
      <Box direction='row' align='start'>
        <Box width='100px'>
          <Text>Selected Records:</Text>
        </Box>
        <Box pad={{ top: '1.5em' }} gap='xxsmall' overflow='auto'>
          {selectedRecords && renderSelectedRecords(selectedRecords)}
        </Box>
      </Box>
      <Box>
        <RowItem
          label='Modified'
          value={dateModified && cleanDate(dateModified)}
        />
        <RowItem label='By' value={lastModifiedBy} />
      </Box>
    </Box>
  );
};

export default DataDeliveryDetail;
