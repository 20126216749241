import { createAction } from '@reduxjs/toolkit';
import { authorizedFetch } from '../utils';

export const updateName = createAction('updateName');

export const fetchHomePageDashboard = () => {
  return new Promise((resolve, reject) => {
    authorizedFetch('/api/minionuisettings?id=home-dashboard', 'GET')
      .then(response => {
        resolve(response?.data?.url);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const setHomePageDashboard = url => {
  const dashboard = {
    id: 'home-dashboard',
    data: {
      url: url
    }
  };

  return new Promise((resolve, reject) => {
    authorizedFetch('/api/minionuisettings', 'POST', dashboard)
      .then(response => {
        resolve(response);
      })
      .catch(e => {
        reject(e);
      });
  });
};
