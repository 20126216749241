import {
  modifyOfferSyndicationConfig,
  receiveOfferSyndicationConfig,
  setOfferSyndicationConfigIsBusy,
  closeOfferSyndicationConfig
} from './offer-syndication-config.actions';
import itemReducer from '../../../items/item.reducers';

const offerSyndicationConfigReducer = itemReducer(
  receiveOfferSyndicationConfig,
  setOfferSyndicationConfigIsBusy,
  modifyOfferSyndicationConfig,
  closeOfferSyndicationConfig
);

export default offerSyndicationConfigReducer;
