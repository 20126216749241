export const INTERNAL_JOB_PROVIDER = 'internal-jobs-provider';

export const CPC_CALCULATION_METHODS = [
  {
    name: 'Job CPC',
    type: 'jobcpc'
  },
  {
    name: 'Effective CPC (eCPC)',
    type: 'ecpc'
  }
];

export const JOB_CAMPAIGN_GOALS = [
  {
    name: 'CPC',
    type: 'CPC'
  },
  {
    name: 'CPA',
    type: 'CPA'
  }
];
