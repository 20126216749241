import { Box, CheckBox, Text } from 'grommet';
import React, { useContext } from 'react';
import { FTSelect, FTTextInput } from '../../../../../components/inputs';
import { CustomThemeContext } from './adunit-theme';
import {
  AD_UNIT_THEME,
  SELECT_OFFER_IMAGE_PLACEMENT,
  SELECT_OFFER_BG_COLOR,
  SELECT_OFFER_BLOCK_IMG_REMAINING,
  SELECT_OFFER_BLOCK_IMG_FIRST,
  SELECT_OFFER_BLOCK_IMG_BORDER_COLOR,
  SELECT_OFFER_BLOCK_IMG_BORDER_WIDTH,
  SELECT_OFFER_BLOCK_IMG_BORDER_STYLE,
  SELECT_OFFER_BLOCK_IMG_BORDER_RADIUS
} from './adunit-theme-constant';
import ColorPicker from '../../../../../components/color-picker';
import { parseCSSProperties } from '../../../traffic-partner-utils';

const AdUnitOfferContainerSettings = () => {
  const { state, dispatch, canEdit } = useContext(CustomThemeContext);
  return (
    <Box>
      <Box margin={{ vertical: 'xsmall' }}>
        <ColorPicker
          label='Offer Background Color'
          color={parseCSSProperties(state.offerBackgroundColor.css.properties)}
          onChange={newValue => {
            dispatch({
              type: SELECT_OFFER_BG_COLOR,
              payload: AD_UNIT_THEME.offerBackgroundColor(newValue)
            });
          }}
          canEdit={canEdit}
        />
      </Box>
      <Box margin={{ vertical: 'xsmall' }}>
        <FTSelect
          disabled={!canEdit}
          label='Offer Image Position'
          placeholder='Offer Image Position'
          size='small'
          options={AD_UNIT_THEME.offerImagePlacement}
          labelKey='displayName'
          valueKey='field'
          value={AD_UNIT_THEME.offerImagePlacement.find(
            option => option.field === state.offerImagePlacement.field
          )}
          onChange={option => {
            dispatch({
              type: SELECT_OFFER_IMAGE_PLACEMENT,
              payload: option
            });
          }}
        />
      </Box>
      <Box margin={{ vertical: 'xsmall' }}>
        <Text>Offer Image Suppression</Text>
        <Box margin={{ vertical: 'xsmall' }}>
          <CheckBox
            disabled={!canEdit}
            checked={
              state.offerBlockImgFirst.css.properties.includes('none')
                ? true
                : false
            }
            onChange={e => {
              dispatch({
                type: SELECT_OFFER_BLOCK_IMG_FIRST,
                payload: e.target.checked
                  ? AD_UNIT_THEME.offerBlockImgFirst('none')
                  : AD_UNIT_THEME.offerBlockImgFirst('block')
              });
            }}
            label='Suppress 1st Image'
          />
        </Box>
        <Box margin={{ vertical: 'xsmall' }}>
          <CheckBox
            disabled={!canEdit}
            checked={
              state.offerBlockImgRemaining.css.properties.includes('none')
                ? true
                : false
            }
            onChange={e => {
              dispatch({
                type: SELECT_OFFER_BLOCK_IMG_REMAINING,
                payload: e.target.checked
                  ? AD_UNIT_THEME.offerBlockImgRemaining('none')
                  : AD_UNIT_THEME.offerBlockImgRemaining('block')
              });
            }}
            label='Suppress Remaining Images'
          />
        </Box>
        <Box margin={{ vertical: 'xsmall' }}>
          <FTSelect
            disabled={!canEdit}
            label='Offer Image Shape'
            placeholder='Offer Image Shape'
            size='small'
            options={AD_UNIT_THEME.offerBlockImgBorderRadius}
            labelKey='displayName'
            valueKey='field'
            value={AD_UNIT_THEME.offerBlockImgBorderRadius.find(
              option => option.field === state.offerBlockImgBorderRadius.field
            )}
            onChange={option => {
              dispatch({
                type: SELECT_OFFER_BLOCK_IMG_BORDER_RADIUS,
                payload: option
              });
            }}
          />
        </Box>
        <Box margin={{ vertical: 'xsmall' }}>
          <ColorPicker
            label='Offer Image Border Color'
            color={parseCSSProperties(
              state.offerBlockImgBorderColor.css.properties
            )}
            onChange={newValue => {
              dispatch({
                type: SELECT_OFFER_BLOCK_IMG_BORDER_COLOR,
                payload: AD_UNIT_THEME.offerBlockImgBorderColor(newValue)
              });
            }}
            canEdit={canEdit}
          />
        </Box>
        <Box margin={{ vertical: 'xsmall' }}>
          <FTTextInput
            label='Offer Image Border Width'
            value={parseCSSProperties(
              state.offerBlockImgBorderWidth.css.properties
            )}
            onChange={newValue => {
              dispatch({
                type: SELECT_OFFER_BLOCK_IMG_BORDER_WIDTH,
                payload: AD_UNIT_THEME.offerBlockImgBorderWidth(newValue)
              });
            }}
            disabled={!canEdit}
          />
        </Box>
        <Box margin={{ vertical: 'xsmall' }}>
          <FTSelect
            disabled={!canEdit}
            label='Offer Image Border Style'
            placeholder='Offer Image Border Style'
            size='small'
            options={AD_UNIT_THEME.offerBlockImgBorderStyle}
            labelKey='displayName'
            valueKey='field'
            value={AD_UNIT_THEME.offerBlockImgBorderStyle.find(
              option => option.field === state.offerBlockImgBorderStyle.field
            )}
            onChange={option => {
              dispatch({
                type: SELECT_OFFER_BLOCK_IMG_BORDER_STYLE,
                payload: option
              });
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AdUnitOfferContainerSettings;
