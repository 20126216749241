import React, { useEffect } from 'react';
import { Box } from 'grommet';
import { DeliveryCap } from './delivery-caps';
import { DELIVERY_CAPS, getCapValidationMessage } from '../campaign-utils.js';

const RevenueStrategy = ({
  onChange,
  isDirty,
  showShortLabels,
  caps,
  counts,
  setValidationMessage
}) => {
  const {
    dailyRevenueCap,
    monthlyRevenueCap,
    overallRevenueCap,
    capStrategy
  } = caps;

  const {
    dailyRevenueCap: dailyCapCount,
    monthlyRevenueCap: monthlyCapCount,
    overallRevenueCap: overallCapCount
  } = counts;

  useEffect(() => {
    setValidationMessage(
      getCapValidationMessage({
        dailyCap: dailyRevenueCap,
        monthlyCap: monthlyRevenueCap,
        overallCap: overallRevenueCap
      })
    );
  }, [
    setValidationMessage,
    dailyRevenueCap,
    monthlyRevenueCap,
    overallRevenueCap
  ]);

  return (
    <Box>
      <Box wrap={true} margin={{ top: 'medium' }}>
        <DeliveryCap
          capStrategy={capStrategy}
          capConfig={DELIVERY_CAPS.DAILY}
          capValue={dailyRevenueCap}
          setDeliveryCap={cap => {
            cap = cap || 0;
            onChange({ dailyRevenueCap: cap });
          }}
          conversionCap={dailyRevenueCap}
          conversionCount={dailyCapCount}
          isDirty={isDirty}
          showShortLabels={showShortLabels}
        />
        <DeliveryCap
          capStrategy={capStrategy}
          capConfig={DELIVERY_CAPS.MONTHLY}
          capValue={monthlyRevenueCap}
          setDeliveryCap={cap => {
            cap = cap || 0;
            onChange({ monthlyRevenueCap: cap });
          }}
          conversionCap={monthlyRevenueCap}
          conversionCount={monthlyCapCount}
          isDirty={isDirty}
          showShortLabels={showShortLabels}
        />
        <DeliveryCap
          capStrategy={capStrategy}
          capConfig={DELIVERY_CAPS.OVERALL}
          capValue={overallRevenueCap}
          setDeliveryCap={cap => {
            cap = cap || 0;
            onChange({ overallRevenueCap: cap });
          }}
          conversionCap={overallRevenueCap}
          conversionCount={overallCapCount}
          isDirty={isDirty}
          showShortLabels={showShortLabels}
        />
      </Box>
    </Box>
  );
};

RevenueStrategy.displayName = 'RevenueStrategy';

export default RevenueStrategy;
