import React from 'react';
import { Box, Text } from 'grommet';
import { IMPLEMENTATION_TYPES_LABELS } from './embedded-flow-config-grid';
import Loader from '../../../components/loader';
import { PAGEOVERLAY } from './embedded-flow-config.actions';
import { isItemNew } from '../../../utils';
import * as itemTypes from '../../../items/item-types';
import CustomAdUnitTemplateSelector from './adunit-templates/custom-adunit-template-selector';

export const redirectToAdUnitTemplateSelector = (item, pathname) => {
  // redirect to config selector if a new config is discarded
  if (
    item.type === itemTypes.EMBEDDED_FLOW_CONFIG &&
    isItemNew(item.id) &&
    (pathname.includes(`${item.id}/styling`) ||
      pathname.includes(`${item.id}/revshare`))
  ) {
    if (pathname.includes('/styling')) {
      window.location.href = `#${pathname.replace('styling', 'info')}`;
    }
    if (pathname.includes('/revshare')) {
      window.location.href = `#${pathname.replace('revshare', 'info')}`;
    }
  }
};

export const LEGACY_TEMPLATE = [
  {
    name: 'Flow',
    description: 'Select your implementation type later.',
    id: null
  }
];

const TemplateItem = setAdUnitTemplate => template => {
  return (
    <Box
      key={template?.name}
      style={{ cursor: 'pointer' }}
      onClick={() => {
        setAdUnitTemplate(template.id);
      }}
      hoverIndicator={{ elevation: 'medium' }}
      round='small'
      height='200px'
      width={{ min: '280px', max: '280px' }}
      pad='medium'
      margin={{ vertical: 'xsmall' }}
      gap='xsmall'
      align='center'
      justify='center'
      background='light-3'
    >
      <Text size='large' textAlign='center' weight='bold'>
        {template?.name}
      </Text>
      <Text size='medium' textAlign='center'>
        {template.description}
      </Text>
    </Box>
  );
};

const AdUnitTemplateSelector = ({
  modifyItem,
  adUnitTemplates,
  customAdUnitTemplates
}) => {
  const setAdUnitTemplate = templateId => {
    modifyItem({
      adUnitTemplateId: templateId,
      implementationType:
        templateId !== null
          ? IMPLEMENTATION_TYPES_LABELS.adUnitConfig
          : PAGEOVERLAY
    });
  };

  return (
    <Box pad={{ horizontal: 'medium' }} fill overflow='auto'>
      <Box
        direction='column'
        gap='small'
        pad='small'
        margin={{ vertical: 'medium' }}
      >
        <Text size='xlarge' fill='horizontal'>
          <strong>NEW</strong>: Use a Dynamic Ad Unit Configuration Template
        </Text>
        <Box direction='row-responsive' gap='medium' height={{ min: '210px' }}>
          {adUnitTemplates.length === 0 ? (
            <Loader />
          ) : (
            adUnitTemplates.map(TemplateItem(setAdUnitTemplate))
          )}
        </Box>
      </Box>
      <Box gap='small' pad='small' margin={{ vertical: 'medium' }}>
        <CustomAdUnitTemplateSelector
          customAdUnitTemplates={customAdUnitTemplates}
          setSelectedTemplate={setAdUnitTemplate}
        />
      </Box>
      <Box
        direction='column'
        gap='small'
        pad='small'
        margin={{ vertical: 'medium' }}
      >
        <Box>
          <Text size='xlarge' fill='horizontal'>
            <strong>LEGACY</strong>: Use Flow Configuration
          </Text>
          <Text>
            Uses a traditional flow canvas to support multiple pages and
            conditional branching. Flows are shared across multiple partners in
            the network.
          </Text>
        </Box>
        <Box direction='row' gap='medium'>
          {LEGACY_TEMPLATE.map(TemplateItem(setAdUnitTemplate))}
        </Box>
      </Box>
    </Box>
  );
};

AdUnitTemplateSelector.displayName = 'AdUnitTemplateSelector';

export default AdUnitTemplateSelector;
