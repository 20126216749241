import React, { useContext } from 'react';
import { Box, Text } from 'grommet';
import { FTSelect, FTTextInput } from '../../../../../components/inputs';
import { CustomThemeContext } from './adunit-theme';
import {
  AD_UNIT_THEME,
  SELECT_BORDER_COLOR_ONE,
  SELECT_BORDER_WIDTH_ONE,
  SELECT_BORDER_COLOR_TWO,
  SELECT_BORDER_WIDTH_TWO,
  SELECT_BORDER_COLOR_THREE,
  SELECT_BORDER_WIDTH_THREE,
  SELECT_BORDER_COLOR_FOUR,
  SELECT_BORDER_WIDTH_FOUR,
  SELECT_FOOTER_BORDER_COLOR,
  SELECT_FOOTER_BORDER_WIDTH,
  SELECT_FOOTER_BORDER_STYLE,
  SELECT_BORDER_STYLE_ONE,
  SELECT_BORDER_STYLE_TWO,
  SELECT_BORDER_STYLE_THREE,
  SELECT_BORDER_STYLE_FOUR
} from './adunit-theme-constant';
import ColorPicker from '../../../../../components/color-picker';
import { parseCSSProperties } from '../../../traffic-partner-utils';

const AdunitBordersDividers = () => {
  const { state, dispatch, canEdit } = useContext(CustomThemeContext);

  return (
    <Box
      gap='small'
      margin={{
        bottom: 'medium'
      }}
    >
      <Text size='medium' weight='bold' color='brand500'>
        Border 1
      </Text>
      <ColorPicker
        label='Color'
        color={parseCSSProperties(state.borderColorOne.css.properties)}
        onChange={newValue => {
          dispatch({
            type: SELECT_BORDER_COLOR_ONE,
            payload: AD_UNIT_THEME.borders.borderColorOne(newValue)
          });
        }}
        canEdit={canEdit}
      />
      <FTTextInput
        label='Size'
        value={parseCSSProperties(state.borderWidthOne.css.properties)}
        onChange={newValue => {
          dispatch({
            type: SELECT_BORDER_WIDTH_ONE,
            payload: AD_UNIT_THEME.borders.borderWidthOne(newValue)
          });
        }}
        disabled={!canEdit}
      />
      <FTSelect
        disabled={!canEdit}
        label='Style'
        placeholder='Footer Divider Style'
        size='small'
        options={AD_UNIT_THEME.borders.borderStyleOne}
        labelKey='displayName'
        valueKey='field'
        value={AD_UNIT_THEME.borders.borderStyleOne.find(
          option => option.field === state.borderStyleOne.field
        )}
        onChange={option => {
          dispatch({
            type: SELECT_BORDER_STYLE_ONE,
            payload: option
          });
        }}
      />
      <Text size='medium' weight='bold' color='brand500'>
        Border 2
      </Text>
      <ColorPicker
        label='Color'
        color={parseCSSProperties(state.borderColorTwo.css.properties)}
        onChange={newValue => {
          dispatch({
            type: SELECT_BORDER_COLOR_TWO,
            payload: AD_UNIT_THEME.borders.borderColorTwo(newValue)
          });
        }}
        canEdit={canEdit}
      />
      <FTTextInput
        label='Size'
        value={parseCSSProperties(state.borderWidthTwo.css.properties)}
        onChange={newValue => {
          dispatch({
            type: SELECT_BORDER_WIDTH_TWO,
            payload: AD_UNIT_THEME.borders.borderWidthTwo(newValue)
          });
        }}
        disabled={!canEdit}
      />
      <FTSelect
        disabled={!canEdit}
        label='Style'
        placeholder='Footer Divider Style'
        size='small'
        options={AD_UNIT_THEME.borders.borderStyleTwo}
        labelKey='displayName'
        valueKey='field'
        value={AD_UNIT_THEME.borders.borderStyleTwo.find(
          option => option.field === state.borderStyleTwo.field
        )}
        onChange={option => {
          dispatch({
            type: SELECT_BORDER_STYLE_TWO,
            payload: option
          });
        }}
      />
      <Text size='medium' weight='bold' color='brand500'>
        Border 3
      </Text>
      <ColorPicker
        label='Color'
        color={parseCSSProperties(state.borderColorThree.css.properties)}
        onChange={newValue => {
          dispatch({
            type: SELECT_BORDER_COLOR_THREE,
            payload: AD_UNIT_THEME.borders.borderColorThree(newValue)
          });
        }}
        canEdit={canEdit}
      />
      <FTTextInput
        label='Size'
        value={parseCSSProperties(state.borderWidthThree.css.properties)}
        onChange={newValue => {
          dispatch({
            type: SELECT_BORDER_WIDTH_THREE,
            payload: AD_UNIT_THEME.borders.borderWidthThree(newValue)
          });
        }}
        disabled={!canEdit}
      />
      <FTSelect
        disabled={!canEdit}
        label='Style'
        placeholder='Footer Divider Style'
        size='small'
        options={AD_UNIT_THEME.borders.borderStyleThree}
        labelKey='displayName'
        valueKey='field'
        value={AD_UNIT_THEME.borders.borderStyleThree.find(
          option => option.field === state.borderStyleThree.field
        )}
        onChange={option => {
          dispatch({
            type: SELECT_BORDER_STYLE_THREE,
            payload: option
          });
        }}
      />
      <Text size='medium' weight='bold' color='brand500'>
        Border 4
      </Text>
      <ColorPicker
        label='Color'
        color={parseCSSProperties(state.borderColorFour.css.properties)}
        onChange={newValue => {
          dispatch({
            type: SELECT_BORDER_COLOR_FOUR,
            payload: AD_UNIT_THEME.borders.borderColorFour(newValue)
          });
        }}
        canEdit={canEdit}
      />
      <FTTextInput
        label='Size'
        value={parseCSSProperties(state.borderWidthFour.css.properties)}
        onChange={newValue => {
          dispatch({
            type: SELECT_BORDER_WIDTH_FOUR,
            payload: AD_UNIT_THEME.borders.borderWidthFour(newValue)
          });
        }}
        disabled={!canEdit}
      />
      <FTSelect
        disabled={!canEdit}
        label='Style'
        placeholder='Footer Divider Style'
        size='small'
        options={AD_UNIT_THEME.borders.borderStyleFour}
        labelKey='displayName'
        valueKey='field'
        value={AD_UNIT_THEME.borders.borderStyleFour.find(
          option => option.field === state.borderStyleFour.field
        )}
        onChange={option => {
          dispatch({
            type: SELECT_BORDER_STYLE_FOUR,
            payload: option
          });
        }}
      />
      <Text size='medium' weight='bold' color='brand500'>
        Footer Divider
      </Text>
      <ColorPicker
        label='Color'
        color={parseCSSProperties(state.footerBorderColor.css.properties)}
        onChange={newValue => {
          dispatch({
            type: SELECT_FOOTER_BORDER_COLOR,
            payload: AD_UNIT_THEME.borders.footerBorderColor(newValue)
          });
        }}
        canEdit={canEdit}
      />
      <FTTextInput
        label='Size'
        value={parseCSSProperties(state.footerBorderWidth.css.properties)}
        onChange={newValue => {
          dispatch({
            type: SELECT_FOOTER_BORDER_WIDTH,
            payload: AD_UNIT_THEME.borders.footerBorderWidth(newValue)
          });
        }}
        disabled={!canEdit}
      />
      <FTSelect
        disabled={!canEdit}
        label='Style'
        placeholder='Footer Divider Style'
        size='small'
        options={AD_UNIT_THEME.borders.footerBorderStyle}
        labelKey='displayName'
        valueKey='field'
        value={AD_UNIT_THEME.borders.footerBorderStyle.find(
          option => option.field === state.footerBorderStyle.field
        )}
        onChange={option => {
          dispatch({
            type: SELECT_FOOTER_BORDER_STYLE,
            payload: option
          });
        }}
      />
    </Box>
  );
};

export default AdunitBordersDividers;
