import itemReducer from '../../../items/item.reducers';
import {
  closeAdGroup,
  modifyAdGroup,
  receiveAdGroup,
  setAdGroupIsBusy
} from './ad-group.actions';

const adGroupReducer = itemReducer(
  receiveAdGroup,
  setAdGroupIsBusy,
  modifyAdGroup,
  closeAdGroup
);

export default adGroupReducer;
