import React, { useEffect, useState } from 'react';
import { Box } from 'grommet';
import { sortBy } from 'lodash';
import ReferencedItems from './referenced-items';
import RowItem from '../row-item';
import { authorizedFetch, cleanDate } from '../../utils.js';
import * as itemTypes from '../../items/item-types';

const JobCampaignDetail = ({ item }) => {
  const {
    name,
    description,
    dateModified,
    lastModifiedBy,
    categories,
    version,
    dateCreated,
    provider,
    keyword
  } = item;
  const [assignedFlows, setAssignedFlows] = useState([]);
  const [activeAdGroups, setActiveAdGroups] = useState([]);
  const [inactiveAdGroups, setInactiveAdGroups] = useState([]);

  const pathBuilder = item => {
    return `/jobcampaign/${item.campaignId}/jobadgroup/${item.id}`;
  };

  useEffect(() => {
    setAssignedFlows([]);

    authorizedFetch(`/api/search/eventflow-flowreadmodel/_search`, 'POST', {
      _source: ['id', 'name'],
      query: {
        terms: {
          'id.keyword': item.flowIds
        }
      }
    })
      .then(response => {
        const assignedFlows = sortBy(
          response.hits.hits.map(h => h._source),
          e => e.name
        );
        setAssignedFlows(assignedFlows);
      })
      .catch(e => console.log(e));
  }, [item.flowIds]);

  useEffect(() => {
    setActiveAdGroups([]);
    setInactiveAdGroups([]);

    const adGroups = sortBy(item.adGroups, e => e.name);

    adGroups.forEach(ag => {
      //derive adgroup status from it's child creatives status
      if (Array.isArray(ag.creatives)) {
        if (ag.creatives.find(c => c.status === 'Active')) {
          ag.status = 'Active';
        } else {
          ag.status = 'Inactive';
        }
      }
    });
    setActiveAdGroups(adGroups.filter(ag => ag.status === 'Active'));
    setInactiveAdGroups(adGroups.filter(ag => ag.status === 'Inactive'));
  }, [item]);

  return (
    <>
      <Box flex={false}>
        <RowItem label='Name' value={name} />
        <RowItem label='Desc' value={description} truncate={false} />
        <RowItem label='Provider' value={provider} />
        <RowItem label='Keyword' value={keyword} />
        <RowItem
          label='Tags'
          value={Array.isArray(categories) && categories.join(', ')}
          truncate={false}
        />
        <RowItem
          label='Created'
          value={dateCreated && cleanDate(dateCreated)}
        />
        <RowItem
          label='Modified'
          value={dateModified && cleanDate(dateModified)}
        />
        <RowItem label='By' value={lastModifiedBy} />
        <RowItem label='Version' value={version} />
      </Box>

      <Box margin={{ top: 'large' }} overflow='auto'>
        <ReferencedItems
          title='Assigned Flows:'
          itemType={itemTypes.FLOW}
          items={assignedFlows}
        />

        <ReferencedItems
          title='Active Ad Groups:'
          itemType={itemTypes.AD_GROUP}
          items={activeAdGroups}
          pathBuilder={pathBuilder}
        />
        <ReferencedItems
          title='Inactive Ad Groups:'
          itemType={itemTypes.AD_GROUP}
          items={inactiveAdGroups}
          pathBuilder={pathBuilder}
        />
      </Box>
    </>
  );
};

export default JobCampaignDetail;
