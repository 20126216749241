import React from 'react';
import { Box, Text, Layer, ThemeContext } from 'grommet';
import { FormClose, StatusCritical, StatusGood } from 'grommet-icons';

const ToastItem = removeToast => ({
  id,
  primaryText,
  secondaryText,
  status,
  isEphemeral
}) => {
  //todo: there is probably a better way to do this
  if (isEphemeral) {
    setTimeout(() => removeToast(id), 2500);
  }

  return (
    <Box
      key={id}
      direction='row'
      justify='between'
      align='center'
      round='small'
      background='white100'
      height='xsmall'
      elevation='elevation500'
      style={{
        minWidth: '320px',
        maxWidth: '600px',
        maxHeight: '120px'
      }}
    >
      <Box
        pad='small'
        fill='vertical'
        justify='center'
        direction='row'
        gap='small'
      >
        <Box justify='start' color={status} pad={{ top: 'xsmall' }}>
          {status === 'status-ok' ? (
            <StatusGood size='medium' color={status} />
          ) : (
            <StatusCritical size='32' color={status} />
          )}
        </Box>
        <Box justify={secondaryText ? 'center' : 'start'}>
          {primaryText && (
            <Text
              title={primaryText}
              truncate={secondaryText ? true : false}
              weight='bold'
              margin={{
                bottom: 'small',
                top: secondaryText ? undefined : 'xsmall'
              }}
              style={{ maxWidth: secondaryText ? 'auto' : '600px' }}
            >
              {primaryText}
            </Text>
          )}
          {secondaryText && (
            <Text
              title={secondaryText}
              style={{ overflowY: 'auto', maxWidth: '600px' }}
            >
              {secondaryText}
            </Text>
          )}
        </Box>
      </Box>

      <Box
        fill='vertical'
        justify='center'
        direction='column'
        alignSelf='stretch'
        background='white300'
        hoverIndicator
        style={{ flexShrink: 0, boxSizing: 'content-box' }}
        round={{ corner: 'right', size: 'small' }}
        onClick={() => removeToast(id)}
      >
        <FormClose data-testid='closeIcon' />
      </Box>
    </Box>
  );
};

const Toasts = ({ items = [], removeToast }) => {
  return (
    <ThemeContext.Extend
      value={{
        layer: {
          zIndex: '999999',
          container: {
            zIndex: '999999'
          }
        }
      }}
    >
      <Layer
        plain={true}
        modal={false}
        position='bottom-right'
        margin={{ right: 'small', bottom: 'small' }}
      >
        <Box gap='small'>{items.map(ToastItem(removeToast))}</Box>
      </Layer>
    </ThemeContext.Extend>
  );
};

Toasts.displayName = 'Toasts';

export default Toasts;
