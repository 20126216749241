import React, { useState, useEffect } from 'react';
import { authorizedFetch } from '../utils';
import { FTSelect } from '../components/inputs';

const CampaignTypeSelector = ({
  campaignType,
  modifyItem,
  disabled,
  addError
}) => {
  const [campaignTypes, setCampaignTypes] = useState([]);

  const fetchCampaignTypes = async () => {
    const res = await authorizedFetch('/api/Lookup/campaigntype', 'GET');
    setCampaignTypes(res);
  };

  useEffect(() => {
    try {
      fetchCampaignTypes();
    } catch (err) {
      addError(`There was an error fetching campaign types`, err.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FTSelect
      label='Type'
      value={campaignTypes.find(at => at.id === campaignType)}
      options={campaignTypes}
      labelKey='description'
      valueKey='id'
      disabled={disabled}
      onChange={({ id }) => modifyItem({ campaignType: id })}
      placeholder='Select a campaign type'
    />
  );
};

export default CampaignTypeSelector;
