import { v4 as uuidv4 } from 'uuid';

export const DEFAULT_SORT_STRATEGY = {
  groupId: uuidv4(),
  sourceIds: [],
  deviceOperatingSystems: ['Android', 'iOS', 'Web'],
  implementationTypes: ['pageoverlay', 'embedonpage', 'none'],
  campaignPlacementSortGroupItems: [
    {
      sortStrategyName: 'random',
      trafficDistribution: 1.0
    }
  ],
  isDefault: true
};

export const SORT_STRATEGY_OPTIONS = [
  { value: 'random', name: 'Random' },
  { value: 'epc', name: 'EPC Sort' },
  { value: 'rpi', name: 'RPI Sort' },
  { value: 'sourceAdgroupScopedPredictiveRPI', name: 'Predictive RPI' },
  {
    value: 'sourcePositionAdgroupScopedPredictiveRPI',
    name: 'Predictive RPI By Position'
  },
  {
    value: 'sourceIdCampaignScopedCdMlPredictiveRpiTuMatchSortStrategy',
    name: 'ML - RPI by Campaign Click Score - TU Match'
  },
  {
    value: 'sourceIdCampaignScopedCdMlPredictiveRpiSortStrategy',
    name: 'ML - RPI by Campaign Click Score - Full Profile'
  }
];

// render nice names for sort strategies based on strategy value
export const SORT_STRATEGY_NAMES = {
  epcSort: 'EPC Sort',
  rpiSort: 'RPI Sort',
  predictiveRpiSort: 'Predictive RPI',
  positionPredictiveRpiSort: 'Predictive RPI By Position',
  random: 'Random',
  SourceIdCampaignScopedCdMlPredictiveRpiSortStrategy:
    'ML - RPI by Campaign Click Score - Full Profile',
  SourceIdCampaignScopedCdMlPredictiveRpiTuMatchSortStrategy:
    'ML - RPI by Campaign Click Score - TU Match'
};
