import React, { useEffect, useState } from 'react';
import { Box, Button, Text } from 'grommet';
import { NavLink, useNavigate } from 'react-router-dom';
import ChildGrid, { MoreColumn } from '../../../components/child-grid';
import Loader from '../../../components/loader';
import * as itemTypes from '../../../items/item-types';
import {
  authorizedFetch,
  dateFormatOptions,
  generateNewId,
  isItemNew
} from '../../../utils';
import EmbeddedFlowPreviewModal from './embedded-flow-preview-modal';
import PartnerRevShareModal from './partner-rev-share/partner-rev-share-modal';
import { canUpdate } from '../../../auth';
import {
  fetchAdUnitTemplates,
  fetchCustomAdUnitTemplates
} from '../traffic-partner-utils';

export const IMPLEMENTATION_TYPES_LABELS = {
  pageoverlay: 'Page Overlay',
  embedonpage: 'Embed on Page',
  stackoverlay: 'Stack Overlay',
  stackembed: 'Stack Embed',
  adUnitConfig: 'adunitconfig'
};

const buildConditionsString = requestingUrlContainsFilters => {
  let conditionsString;
  const urlConditions = requestingUrlContainsFilters.map(f => `"${f}"`);
  if (urlConditions) {
    conditionsString = `${urlConditions.join(' OR ')}`;
  }
  return conditionsString;
};

const getColumns = (
  onEdit,
  onStatusChange,
  setDefault,
  setPreviewEmbeddedFlow,
  adUnitTemplates
) => [
  {
    property: 'embeddedFlowConfig.sourceName',
    header: 'Source Name | ID',
    width: '240px',
    render: embeddedFlowConfig => {
      return (
        <Box direction='row' align='center' gap='small'>
          <Text
            truncate
            title={`${embeddedFlowConfig.trafficSourceName} | ${embeddedFlowConfig.trafficSourceAffiliateId}`}
          >
            <NavLink
              to={`/${itemTypes.TRAFFIC_SOURCE}/${embeddedFlowConfig.trafficSourceId}/info`}
            >
              {`${embeddedFlowConfig.trafficSourceName} | ${embeddedFlowConfig.trafficSourceAffiliateId}`}{' '}
            </NavLink>
          </Text>
          {embeddedFlowConfig.default && <Text>Default</Text>}
        </Box>
      );
    },
    sort: embeddedFlowConfig => {
      return `${embeddedFlowConfig.trafficSourceName} | ${embeddedFlowConfig.trafficSourceAffiliateId}`;
    },
    defaultOrder: 'asc'
  },
  {
    property: 'embeddedFlowConfig.implementationType',
    header: 'Implementation Type',
    width: '260px',
    render: embeddedFlowConfig => {
      const getAdUnitTemplate = id => {
        return adUnitTemplates.find(template => template.id === id);
      };
      const implementationType =
        embeddedFlowConfig.hasOwnProperty('adUnitTemplateId') &&
        embeddedFlowConfig.adUnitTemplateId !== null
          ? `
        Ad Unit: ${getAdUnitTemplate(embeddedFlowConfig.adUnitTemplateId)
          ?.name || 'Custom Template'}
      `
          : `
        Flow: ${
          IMPLEMENTATION_TYPES_LABELS[embeddedFlowConfig.implementationType]
        } | ${embeddedFlowConfig.assignedFlow}
      `;

      return (
        <Box direction='row' align='center' gap='small'>
          <Text truncate title={implementationType}></Text>
          {implementationType}
        </Box>
      );
    },
    sort: embeddedFlowConfig => {
      return `Flow: ${
        IMPLEMENTATION_TYPES_LABELS[embeddedFlowConfig.implementationType]
      } | ${embeddedFlowConfig.assignedFlow}`;
    },
    defaultOrder: 'asc'
  },
  {
    property: 'embeddedFlowConfig.urlCondition',
    header: 'URL Condition Contains',
    render: embeddedFlowConfig => {
      const requestingUrlContainsFilters =
        embeddedFlowConfig.requestingUrlContainsFilters || [];
      const testRequestingUrlContainsFilters =
        embeddedFlowConfig.testRequestingUrlContainsFilters || [];
      const combinedFilters = [
        ...requestingUrlContainsFilters,
        ...testRequestingUrlContainsFilters
      ];
      const conditionsString = buildConditionsString(combinedFilters);
      return (
        <Box direction='row' align='center' gap='small'>
          <Text title={conditionsString ?? ''} truncate>
            {conditionsString ?? ''}
          </Text>
        </Box>
      );
    },
    sort: embeddedFlowConfig => {
      const requestingUrlContainsFilters =
        embeddedFlowConfig.requestingUrlContainsFilters || [];
      const testRequestingUrlContainsFilters =
        embeddedFlowConfig.testRequestingUrlContainsFilters || [];
      const combinedFilters = [
        ...requestingUrlContainsFilters,
        ...testRequestingUrlContainsFilters
      ];
      const conditionsString = buildConditionsString(combinedFilters);
      return conditionsString ?? '';
    },
    defaultOrder: 'asc'
  },
  {
    property: 'embeddedFlowConfig.revSharePerSession',
    header: 'Current Session Value | Revenue %',
    width: '240px',
    render: embeddedFlowConfig => {
      const revSession = embeddedFlowConfig?.revSharePerSession || 0;
      const revPercent = embeddedFlowConfig?.revSharePercent || 0;
      return (
        <Text>{`$${revSession.toFixed(2)} | ${(revPercent * 100).toFixed(
          2
        )}%`}</Text>
      );
    },
    sort: embeddedFlowConfig => {
      const revSession = embeddedFlowConfig?.revSharePerSession || 0;
      const revPercent = embeddedFlowConfig?.revSharePercent || 0;
      return `$${revSession.toFixed(2)} | ${(revPercent * 100).toFixed(2)}%`;
    },
    defaultOrder: 'asc'
  },
  {
    property: 'embeddedFlowConfig.enabled',
    header: 'Status',
    width: '100px',
    render: embeddedFlowConfig => {
      return (
        <Text color={embeddedFlowConfig.enabled ? 'green' : ''}>
          {embeddedFlowConfig.enabled ? 'Active' : 'Inactive'}
        </Text>
      );
    },
    sort: 'enabled',
    defaultOrder: 'desc'
  },
  {
    property: 'embeddedFlowConfig.dateModified',
    header: 'Modified On (By)',
    width: '230px',
    sort: embeddedFlowConfig =>
      `${
        embeddedFlowConfig.dateModified
      } (${embeddedFlowConfig.lastModifiedBy || 'None'})`,
    defaultOrder: 'desc',
    render: embeddedFlowConfig => {
      const dateModifiedAndModifiedBy = `${embeddedFlowConfig.dateModified &&
        new Date(embeddedFlowConfig.dateModified).toLocaleString(
          'en-US',
          dateFormatOptions
        )} (${embeddedFlowConfig.lastModifiedBy || 'None'})`;
      return (
        <Text truncate title={dateModifiedAndModifiedBy}>
          {dateModifiedAndModifiedBy}
        </Text>
      );
    }
  },
  {
    property: 'action',
    header: 'Actions',
    width: '70px',
    render: embeddedFlowConfig => {
      const extraOptions = [
        {
          label: 'Preview',
          action: () => setPreviewEmbeddedFlow(embeddedFlowConfig)
        }
      ];
      if (
        !embeddedFlowConfig.default &&
        canUpdate(itemTypes.EMBEDDED_FLOW_CONFIG)
      ) {
        extraOptions.push({
          label: 'Set Default',
          action: () => setDefault(embeddedFlowConfig)
        });
      }
      return (
        <MoreColumn
          onEdit={onEdit}
          status={embeddedFlowConfig.enabled ? 'Active' : 'Inactive'}
          onStatusChange={onStatusChange}
          canChangeStatus={canUpdate(itemTypes.EMBEDDED_FLOW_CONFIG)}
          entity={embeddedFlowConfig}
          extraOptions={extraOptions}
        />
      );
    }
  }
];

const getFlowNames = async flowIds => {
  const response = await authorizedFetch(
    `/api/search/eventflow-flowreadmodel/_search`,
    'POST',
    {
      _source: ['id', 'name'],
      size: flowIds.length,
      query: {
        terms: {
          'id.keyword': flowIds
        }
      }
    }
  );

  const flowNames = response.hits.hits.map(h => {
    return h._source;
  });
  return flowNames;
};

const getTrafficSourceNames = async trafficSourceIds => {
  const response = await authorizedFetch(
    `/api/search/eventflow-trafficsourcereadmodel/_search`,
    'POST',
    {
      _source: ['id', 'name'],
      size: trafficSourceIds.length,
      query: {
        terms: {
          'id.keyword': trafficSourceIds
        }
      }
    }
  );
  const trafficSourceNames = response.hits.hits.map(h => {
    return h._source;
  });
  return trafficSourceNames;
};

const getEmbeddedFlowConfigs = async (
  id,
  setEmbeddedFlowConfigs,
  setIsLoadingItems,
  addError
) => {
  if (!isItemNew(id)) {
    try {
      const response = await authorizedFetch(
        'api/search/eventflow-embeddedflowconfigreadmodel/_search',
        'POST',
        {
          _source: [
            'id',
            'publishedFlowUrl',
            'trafficSourceId',
            'trafficSourceAffiliateId',
            'requestingUrlContainsFilters',
            'testRequestingUrlContainsFilters',
            'implementationType',
            'dateModified',
            'flowId',
            'enabled',
            'version',
            'revSharePerSession',
            'revSharePercent',
            'lastModifiedBy',
            'adUnitTemplateId'
          ],
          size: 200,
          sort: {
            dateModified: 'desc'
          },
          query: {
            term: {
              'trafficPartnerId.keyword': id
            }
          }
        }
      );
      const embeddedFlowConfigs = response?.hits?.hits
        ? response.hits.hits.map(d => d._source)
        : [];
      setEmbeddedFlowConfigs(embeddedFlowConfigs);
      setIsLoadingItems(false);
    } catch (error) {
      addError('Unable to fetch ad unit configurations', JSON.stringify(error));
      setIsLoadingItems(false);
    }
  }
};

const EmbeddedFlowConfigGrid = props => {
  const navigate = useNavigate();
  const { item, addError, addSuccess, modifyItem, canEdit } = props;
  const [embeddedFlowConfigs, setEmbeddedFlowConfigs] = useState([]);
  const [isLoadingItems, setIsLoadingItems] = useState(true);
  const [gridData, setGridData] = useState([]);
  const [showManagePartnerRevenue, setShowManagePartnerRevenue] = useState(
    false
  );
  const [previewEmbeddedFlow, setPreviewEmbeddedFlow] = useState();
  const [adUnitTemplates, setAdUnitTemplates] = useState([]);
  const [customAdUnitTemplates, setCustomAdUnitTemplates] = useState([]);

  useEffect(() => {
    fetchAdUnitTemplates(props.item.id, setAdUnitTemplates, addError);
  }, []);

  useEffect(() => {
    fetchCustomAdUnitTemplates(setCustomAdUnitTemplates, addError);
  }, []);

  useEffect(() => {
    getEmbeddedFlowConfigs(
      item.id,
      setEmbeddedFlowConfigs,
      setIsLoadingItems,
      addError
    );
  }, [item.id, addError]);

  useEffect(() => {
    const getGridData = async () => {
      const flowIds = [];
      const trafficSourceIds = [];
      embeddedFlowConfigs.forEach(efc => {
        flowIds.push(efc.flowId);
        trafficSourceIds.push(efc.trafficSourceId);
      });

      // Filter out null values (we don't store flow ids for ad unit templates)
      const flowNames = await getFlowNames(
        flowIds.filter(value => value !== null)
      );
      const trafficSourceNames = await getTrafficSourceNames(trafficSourceIds);

      const gridData = embeddedFlowConfigs.map(efc => {
        const isDefault = efc.id === item.defaultEmbeddedFlowConfigId;
        const matchedFlow = flowNames.find(flow => flow.id === efc.flowId);
        const matchedTrafficSource = trafficSourceNames.find(
          trafficSource => trafficSource.id === efc.trafficSourceId
        );

        return {
          ...efc,
          assignedFlow: matchedFlow?.name,
          trafficSourceName: matchedTrafficSource.name,
          default: isDefault
        };
      });
      setGridData(gridData);
    };
    getGridData();
  }, [embeddedFlowConfigs, item.defaultEmbeddedFlowConfigId]);

  useEffect(() => {
    setGridData(gridData);
  }, [gridData]);

  const createEmbeddedFlowConfig = () =>
    navigate(
      `/${itemTypes.TRAFFIC_PARTNER}/${item.id}/${
        itemTypes.EMBEDDED_FLOW_CONFIG
      }/${generateNewId()}/info`
    );

  const openEmbeddedFlowConfig = embeddedFlowConfig =>
    navigate(
      `/${itemTypes.TRAFFIC_PARTNER}/${item.id}/${itemTypes.EMBEDDED_FLOW_CONFIG}/${embeddedFlowConfig.id}/info`
    );

  const updateConfigStatus = async (updatedConfig, newStatus) => {
    try {
      authorizedFetch(
        `/api/${itemTypes.TRAFFIC_PARTNER}/${item.id}/${
          itemTypes.EMBEDDED_FLOW_CONFIG
        }/${updatedConfig.id}/${
          newStatus === 'Active' ? 'activate' : 'deactivate'
        }`,
        'POST',
        {
          expectedVersion: updatedConfig.version
        }
      ).then(res => {
        const newEmbeddedFlowConfigs = [...embeddedFlowConfigs];
        const updatedConfigIndex = newEmbeddedFlowConfigs.findIndex(
          config => config.id === updatedConfig.id
        );
        newEmbeddedFlowConfigs[updatedConfigIndex].enabled =
          newStatus === 'Active' ? true : false;
        newEmbeddedFlowConfigs[updatedConfigIndex].version = res.version;
        setEmbeddedFlowConfigs(newEmbeddedFlowConfigs);
      });
    } catch (ex) {
      addError('Unable to change config status', ex.message);
    }
  };

  const setDefaultConfig = embeddedFlowConfig => {
    modifyItem({ defaultEmbeddedFlowConfigId: embeddedFlowConfig.id });
  };

  return (
    <Box flex>
      <Box
        direction='column'
        justify='between'
        align='right'
        flex={{ shrink: 0 }}
      >
        {!isLoadingItems ? (
          <Box
            style={{
              position: 'relative'
            }}
          >
            {canUpdate(itemTypes.EMBEDDED_FLOW_CONFIG) && (
              <Button
                label='Manage Partner Revenue'
                onClick={() => setShowManagePartnerRevenue(true)}
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '190px'
                }}
                disabled={gridData.length === 0}
              />
            )}
            <ChildGrid
              header='Ad Unit Configurations'
              items={gridData}
              itemType={itemTypes.EMBEDDED_FLOW_CONFIG}
              keyBuilder={embeddedFlowConfig => embeddedFlowConfig.id}
              columns={getColumns(
                openEmbeddedFlowConfig,
                updateConfigStatus,
                setDefaultConfig,
                setPreviewEmbeddedFlow,
                adUnitTemplates
              )}
              newLabel='New Configuration'
              noLabel='Add new configurations using the button above.'
              onItemDoubleClick={openEmbeddedFlowConfig}
              onNewClick={createEmbeddedFlowConfig}
            />

            {showManagePartnerRevenue && (
              <PartnerRevShareModal
                setShowPlaceholdersModal={setShowManagePartnerRevenue}
                gridData={gridData}
                trafficPartnerId={item.id}
                addError={addError}
                canEdit={canEdit}
                adUnitTemplates={adUnitTemplates}
              />
            )}

            {previewEmbeddedFlow ? (
              <EmbeddedFlowPreviewModal
                trafficPartnerId={item.id}
                previewEmbeddedFlow={previewEmbeddedFlow}
                setPreviewEmbeddedFlow={setPreviewEmbeddedFlow}
                canEdit={canEdit}
                addError={addError}
                addSuccess={addSuccess}
                adUnitTemplates={adUnitTemplates}
                customAdUnitTemplates={customAdUnitTemplates}
              />
            ) : null}
          </Box>
        ) : (
          <Loader />
        )}
      </Box>
    </Box>
  );
};

EmbeddedFlowConfigGrid.displayName = 'EmbeddedFlowConfigGrid';

export default EmbeddedFlowConfigGrid;
