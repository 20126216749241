import { createAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import * as itemTypes from '../../items/item-types';
import {
  getItem as getItemInternal,
  saveItem as saveItemInternal
} from '../../items/item.actions';
import { keyFromItemType } from '../../routing-utils';

export const receiveIntegration = createAction('receiveIntegration');
export const setIntegrationIsBusy = createAction('setIntegrationIsBusy');
export const modifyIntegration = createAction('modifyIntegration');
export const closeIntegration = createAction('closeIntegration');

const integrationModel = (
  id,
  name,
  params,
  headers,
  responseParameterMappings
) => {
  return {
    id,
    name,
    headers,
    responseParameterMappings,
    type: itemTypes.INTEGRATION,
    startDate: new Date().toISOString(),
    [keyFromItemType(itemTypes.ADVERTISER)]: params.advertiserid
  };
};

export const getItem = getItemInternal(
  integrationModel,
  receiveIntegration,
  setIntegrationIsBusy,
  item => {
    item.headers = Object.entries(item.headers).map(([key, value]) => {
      return { id: uuidv4(), key: value.name, value: value.value };
    });
    item.responseParameterMappings = Object.entries(
      item.responseParameterMappings
    ).map(([key, value]) => {
      return { id: uuidv4(), key: value.mapFrom, value: value.mapTo };
    });
  }
);

export const saveItem = saveItemInternal(modifyIntegration, undefined, item => {
  if (Array.isArray(item.headers)) {
    item.headers = Object.entries(item.headers).map(([key, value]) => {
      return { name: value.key, value: value.value };
    });
  }
  if (Array.isArray(item.responseParameterMappings)) {
    item.responseParameterMappings = Object.entries(
      item.responseParameterMappings
    ).map(([key, value]) => {
      return { mapFrom: value.key, mapTo: value.value };
    });
  }
});
