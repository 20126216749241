import React, { useEffect, useState } from 'react';
import { Box, Button, Text } from 'grommet';
import useClippy from 'use-clippy';
import { v4 as uuidv4 } from 'uuid';
import { FTSelect, FTTextInput } from '../../../components/inputs';
import KeyValueInputs from '../../../components/key-value-inputs';
import Modal from '../../../components/modal';
import { getEnvironmentConfig } from '../../../environment-config/environment-config';
import * as itemTypes from '../../../items/item-types';
import { getItemsList, keyValueField } from '../../../utils';
import { getFlowPublications } from './embedded-flow-config-info';
import { fetchCampaignAdCreatives } from '../../../campaigns/campaign-utils';
import { IMPLEMENTATION_TYPES_LABELS } from './embedded-flow-config-grid';
import { EMBEDONPAGE } from './embedded-flow-config.actions';

const EmbeddedFlowPreviewModal = ({
  trafficPartnerId,
  previewEmbeddedFlow,
  setPreviewEmbeddedFlow,
  canEdit,
  addError,
  addSuccess,
  adUnitTemplates,
  customAdUnitTemplates
}) => {
  const {
    id,
    flowId,
    trafficSourceAffiliateId,
    implementationType,
    adUnitTemplateId
  } = previewEmbeddedFlow;

  const embedOnPageTemplates = adUnitTemplates
    .filter(template => template.name.includes('Page Embed'))
    .find(template => template.id === adUnitTemplateId);

  const [, setClipboard] = useClippy();
  const [previewUrl, setPreviewUrl] = useState('');
  const [publishedLocations, setPublishedLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [transactionParams, setTransactionParams] = useState([keyValueField()]);
  const [campaigns, setCampaigns] = useState([]);
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [adCreatives, setAdCreatives] = useState([]);
  const [selectedCreative, setSelectedCreative] = useState(null);
  const [pixelWidth, setPixelWidth] = useState(null);

  const deriveImplementationTypeFromAdUnitTemplateId = adUnitTemplateId => {
    return [...adUnitTemplates, ...customAdUnitTemplates].find(t => {
      return t.id === adUnitTemplateId;
    }).implementationTypeMapping;
  };

  useEffect(() => {
    if (flowId) {
      const getLocations = async () => {
        try {
          const fetchedFlowPublications = await getFlowPublications(
            flowId,
            true
          );
          setPublishedLocations(
            fetchedFlowPublications.map(pub => {
              return { label: pub.publicationName, url: pub.url };
            })
          );
          if (fetchedFlowPublications.length === 1) {
            setSelectedLocation({
              label: fetchedFlowPublications[0].publicationName,
              url: fetchedFlowPublications[0].url
            });
          }
        } catch (e) {
          addError(e.message);
        }
      };
      getLocations();
    }
  }, [flowId, addError]);

  useEffect(() => {
    const getCampaigns = async () => {
      try {
        let remappedCampaigns = [];
        const fetchedCampaigns = await getItemsList(itemTypes.CAMPAIGN);
        if (implementationType !== IMPLEMENTATION_TYPES_LABELS.adUnitConfig) {
          remappedCampaigns = fetchedCampaigns
            .filter(c => c.flowIds.includes(flowId))
            .map(c => {
              return { label: c.name, id: c.id };
            });
        } else {
          remappedCampaigns = fetchedCampaigns.map(c => {
            return { label: c.name, id: c.id };
          });
        }
        setCampaigns(remappedCampaigns);
        setFilteredCampaigns(remappedCampaigns);
      } catch (e) {
        addError(e.message);
      }
    };
    getCampaigns();
  }, [addError, flowId]);

  useEffect(() => {
    if (selectedCampaign) {
      const getCampaign = async () => {
        try {
          const adCreatives = await fetchCampaignAdCreatives(selectedCampaign);
          const adCreativeOptions = adCreatives.map(c => {
            return {
              label: c.name,
              id: c.id
            };
          });
          setAdCreatives(adCreativeOptions);
        } catch (e) {
          addError(e.message);
        }
      };
      getCampaign();
    }
  }, [selectedCampaign, addError]);

  useEffect(() => {
    const envConfig = getEnvironmentConfig();
    const filteredParams = transactionParams.filter(param => param.key !== '');

    if (selectedLocation) {
      const queryParams = [
        {
          key: 'flowUrl',
          value: selectedLocation.url
        },
        {
          key: 'sourceid',
          value: trafficSourceAffiliateId
        },
        {
          key: 'implementationType',
          value: implementationType
        },
        ...filteredParams
      ];

      if (implementationType === EMBEDONPAGE) {
        queryParams.push(
          {
            key: 'width',
            value: pixelWidth ? `${pixelWidth}px` : `100%`
          },
          {
            key: 'padding',
            value: '1.25rem'
          }
        );
      }

      if (selectedCreative) {
        queryParams.push({
          key: 'previewadcreative',
          value: selectedCreative
        });
      }

      const url = new URL(
        `https://${envConfig?.environment}.minionplatform.com/api/${itemTypes.TRAFFIC_PARTNER}/${trafficPartnerId}/embeddedFlowConfig/${id}/preview`
      );
      if (Array.isArray(queryParams) && queryParams.length > 0) {
        queryParams.forEach(param =>
          url.searchParams.append(param.key, param.value)
        );
      }
      setPreviewUrl(decodeURIComponent(url.toString()));
    }

    if (implementationType === IMPLEMENTATION_TYPES_LABELS.adUnitConfig) {
      const adUnitImplementationType = deriveImplementationTypeFromAdUnitTemplateId(
        adUnitTemplateId
      );

      const queryParams = [
        {
          key: 'flowUrl',
          value: `https://public.${envConfig?.environment}.minionplatform.com/v1/display?configid=${id}&isPreviewScoped=true`
        },
        {
          key: 'sourceid',
          value: trafficSourceAffiliateId
        },
        {
          key: 'implementationType',
          value: adUnitImplementationType
        },
        ...filteredParams
      ];
      if (selectedCreative) {
        queryParams.push({
          key: 'previewadcreative',
          value: selectedCreative
        });
      }
      if (implementationType === EMBEDONPAGE || !!embedOnPageTemplates) {
        queryParams.push(
          {
            key: 'width',
            value: pixelWidth ? `${pixelWidth}px` : `100%`
          },
          {
            key: 'padding',
            value: '1.25rem'
          }
        );
      }
      const url = new URL(
        `https://${envConfig?.environment}.minionplatform.com/api/${itemTypes.TRAFFIC_PARTNER}/${trafficPartnerId}/embeddedFlowConfig/${id}/preview`
      );
      if (Array.isArray(queryParams) && queryParams.length > 0) {
        queryParams.forEach(param =>
          url.searchParams.append(param.key, param.value)
        );
      }
      setPreviewUrl(decodeURIComponent(url.toString()));
    }
  }, [
    selectedLocation,
    id,
    trafficSourceAffiliateId,
    implementationType,
    transactionParams,
    selectedCreative,
    pixelWidth,
    trafficPartnerId
  ]);

  return (
    <Modal
      header='Preview this Ad Unit Configuration'
      saveLabel='Close'
      onClose={() => setPreviewEmbeddedFlow(null)}
      canSave
      onSave={() => setPreviewEmbeddedFlow(null)}
    >
      <Box gap='medium' pad='medium'>
        {implementationType !== IMPLEMENTATION_TYPES_LABELS.adUnitConfig && (
          <FTSelect
            label='Publish Location'
            value={selectedLocation || ''}
            labelKey='label'
            valueKey='label'
            placeholder='Select Publish Location'
            options={publishedLocations}
            onChange={location => {
              setSelectedLocation(location);
            }}
          />
        )}

        {(implementationType === EMBEDONPAGE || !!embedOnPageTemplates) && (
          <FTTextInput
            label='Embed on Page Pixel Width'
            value={pixelWidth || ''}
            placeholder='if empty defaults to 100%'
            icon={<Text>px</Text>}
            reverse={true}
            type='number'
            onChange={newValue => {
              setPixelWidth(Number(newValue));
            }}
          />
        )}

        <Text color='brand' weight='bold'>
          Optional Preview Settings
        </Text>

        <Text>Query Params</Text>
        <Box gap='xsmall'>
          {transactionParams.map(params => (
            <KeyValueInputs
              canEdit={true}
              key={params.id}
              keyPair={params}
              onKeyPairChange={newProps => {
                let newParams = [...transactionParams];
                newParams.splice(
                  newParams.findIndex(p => p.id === params.id),
                  1,
                  Object.assign({}, params, newProps)
                );
                setTransactionParams(newParams);
              }}
              removeKeyPair={() => {
                let newParams = [...transactionParams];
                newParams.splice(
                  newParams.findIndex(p => p.id === params.id),
                  1
                );
                setTransactionParams(newParams);
              }}
            />
          ))}
          <Button
            alignSelf='end'
            secondary
            label='Add'
            onClick={() => {
              setTransactionParams([...transactionParams, keyValueField()]);
            }}
          />
        </Box>

        <FTSelect
          placeholder={`Select a Campaign`}
          labelKey='label'
          valueKey='id'
          options={filteredCampaigns}
          value={campaigns.find(c => c.id === selectedCampaign)}
          label='Campaign'
          onChange={({ id }) => {
            setSelectedCampaign(id);
            setSelectedCreative(null);
          }}
          handleSearch={search => {
            const lowercaseSearch = search.toLowerCase();
            if (search === '') {
              setFilteredCampaigns(campaigns);
            } else {
              setFilteredCampaigns(
                campaigns.filter(c => {
                  const campaignLabel = c.label.toLowerCase();
                  return campaignLabel.includes(lowercaseSearch);
                })
              );
            }
          }}
        />

        {selectedCampaign && (
          <FTSelect
            label='Campaign Creative'
            placeholder='Select a Creative'
            value={adCreatives.find(cg => cg.id === selectedCreative)}
            options={adCreatives}
            labelKey='label'
            valueKey='id'
            onChange={({ id }) => {
              setSelectedCreative(id);
            }}
          />
        )}

        {previewUrl && (
          <Box justify='center' align='center' gap='medium'>
            <Text
              as='a'
              href={previewUrl}
              target='_blank'
              style={{
                fontFamily: 'monospace',
                background: '#efefef',
                padding: '1em',
                width: '100%',
                overflowWrap: 'break-word'
              }}
            >
              {previewUrl}
            </Text>
            <Button
              onClick={() => {
                setClipboard(previewUrl);
                addSuccess('Preview link copied to clipboard');
              }}
              primary
              label='Copy to Clipboard'
            />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default EmbeddedFlowPreviewModal;
