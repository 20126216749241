import React, { useEffect, useState } from 'react';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useDrop } from 'react-dnd';
import { Box, Button, Text } from 'grommet';
import MessageBox from './messageBox';
import { isValidImageUrl } from '../utils';

// from https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
function formatBytes(bytes, decimals = 0) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const DropZone = ({ onDrop, handleClick, url }) => {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item, monitor) {
      if (onDrop) {
        onDrop(monitor);
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const isActive = canDrop && isOver;

  const style = {
    background: isActive ? '#F0FFFF' : '#EEEEEE',
    border: isActive ? '1px #ADD8E6 dashed' : '1px #999999 dashed',
    padding: '10px',
    fontSize: '10px',
    color: '#888888',
    borderRadius: '3px'
  };

  return (
    <Box
      ref={drop}
      fill='horizontal'
      justify='center'
      align='center'
      style={style}
      onClick={handleClick}
    >
      {isActive ? (
        <Text size='small' weight='bold'>
          Drop file here
        </Text>
      ) : url ? (
        <img
          data-testid='image-uploader-thumbnail'
          src={url}
          alt=''
          title={url}
          style={{
            maxHeight: '100%',
            maxWidth: '100%',
            objectFit: 'contain'
          }}
        />
      ) : (
        <Text size='small' weight='bold' margin={{ bottom: 'small' }}>
          Drag and drop to upload file
        </Text>
      )}
    </Box>
  );
};

const ImageUploader = ({
  imageUrl = '',
  onFileUpload,
  infoMessage,
  width = '200px',
  height = '200px',
  isImageRemoved,
  setIsImageRemoved
}) => {
  const [url, setUrl] = useState(imageUrl);
  const [validationError, setValidationError] = useState();
  const [fileName, setFileName] = useState();

  // Create ref to redirect click to hidden ugly native file input
  const browserFileInput = React.useRef(null);

  const handleClick = event => {
    browserFileInput.current.click();
  };

  const handleRemoveImage = event => {
    setUrl('');
    onFileUpload();
    setIsImageRemoved(true);
  };

  const handleFileDrop = monitor => {
    if (monitor) {
      const files = monitor.getItem().files;
      handleChange(files[0]);
    }
  };

  const handleChange = file => {
    if (file) {
      const validationError = getValidationError(file);
      setValidationError(validationError);
      setFileName(file.name);

      if (!validationError) {
        const url = URL.createObjectURL(file);
        setUrl(url);
        onFileUpload(file);
      }
    }
  };

  const getValidationError = file => {
    if (!isValidImageUrl(file.name)) {
      return `Invalid file type.
      Please use one of the following file types: JPG, GIF, PNG`;
    }
    // restrict uploads to 1 MB for now
    if (file.size > 1048576) {
      return `File is too large (${formatBytes(
        file.size
      )}). Please select a file under 1 MB`;
    }
  };

  useEffect(() => {
    if (!isImageRemoved) setUrl(imageUrl);
  }, [imageUrl, isImageRemoved]);

  return (
    <Box>
      {validationError && (
        <MessageBox
          type='error'
          message={validationError}
          customMessage={
            <Box>
              <Text weight='bold'>
                Error uploading <em>{fileName}</em>:
              </Text>
              <Text>{validationError}</Text>
            </Box>
          }
          onDismiss={() => {
            setValidationError(null);
          }}
        />
      )}
      <Box margin={{ bottom: 'small' }}>
        <Text size='small'>{infoMessage}</Text>
        <Box direction='row' gap='xsmall'>
          <Button
            onClick={handleClick}
            style={{ width: '150px' }}
            margin={{ top: 'small' }}
            label={'Choose image'}
          ></Button>
          {url && typeof setIsImageRemoved === 'function' && (
            <Button
              data-testid='remove-image-button'
              onClick={handleRemoveImage}
              style={{ width: '150px' }}
              margin={{ top: 'small' }}
              label={'Remove image'}
            ></Button>
          )}
        </Box>
      </Box>

      <Box
        direction='row'
        margin={{ bottom: 'small' }}
        style={{ width, height }}
      >
        <DropZone handleClick={handleClick} onDrop={handleFileDrop} url={url} />
      </Box>

      {/* Hide ugly native file input */}
      <input
        type='file'
        ref={browserFileInput}
        onChange={e => handleChange(e.target.files[0])}
        style={{ display: 'none' }}
        accept='image/*'
      />
    </Box>
  );
};

ImageUploader.displayName = 'ImageUploader';

export default ImageUploader;
