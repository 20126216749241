import React, { useEffect, useState } from 'react';
import { FTSelect } from '../components/inputs';
import { getIntegrationItems } from '../utils';

const NO_DEFAULT_OPTION = {
  id: null,
  name: 'None'
};

const PrePingIntegrations = ({ item, canEdit, modifyItem, addError }) => {
  const [integrationItems, setIntegrationItems] = useState([]);

  useEffect(() => {
    if (item.advertiserId) {
      getIntegrationItems(item.advertiserId, setIntegrationItems, addError);
    }
  }, [item.advertiserId, addError]);

  return (
    <FTSelect
      disabled={!canEdit || !item.advertiserId}
      label='Pre-Ping'
      placeholder='Select Request Webhook'
      value={integrationItems.find(cg => cg.id === item.webApiIntegrationId)}
      options={[NO_DEFAULT_OPTION, ...integrationItems]}
      labelKey='name'
      valueKey='id'
      onChange={newValue => {
        modifyItem({ webApiIntegrationId: newValue.id });
      }}
    />
  );
};

export default PrePingIntegrations;
