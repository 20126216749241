import React from 'react';
import { Box } from 'grommet';
import RowItem from '../row-item';

const DataCampaignDetail = ({ item }) => {
  const {
    name,
    partnerName,
    description,
    deliveryCount,
    epr,
    recordsSold,
    revenueGenerated
  } = item;
  return (
    <Box gap='xsmall' flex={false}>
      <RowItem label='Name' value={name} />
      <RowItem label='Desc' value={description} truncate={false} />
      <RowItem label='Partner' value={partnerName} />
      <RowItem label='Deliveries' value={deliveryCount} />
      <RowItem label='EPR' value={epr} />
      <Box margin={{ top: 'medium' }}>
        <RowItem label='Sold (L30)' value={recordsSold} />
        <RowItem label='Rev (L30)' value={revenueGenerated} />
      </Box>
    </Box>
  );
};

export default DataCampaignDetail;
