import React from 'react';
import { Box } from 'grommet';
import RowItem from '../row-item';
import { cleanDate } from '../../utils.js';

const CallCampaignDetail = ({ item }) => {
  const {
    name,
    description,
    advertiserName,
    dateModified,
    lastModifiedBy,
    categories,
    version,
    dateCreated,
    externalCampaignProvider
  } = item;

  return (
    <>
      <Box flex={false}>
        <RowItem label='Name' value={name} />
        <RowItem label='Desc' value={description} truncate={false} />
        <RowItem label='Advertiser' value={advertiserName} />
        <RowItem label='Provider' value={externalCampaignProvider} />
        <RowItem
          label='Tags'
          value={Array.isArray(categories) && categories.join(', ')}
          truncate={false}
        />
        <RowItem
          label='Created'
          value={dateCreated && cleanDate(dateCreated)}
        />
        <RowItem
          label='Modified'
          value={dateModified && cleanDate(dateModified)}
        />
        <RowItem label='By' value={lastModifiedBy} />
        <RowItem label='Version' value={version} />
      </Box>
    </>
  );
};

export default CallCampaignDetail;
