import { Box, TextInput } from 'grommet';
import { useEffect, useState } from 'react';

import { LabeledInput } from '../components/inputs';
import { getDebouncedSuggestions } from '../items/items';
import * as itemTypes from '../items/item-types';
import { isItemNew } from '../utils';

export const AdvertiserInput = ({ item, canEdit, modifyItem }) => {
  const [advertiserName, setAdvertiserName] = useState(
    item.advertiserName || ''
  );
  const [advertiserSuggestions, setAdvertiserSuggestions] = useState([]);
  const [isAdvertiserValid, setIsAdvertiserValid] = useState(true);

  const advertiserDisabled = isItemNew(item.id) ? false : !!item.advertiserId;

  useEffect(() => {
    setAdvertiserName(item.advertiserName);
  }, [item.advertiserName]);

  return (
    <LabeledInput label='Advertiser' legacy={false}>
      <Box
        border={
          !isAdvertiserValid ? { side: 'all', color: 'status-error' } : 'all'
        }
        round='xsmall'
      >
        <TextInput
          size='medium'
          plain
          value={advertiserName}
          onChange={e => {
            setAdvertiserName(e.target.value);
            setIsAdvertiserValid(false);
            getDebouncedSuggestions(
              e.target.value,
              itemTypes.ADVERTISER,
              [],
              setAdvertiserSuggestions
            );
          }}
          disabled={!canEdit || advertiserDisabled}
          title={advertiserName}
          suggestions={advertiserSuggestions}
          onSelect={e => {
            setAdvertiserName(e.suggestion.value.name);
            modifyItem({
              advertiserId: e.suggestion.value.id,
              advertiserName: e.suggestion.value.name
            });
            setIsAdvertiserValid(true);
          }}
        />
      </Box>
    </LabeledInput>
  );
};
