import { createAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import * as itemTypes from '../../../items/item-types';
import {
  getItem as getItemInternal,
  saveItem as saveItemInternal
} from '../../../items/item.actions';
import { keyFromItemType } from '../../../routing-utils';
import { authorizedFetch } from '../../../utils';

export const receiveAdCreative = createAction('receiveAdCreative');
export const setAdCreativeIsBusy = createAction('setAdCreativeIsBusy');
export const modifyAdCreative = createAction('modifyAdCreative');
export const closeAdCreative = createAction('closeAdCreative');

const adCreativeModel = (id, name, params) => {
  return {
    id,
    name,
    type: itemTypes.AD_CREATIVE,
    [keyFromItemType(itemTypes.CAMPAIGN)]: params.campaignid
  };
};

export const getItem = getItemInternal(
  adCreativeModel,
  receiveAdCreative,
  setAdCreativeIsBusy
);

export const saveItem = (() => {
  let thumbnailDataUrl;

  return saveItemInternal(
    modifyAdCreative,
    item => {
      if ((item.thumbnail || item.thumbnailData) && !item.thumbnailAltText) {
        return 'Alt text is required for the image.';
      }
    },
    itemCopy => {
      thumbnailDataUrl = itemCopy.thumbnailData;
      delete itemCopy.thumbnailData;
    },
    async (item, { id, version }, dispatch, modifyItemAction) => {
      // /upload endpoint requires expected version for each call so have to perform these synchronously
      let expectedVersion = version;

      if (thumbnailDataUrl) {
        const thumbnailResponse = await uploadAdCreativeImage(
          thumbnailDataUrl,
          id,
          item.campaignId,
          expectedVersion,
          dispatch,
          modifyItemAction
        );
        thumbnailDataUrl = null;
        expectedVersion = thumbnailResponse.version;
      }
    }
  );
})();

async function uploadAdCreativeImage(
  url,
  adCreativeId,
  campaignId,
  version,
  dispatch,
  modifyItemAction
) {
  const formData = new FormData();
  const imageData = await fetch(url).then(r => r.blob());
  formData.append('file', imageData);
  formData.append('type', 'thumbnail');
  formData.append('expectedVersion', version);

  try {
    const response = await authorizedFetch(
      `/api/${itemTypes.CAMPAIGN}/${campaignId}/${itemTypes.AD_CREATIVE}/${adCreativeId}/upload`,
      'POST',
      formData,
      null,
      'multipart/form-data'
    );

    await dispatch(
      modifyItemAction({
        id: adCreativeId,
        newProperties: {
          thumbnail: response.data.url,
          removeThumbnailUrl: false,
          version: response.version,
          id: response.id
        }
      })
    );
    return response;
  } catch (err) {
    return {
      isError: true,
      primary: `Unable to upload image.`,
      secondary: err.Message
    };
  }
}
