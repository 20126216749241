import React, { memo, useState } from 'react';
import { Box, Grid } from 'grommet';
import { debounce } from 'lodash';
import { authorizedFetch } from '../utils';
import { FTTagInput, FTTextArea, FTTextInput } from './inputs';

export const getSuggestions = debounce(
  (searchText, exclude, setSuggestions) => {
    authorizedFetch('/api/search/eventflow*/_search', 'POST', {
      size: 0,
      aggs: {
        matching_tag: {
          filter: { match_phrase_prefix: { categories: searchText } },
          aggs: {
            tag: {
              terms: {
                field: 'categories.keyword',
                exclude,
                size: 10
              }
            }
          }
        }
      }
    })
      .then(response => {
        setSuggestions(
          response.aggregations.matching_tag.tag.buckets.map(b => b.key)
        );
      })
      .catch(e => {
        console.log(e);
        setSuggestions([]);
      });
  },
  200
);

const ItemInfo = memo(({ item, modifyItem, canEdit, children }) => {
  const { name, description, categories } = item;
  const [suggestions, setSuggestions] = useState([]);

  return (
    <Box fill overflow={{ vertical: 'auto' }} pad='medium'>
      <Grid
        columns={['repeat(3, minmax(250px, 1fr))']}
        gap='medium'
        justifyContent='start'
      >
        <Box gap='medium'>
          <FTTextInput
            label='Name'
            disabled={!canEdit}
            value={name || ''}
            onChange={newValue => modifyItem({ name: newValue })}
          />
          <FTTagInput
            label='Tags'
            disabled={!canEdit}
            tags={categories}
            onChange={newValue => modifyItem({ categories: newValue })}
            suggestions={suggestions}
            onTextChange={text =>
              getSuggestions(text, categories, setSuggestions)
            }
          />
        </Box>
        <FTTextArea
          height='100%'
          label='Description'
          disabled={!canEdit}
          value={description || ''}
          onChange={newValue => {
            modifyItem({ description: newValue });
          }}
        />
        {children}
      </Grid>
    </Box>
  );
});

ItemInfo.displayName = 'ItemInfo';

export default ItemInfo;
