import React from 'react';
import { Box, Text } from 'grommet';
import { NavLink } from 'react-router-dom';
import { itemTypeNames } from '../items/item-types';

const RecentItem = ({ itemName, itemType, itemId }) => {
  return (
    <Box width='small' pad='xsmall'>
      <NavLink
        data-testid={`Ref ${itemType} Link`}
        to={`/${itemType}/${itemId}/info`}
      >
        <Text title={itemName} truncate={true}>
          {itemName}
        </Text>
      </NavLink>
    </Box>
  );
};

const RecentItemList = ({ itemType, recentItems }) => {
  if (!recentItems?.length) {
    return null;
  }
  return (
    <Box margin={'small'}>
      <Text weight='bold'>{`Recent ${itemTypeNames[itemType]}s`}</Text>
      <Box>
        {recentItems.map(({ id, name }) => {
          return (
            <RecentItem
              key={id}
              itemName={name}
              itemType={itemType}
              itemId={id}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default RecentItemList;
