import React, { useEffect, useState } from 'react';
import { Box } from 'grommet';
import ReferencedItems from './referenced-items';
import RowItem from '../row-item';
import { cleanDate } from '../../utils.js';
import * as itemTypes from '../../items/item-types';
import { addError } from '../../toasts/toasts.actions.js';
import { fetchItemsByType } from '../../items/items.actions.js';

const AdvertiserDetail = ({ item }) => {
  const {
    name,
    description,
    categories,
    dateModified,
    lastModifiedBy,
    version,
    advertiserType
    // managerName
  } = item;
  const [usedBy, setUsedBy] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const { items } = await fetchItemsByType({
          itemType: itemTypes.CAMPAIGN,
          size: 10,
          filters: [
            {
              field: 'advertiserId.keyword',
              values: [item.id]
            }
          ]
        });
        setUsedBy(items);
      } catch (e) {
        addError('Unable to get Ad Groups', e.Message);
      }
    };
    getData();
  }, [item.id]);

  return (
    <>
      <Box flex={false}>
        <RowItem label='Name' value={name} />
        <RowItem label='Desc' value={description} truncate={false} />
        {/* <RowItem label='Manager' value={managerName = 'MANAGER'} />  */}
        <RowItem label='Type' value={advertiserType} />
        <RowItem
          label='Tags'
          value={Array.isArray(categories) && categories.join(', ')}
          truncate={false}
        />
        <RowItem
          label='Modified'
          value={dateModified && cleanDate(dateModified)}
        />
        <RowItem label='By' value={lastModifiedBy} />
        <RowItem label='Version' value={version} />
      </Box>

      <Box margin={{ top: 'large' }} overflow='auto'>
        <ReferencedItems
          title='Active Campaigns:'
          itemType={itemTypes.CAMPAIGN}
          items={usedBy}
        />
      </Box>
    </>
  );
};

export default AdvertiserDetail;
