import { authorizedFetch } from '../utils.js';
import { getReadModelFromItemType, itemTypesByReadModel } from './item-types';

export const searchItems = query =>
  authorizedFetch(`/api/search/eventflow*/_search`, 'POST', {
    query: {
      bool: {
        must: [
          {
            multi_match: {
              query: query,
              fields: ['id^4', 'name^3', 'categories^1.4', '*'],
              type: 'phrase_prefix',
              lenient: true
            }
          }
        ],
        must_not: [
          {
            exists: { field: 'dataCampaignId' } // data deliveries
          },
          {
            exists: { field: 'deliveryPriority' } // data delivery groups
          },
          {
            exists: { field: 'dataDeliveries' } // data campaigns
          }
        ]
      }
    }
  });

export const searchItemsByType = ({
  itemTypes,
  additionalFilters,
  query = '',
  excludeIds = [],
  sort = {},
  from = 0,
  size = 20
}) => {
  const readModels = itemTypes.map(itemType =>
    getReadModelFromItemType(itemType)
  );

  return new Promise((resolve, reject) => {
    const musts = [];
    if (additionalFilters) {
      if (Array.isArray(additionalFilters)) {
        musts.push(...additionalFilters);
      } else {
        //default behavior is a single object term filter
        musts.push({ term: additionalFilters });
      }
    }

    let must_not;
    if (excludeIds.length > 0) {
      must_not = {
        terms: {
          'id.keyword': excludeIds
        }
      };
    }

    const queryRequest = {
      bool: {
        must: musts,
        must_not
      }
    };

    if (query) {
      queryRequest.bool.filter = {
        multi_match: {
          query: query,
          type: 'phrase_prefix'
        }
      };
    }

    authorizedFetch(
      `/api/search/${readModels
        .map(rm => `eventflow-${rm}`)
        .join(',')}/_search`,
      'POST',
      {
        from,
        size,
        sort: sort?._script ? sort : [sort],
        query: queryRequest
      }
    )
      .then(response => {
        resolve({
          items: response.hits.hits.map(h => {
            const readModel = h._index.replace('eventflow-', '');
            return {
              type: itemTypesByReadModel[readModel],
              ...h._source
            };
          }),
          totalMatches: response.hits.total
        });
      })
      .catch(reject);
  });
};

export const fetchItemsByType = ({
  itemType,
  filters = [],
  query = '',
  orderByField = 'dateModified',
  orderByAsc,
  from = 0,
  size = 20
}) => {
  return new Promise((resolve, reject) => {
    authorizedFetch(`/api/${itemType}/search`, 'POST', {
      query,
      from,
      size,
      orderByField,
      orderByAsc,
      filters
    })
      .then(response => {
        resolve({
          items: response.items.map(item => {
            return {
              type: itemType,
              ...item
            };
          }),
          totalMatches: response.totalHits
        });
      })
      .catch(reject);
  });
};
