export const SELECT_MODAL_POSITION = 'SELECT_MODAL_POSITION';
export const SELECT_CLOSE_BUTTON_POSITION = 'SELECT_CLOSE_BUTTON_POSITION';
export const SELECT_CLOSE_BUTTON_COLOR = 'SELECT_CLOSE_BUTTON_COLOR';
export const SELECT_CLOSE_BUTTON_COLOR_STROKE =
  'SELECT_CLOSE_BUTTON_COLOR_STROKE';
export const SELECT_MODAL_WIDTH = 'SELECT_MODAL_WIDTH';
export const SELECT_MODAL_CORNER_SHAPE = 'SELECT_MODAL_CORNER_SHAPE';
export const SELECT_PAGE_OVERLAY_COLOR = 'SELECT_PAGE_OVERLAY_COLOR';
export const SELECT_OFFER_BG_COLOR = 'SELECT_OFFER_BACKGROUND_COLOR';
export const SET_EDITING = 'SET_EDITING';
export const SELECT_BUTTON_STYLE = 'SELECT_BUTTON_STYLE';
export const SELECT_BUTTON_POSITIVE_TEXT_STYLE =
  'SELECT_BUTTON_POSITIVE_TEXT_STYLE';
export const SELECT_BUTTON_NEGATIVE_TEXT_STYLE =
  'SELECT_BUTTON_NEGATIVE_TEXT_STYLE';
export const SELECT_BUTTON_NEUTRAL_TEXT_STYLE =
  'SELECT_BUTTON_NEUTRAL_TEXT_STYLE';
export const SELECT_BUTTON_POSITIVE_TEXT_WEIGHT =
  'SELECT_BUTTON_POSITIVE_TEXT_WEIGHT';
export const SELECT_BUTTON_NEGATIVE_TEXT_WEIGHT =
  'SELECT_BUTTON_NEGATIVE_TEXT_WEIGHT';
export const SELECT_BUTTON_NEUTRAL_TEXT_WEIGHT =
  'SELECT_BUTTON_NEUTRAL_TEXT_WEIGHT';
export const SELECT_BUTTON_POSITIVE_BG_COLOR =
  'SELECT_BUTTON_POSITIVE_BG_COLOR';
export const SELECT_BUTTON_POSITIVE_TEXT_COLOR =
  'SELECT_BUTTON_POSITIVE_TEXT_COLOR';
export const SELECT_BUTTON_POSITIVE_BORDER_COLOR =
  'SELECT_BUTTON_POSITIVE_BORDER_COLOR';
export const SELECT_BUTTON_POSITIVE_BORDER_WIDTH =
  'SELECT_BUTTON_POSITIVE_BORDER_WIDTH';
export const SELECT_BUTTON_POSITIVE_BORDER_STYLE =
  'SET_BUTTON_POSITIVE_BORDER_STYLE';
export const SELECT_BUTTON_NEGATIVE_BG_COLOR =
  'SELECT_BUTTON_NEGATIVE_BG_COLOR';
export const SELECT_BUTTON_NEGATIVE_TEXT_COLOR =
  'SELECT_BUTTON_NEGATIVE_TEXT_COLOR';
export const SELECT_BUTTON_NEGATIVE_BORDER_COLOR =
  'SELECT_BUTTON_NEGATIVE_BORDER_COLOR';
export const SELECT_BUTTON_NEGATIVE_BORDER_WIDTH =
  'SELECT_BUTTON_NEGATIVE_BORDER_WIDTH';
export const SELECT_BUTTON_NEGATIVE_BORDER_STYLE =
  'SET_BUTTON_NEGATIVE_BORDER_STYLE';
export const SELECT_BUTTON_NEUTRAL_BG_COLOR = 'SELECT_BUTTON_NEUTRAL_BG_COLOR';
export const SELECT_BUTTON_NEUTRAL_TEXT_COLOR =
  'SELECT_BUTTON_NEUTRAL_TEXT_COLOR';
export const SELECT_BUTTON_NEUTRAL_BORDER_COLOR =
  'SELECT_BUTTON_NEUTRAL_BORDER_COLOR';
export const SELECT_BUTTON_NEUTRAL_BORDER_WIDTH =
  'SELECT_BUTTON_NEUTRAL_BORDER_WIDTH';
export const SELECT_BUTTON_NEUTRAL_BORDER_STYLE =
  'SET_BUTTON_NEUTRAL_BORDER_STYLE';
export const SELECT_CTA_BUTTON_ORDER = 'SELECT_CTA_BUTTON_ORDER';
export const SELECT_BG_COLOR_ONE = 'SELECT_BG_COLOR_ONE';
export const SELECT_BG_COLOR_ONE_TEXT_COLOR = 'SELECT_BG_COLOR_ONE_TEXT_COLOR';
export const SELECT_BG_COLOR_TWO = 'SELECT_BG_COLOR_TWO';
export const SELECT_BG_COLOR_TWO_TEXT_COLOR = 'SELECT_BG_COLOR_TWO_TEXT_COLOR';
export const SELECT_BG_COLOR_THREE = 'SELECT_BG_COLOR_THREE';
export const SELECT_BG_COLOR_THREE_TEXT_COLOR =
  'SELECT_BG_COLOR_THREE_TEXT_COLOR';
export const SELECT_BG_COLOR_FOUR = 'SELECT_BG_COLOR_FOUR';
export const SELECT_BG_COLOR_FOUR_TEXT_COLOR =
  'SELECT_BG_COLOR_FOUR_TEXT_COLOR';
export const SELECT_TEXT_COLOR_ONE = 'SELECT_TEXT_COLOR_ONE';
export const SELECT_TEXT_COLOR_TWO = 'SELECT_TEXT_COLOR_TWO';
export const SELECT_TEXT_COLOR_THREE = 'SELECT_TEXT_COLOR_THREE';
export const SELECT_FONT_SIZE = 'SELECT_FONT_SIZE';
export const SELECT_DEFAULT_FONT_FAMILY = 'SELECT_DEFAULT_FONT_FAMILY';
export const SELECT_DEFAULT_FONT_COLOR = 'SELECT_DEFAULT_FONT_COLOR';
export const SELECT_SUPPORTING_FONT_FAMILY = 'SELECT_SUPPORTING_FONT_FAMILY';
export const SELECT_SUPPORTING_FONT_COLOR = 'SELECT_SUPPORTING_FONT_COLOR';
export const SELECT_OFFER_IMAGE_PLACEMENT = 'SELECT_OFFER_IMAGE_PLACEMENT';
export const SELECT_OFFER_BLOCK_IMG_FIRST = 'SELECT_OFFER_BLOCK_IMG_FIRST';
export const SELECT_OFFER_BLOCK_IMG_REMAINING =
  'SELECT_OFFER_BLOCK_IMG_REMAINING';
export const SELECT_OFFER_BLOCK_IMG_BORDER_COLOR =
  'SELECT_OFFER_BLOCK_IMG_BORDER_COLOR';
export const SELECT_OFFER_BLOCK_IMG_BORDER_WIDTH =
  'SELECT_OFFER_BLOCK_IMG_BORDER_WIDTH';
export const SELECT_OFFER_BLOCK_IMG_BORDER_STYLE =
  'SELECT_OFFER_BLOCK_IMG_BORDER_STYLE';
export const SELECT_OFFER_BLOCK_IMG_BORDER_RADIUS =
  'SELECT_OFFER_BLOCK_IMG_BORDER_RADIUS';
export const SET_PREVIEW_MODE = 'SET_PREVIEW_MODE';
export const PREVIEW_MODE_DESKTOP = 'desktop';
export const PREVIEW_MODE_MOBILE = 'mobile';
export const SELECT_BORDER_COLOR_ONE = 'SELECT_BORDER_COLOR_ONE';
export const SELECT_BORDER_WIDTH_ONE = 'SELECT_BORDER_WIDTH_ONE';
export const SELECT_BORDER_STYLE_ONE = 'SELECT_BORDER_STYLE_ONE';
export const SELECT_BORDER_COLOR_TWO = 'SELECT_BORDER_COLOR_TWO';
export const SELECT_BORDER_WIDTH_TWO = 'SELECT_BORDER_WIDTH_TWO';
export const SELECT_BORDER_STYLE_TWO = 'SELECT_BORDER_STYLE_TWO';
export const SELECT_BORDER_COLOR_THREE = 'SELECT_BORDER_COLOR_THREE';
export const SELECT_BORDER_WIDTH_THREE = 'SELECT_BORDER_WIDTH_THREE';
export const SELECT_BORDER_STYLE_THREE = 'SELECT_BORDER_STYLE_THREE';
export const SELECT_BORDER_COLOR_FOUR = 'SELECT_BORDER_COLOR_FOUR';
export const SELECT_BORDER_WIDTH_FOUR = 'SELECT_BORDER_WIDTH_FOUR';
export const SELECT_BORDER_STYLE_FOUR = 'SELECT_BORDER_STYLE_FOUR';
export const SELECT_FOOTER_BORDER_COLOR = 'SELECT_FOOTER_BORDER_COLOR';
export const SELECT_FOOTER_BORDER_WIDTH = 'SELECT_FOOTER_BORDER_WIDTH';
export const SELECT_FOOTER_BORDER_STYLE = 'SELECT_FOOTER_BORDER_STYLE';

export const MOBILE_PREVIEW_CSS = `@media screen and (max-width:499px){
  .fluent-af-exp-modal_close.part-close-location{
    position:relative;
    top:36px;
    left:-5px;
    margin-top:0;
  }
  .fluent-af-exp-modal_close.part-close-btn{
    background:0 0 !important;
  }
  .fluent-af-exp-modal_close-container{
    margin-top:-15px;
  }
  .fluent-af-exp__wrapper{
    height:auto;
    padding-top:.5rem;
  }
  .fluent-af-exp__wrapper-container{
    width:100%;
    padding-left:5px;
    padding-right:5px;
  }
}`;

export const AD_UNIT_THEME = {
  // Page Overlay Settings
  modalPosition: [
    {
      displayName: 'Top',
      field: 'top',
      css: {
        selector: '.part-exp-location',
        properties: 'padding-top: 2rem;'
      }
    },
    {
      displayName: 'Middle',
      field: 'middle',
      css: {
        selector: '.part-exp-location',
        properties: 'height: 100%; padding-top: 0rem;'
      }
    }
  ],
  closeButtonPosition: [
    {
      displayName: 'Browser Top Right',
      field: 'browser-top-right',
      css: [
        {
          selector: '.part-close-location',
          properties: 'position: absolute; right: 10px; top: 10px;'
        },
        {
          selector: '.part-logo',
          properties: 'margin-right: 1rem;'
        }
      ]
    },
    {
      displayName: 'Modal Top Right (Outside)',
      field: 'modal-top-right-outside',
      css: [
        {
          selector: '.part-close-location',
          properties: 'position: relative; right: -30px; top: 0px;'
        },
        {
          selector: '.part-logo',
          properties: 'margin-right: 1rem;'
        }
      ]
    },
    {
      displayName: 'Modal Top Right (Inside)',
      field: 'modal-top-right-inside',
      css: [
        {
          selector: '.part-close-location',
          properties: 'position: relative; right: .7rem; top: 2.85rem;'
        },
        {
          selector: '.part-logo',
          properties: 'margin-right: 48px;'
        }
      ]
    }
  ],
  closeButton: {
    bgColor: (value = '') => ({
      css: {
        selector: '.part-close-btn',
        property: 'background-color',
        properties: `background-color: ${value};`
      }
    }),
    strokeColor: (value = '#fff') => ({
      css: {
        selector: '.part-close-btn',
        property: 'stroke',
        properties: `stroke: ${value};`
      }
    })
  },
  modalWidth: [
    {
      displayName: 'Small',
      field: 'small',
      css: {
        selector: '.part-exp-width',
        properties: 'width: 500px;'
      }
    },
    {
      displayName: 'Medium',
      field: 'medium',
      css: {
        selector: '.part-exp-width',
        properties: 'width: 625px;'
      }
    },
    {
      displayName: 'Large',
      field: 'large',
      css: {
        selector: '.part-exp-width',
        properties: 'width: 750px;'
      }
    }
  ],
  modalCornerShape: [
    {
      displayName: 'Square',
      field: 'square',
      css: {
        selector: '.part-exp-offer-box',
        properties: 'border-radius: 0px;'
      }
    },
    {
      displayName: 'Small Round',
      field: 'small-round',
      css: {
        selector: '.part-exp-offer-box',
        properties: 'border-radius: .625rem;'
      }
    },
    {
      displayName: 'Large Round',
      field: 'large-round',
      css: {
        selector: '.part-exp-offer-box',
        properties: 'border-radius: 1.5rem;'
      }
    }
  ],
  pageOverlayColor: (value = 'rgba(51, 51, 51, 0.6)') => ({
    css: {
      selector: '.fluent-af-exp-modal--overlay-mode',
      property: 'background-color',
      properties: `background-color: ${value};`
    }
  }),

  // Offer Container Settings
  offerBackgroundColor: (value = '#fff') => ({
    css: {
      selector: '.part-exp-mode',
      property: 'background-color',
      properties: `background-color: ${value};`
    }
  }),
  offerImagePlacement: [
    {
      displayName: 'Right',
      field: 'right',
      css: [
        {
          selector: '.part-offer-img-location img',
          properties: 'margin: 0 0 0 1rem;'
        },
        {
          selector: '.part-offer-img-location',
          properties: 'flex-direction: row-reverse;'
        }
      ]
    },
    {
      displayName: 'Left',
      field: 'left',
      css: [
        {
          selector: '.part-offer-img-location img',
          properties: 'margin: 0 1rem 0 0;'
        },
        {
          selector: '.part-offer-img-location',
          properties: 'flex-direction: row;'
        }
      ]
    }
  ],
  offerBlockImgFirst: (value = 'none') => ({
    css: {
      selector: '.offer-block_offer-img.offer-block_offer-img_first',
      property: 'display',
      properties: `display: ${value};`
    }
  }),
  offerBlockImgRemaining: (value = 'block') => ({
    css: {
      selector: '.offer-block_offer-img',
      property: 'display',
      properties: `display: ${value};`
    }
  }),
  offerBlockImgBorderColor: (value = '') => ({
    css: {
      selector: '.part-img-border',
      property: 'border-color',
      properties: `border-color: ${value};`
    }
  }),
  offerBlockImgBorderWidth: (value = '0px') => ({
    css: {
      selector: '.part-img-border',
      property: 'border-width',
      properties: `border-width: ${value};`
    }
  }),
  offerBlockImgBorderStyle: [
    {
      displayName: 'None',
      field: 'none',
      css: {
        selector: '.part-img-border',
        properties: 'border: none;'
      }
    },
    {
      displayName: 'Solid',
      field: 'solid',
      css: {
        selector: '.part-img-border',
        properties: 'border-style: solid;'
      }
    }
  ],
  offerBlockImgBorderRadius: [
    {
      displayName: 'Square',
      field: 'square',
      css: {
        selector: '.part-img-border',
        properties: 'border-radius: 0rem;'
      }
    },
    {
      displayName: 'Small Round',
      field: 'small-round',
      css: {
        selector: '.part-img-border',
        properties: 'border-radius: .5rem;'
      }
    },
    {
      displayName: 'Large Round',
      field: 'large-round',
      css: {
        selector: '.part-img-border',
        properties: 'border-radius: 1rem;'
      }
    },
    {
      displayName: 'Circle',
      field: 'circle',
      css: {
        selector: '.part-img-border',
        properties: 'border-radius: 50%;'
      }
    }
  ],

  // Font settings
  fontSizes: [
    {
      displayName: '10',
      field: '10px',
      css: {
        selector: 'html',
        properties: 'font-size: 10px'
      }
    },
    {
      displayName: '11',
      field: '11px',
      css: {
        selector: 'html',
        properties: 'font-size: 11px'
      }
    },
    {
      displayName: '12',
      field: '12px',
      css: {
        selector: 'html',
        properties: 'font-size: 12px'
      }
    },
    {
      displayName: '13',
      field: '13px',
      css: {
        selector: 'html',
        properties: 'font-size: 13px'
      }
    },
    {
      displayName: '14',
      field: '14px',
      css: {
        selector: 'html',
        properties: 'font-size: 14px'
      }
    },
    {
      displayName: '15',
      field: '15px',
      css: {
        selector: 'html',
        properties: 'font-size: 15px'
      }
    },
    {
      displayName: '16',
      field: '16px',
      css: {
        selector: 'html',
        properties: 'font-size: 16px'
      }
    },
    {
      displayName: '17',
      field: '17px',
      css: {
        selector: 'html',
        properties: 'font-size: 17px'
      }
    },
    {
      displayName: '18',
      field: '18px',
      css: {
        selector: 'html',
        properties: 'font-size: 18px'
      }
    },
    {
      displayName: '19',
      field: '19px',
      css: {
        selector: 'html',
        properties: 'font-size: 19px'
      }
    },
    {
      displayName: '20',
      field: '20px',
      css: {
        selector: 'html',
        properties: 'font-size: 20px'
      }
    },
    {
      displayName: '21',
      field: '21px',
      css: {
        selector: 'html',
        properties: 'font-size: 21px'
      }
    },
    {
      displayName: '22',
      field: '22px',
      css: {
        selector: 'html',
        properties: 'font-size: 22px'
      }
    },
    {
      displayName: '23',
      field: '23px',
      css: {
        selector: 'html',
        properties: 'font-size: 23px'
      }
    },
    {
      displayName: '24',
      field: '24px',
      css: {
        selector: 'html',
        properties: 'font-size: 24px'
      }
    }
  ],
  defaultFontFamilies: [
    {
      displayName: 'Arial, Sans-Serif',
      field: "'Arial',sans-serif",
      css: {
        selector: '.part-default-txt',
        properties: 'font-family: Arial, sans-serif;'
      }
    },
    {
      displayName: 'Raleway, Sans-Serif',
      field: "'Raleway',sans-serif",
      css: {
        selector: '.part-default-txt',
        properties: 'font-family: Raleway, sans-serif;'
      }
    },
    {
      displayName: 'Montserrat, Sans-Serif',
      field: "'Montserrat',sans-serif",
      css: {
        selector: '.part-default-txt',
        properties: 'font-family: Montserrat, sans-serif;'
      }
    },
    {
      displayName: 'Roboto, Sans-Serif',
      field: "'Roboto',sans-serif",
      css: {
        selector: '.part-default-txt',
        properties: 'font-family: Roboto, sans-serif;'
      }
    },
    {
      displayName: 'Roboto Slab, Sans-Serif',
      field: "'Roboto Slab',sans-serif",

      css: {
        selector: '.part-default-txt',
        properties: 'font-family: Roboto Slab, sans-serif;'
      }
    },
    {
      displayName: 'Poppins, Sans-Serif',
      field: "'Poppins',sans-serif",
      css: {
        selector: '.part-default-txt',
        properties: 'font-family: Poppins, sans-serif;'
      }
    },
    {
      displayName: 'Oswald, Sans-Serif',
      field: "'Oswald',sans-serif",
      css: {
        selector: '.part-default-txt',
        properties: 'font-family: Oswald, sans-serif;'
      }
    },
    {
      displayName: 'Open Sans, Sans-Serif',
      field: "'Open Sans',sans-serif",
      css: {
        selector: '.part-default-txt',

        properties: 'font-family: Open Sans, sans-serif;'
      }
    },
    {
      displayName: 'Lato, Sans-Serif',
      field: "'Lato',sans-serif",
      css: {
        selector: '.part-default-txt',
        properties: 'font-family: Lato, sans-serif;'
      }
    },
    {
      displayName: 'Merriweather, Serif',
      field: "'Merriweather',serif",
      css: {
        selector: '.part-default-txt',
        properties: 'font-family: Merriweather, serif;'
      }
    },
    {
      displayName: 'Times New Roman, Serif',
      field: "'Times New Roman',serif",
      css: {
        selector: '.part-default-txt',
        properties: 'font-family: Times New Roman, serif;'
      }
    },
    {
      displayName: 'Outfit, Sans-Serif',
      field: "'Outfit',sans-serif",
      css: {
        selector: '.part-default-txt',
        properties: 'font-family: Outfit, sans-serif;'
      }
    }
  ],
  supportingFontFamilies: [
    {
      displayName: 'Arial, Sans-Serif',
      field: "'Arial',sans-serif",
      css: {
        selector: '.part-supporting-txt',
        properties: 'font-family: Arial, sans-serif;'
      }
    },
    {
      displayName: 'Raleway, Sans-Serif',
      field: "'Raleway',sans-serif",
      css: {
        selector: '.part-supporting-txt',
        properties: 'font-family: Raleway, sans-serif;'
      }
    },
    {
      displayName: 'Montserrat, Sans-Serif',
      field: "'Montserrat',sans-serif",
      css: {
        selector: '.part-supporting-txt',
        properties: 'font-family: Montserrat, sans-serif;'
      }
    },
    {
      displayName: 'Roboto, Sans-Serif',
      field: "'Roboto',sans-serif",
      css: {
        selector: '.part-supporting-txt',
        properties: 'font-family: Roboto, sans-serif;'
      }
    },
    {
      displayName: 'Roboto Slab, Sans-Serif',
      field: "'Roboto Slab',sans-serif",

      css: {
        selector: '.part-supporting-txt',
        properties: 'font-family: Roboto Slab, sans-serif;'
      }
    },
    {
      displayName: 'Poppins, Sans-Serif',
      field: "'Poppins',sans-serif",
      css: {
        selector: '.part-supporting-txt',
        properties: 'font-family: Poppins, sans-serif;'
      }
    },
    {
      displayName: 'Oswald, Sans-Serif',
      field: "'Oswald',sans-serif",
      css: {
        selector: '.part-supporting-txt',
        properties: 'font-family: Oswald, sans-serif;'
      }
    },
    {
      displayName: 'Open Sans, Sans-Serif',
      field: "'Open Sans',sans-serif",
      css: {
        selector: '.part-supporting-txt',

        properties: 'font-family: Open Sans, sans-serif;'
      }
    },
    {
      displayName: 'Lato, Sans-Serif',
      field: "'Lato',sans-serif",
      css: {
        selector: '.part-supporting-txt',
        properties: 'font-family: Lato, sans-serif;'
      }
    },
    {
      displayName: 'Merriweather, Serif',
      field: "'Merriweather',serif",
      css: {
        selector: '.part-supporting-txt',
        properties: 'font-family: Merriweather, serif;'
      }
    },
    {
      displayName: 'Times New Roman, Serif',
      field: "'Times New Roman',serif",
      css: {
        selector: '.part-supporting-txt',
        properties: 'font-family: Times New Roman, serif;'
      }
    },
    {
      displayName: 'Outfit, Sans-Serif',
      field: "'Outfit',sans-serif",
      css: {
        selector: '.part-supporting-txt',
        properties: 'font-family: Outfit, sans-serif;'
      }
    }
  ],
  defaultFontColor: (value = '#444') => ({
    css: {
      selector: '.part-default-txt',
      property: 'color',
      properties: `color: ${value};`
    }
  }),
  supportingFontColor: (value = '#444') => ({
    css: {
      selector: '.part-supporting-txt',
      property: 'color',
      properties: `color: ${value};`
    }
  }),

  // Button settings
  buttons: {
    styles: [
      {
        displayName: 'Rounded',
        field: 'rounded',
        css: {
          selector: '.part-btn-style',
          properties: 'border-radius: 5px;'
        }
      },
      {
        displayName: 'Square',
        field: 'square',
        css: {
          selector: '.part-btn-style',
          properties: 'border-radius: 0px;'
        }
      },
      {
        displayName: 'Pill',
        field: 'pill',
        css: {
          selector: '.part-btn-style',
          properties: 'border-radius: 50px;'
        }
      }
    ],
    positiveTextStyles: [
      {
        displayName: 'Default',
        field: 'default',
        css: {
          selector: '.part-btn-positive',
          properties: 'text-transform: none;'
        }
      },
      {
        displayName: 'All Caps',
        field: 'uppercase',
        css: {
          selector: '.part-btn-positive',
          properties: 'text-transform: uppercase;'
        }
      },
      {
        displayName: 'All Lowercase',
        field: 'lowercase',
        css: {
          selector: '.part-btn-positive',
          properties: 'text-transform: lowercase;'
        }
      },
      {
        displayName: 'First Letter Caps',
        field: 'capitalize',
        css: {
          selector: '.part-btn-positive',
          properties: 'text-transform: capitalize;'
        }
      }
    ],
    negativeTextStyles: [
      {
        displayName: 'Default',
        field: 'default',
        css: {
          selector: '.part-btn-negative',
          properties: 'text-transform: none;'
        }
      },
      {
        displayName: 'All Caps',
        field: 'uppercase',
        css: {
          selector: '.part-btn-negative',
          properties: 'text-transform: uppercase;'
        }
      },
      {
        displayName: 'All Lowercase',
        field: 'lowercase',
        css: {
          selector: '.part-btn-negative',
          properties: 'text-transform: lowercase;'
        }
      },
      {
        displayName: 'First Letter Caps',
        field: 'capitalize',
        css: {
          selector: '.part-btn-negative',
          properties: 'text-transform: capitalize;'
        }
      }
    ],
    neutralTextStyles: [
      {
        displayName: 'Default',
        field: 'default',
        css: {
          selector: '.part-btn-neutral',
          properties: 'text-transform: none;'
        }
      },
      {
        displayName: 'All Caps',
        field: 'uppercase',
        css: {
          selector: '.part-btn-neutral',
          properties: 'text-transform: uppercase;'
        }
      },
      {
        displayName: 'All Lowercase',
        field: 'lowercase',
        css: {
          selector: '.part-btn-neutral',
          properties: 'text-transform: lowercase;'
        }
      },
      {
        displayName: 'First Letter Caps',
        field: 'capitalize',
        css: {
          selector: '.part-btn-neutral',
          properties: 'text-transform: capitalize;'
        }
      }
    ],
    positiveTextWeight: [
      {
        displayName: 'Default',
        field: 'default',
        css: {
          selector: '.part-btn-positive',
          properties: 'font-weight: normal;'
        }
      },
      {
        displayName: 'Bold',
        field: 'bold',
        css: {
          selector: '.part-btn-positive',
          properties: 'font-weight: bold;'
        }
      }
    ],
    negativeTextWeight: [
      {
        displayName: 'Default',
        field: 'default',
        css: {
          selector: '.part-btn-negative',
          properties: 'font-weight: normal;'
        }
      },
      {
        displayName: 'Bold',
        field: 'bold',
        css: {
          selector: '.part-btn-negative',
          properties: 'font-weight: bold;'
        }
      }
    ],
    neutralTextWeight: [
      {
        displayName: 'Default',
        field: 'default',
        css: {
          selector: '.part-btn-neutral',
          properties: 'font-weight: normal;'
        }
      },
      {
        displayName: 'Bold',
        field: 'bold',
        css: {
          selector: '.part-btn-neutral',
          properties: 'font-weight: bold;'
        }
      }
    ],

    ctaOrder: [
      {
        displayName: 'Standard Display',
        field: 'standard',
        css: {
          selector: '.part-cta-order',
          properties: 'flex-direction: row;'
        }
      },
      {
        displayName: 'Reverse Display',
        field: 'reverse',
        css: {
          selector: '.part-cta-order',
          properties: 'flex-direction: row-reverse;'
        }
      }
    ],
    positiveBgColor: (value = '#df332a') => ({
      css: {
        selector: '.part-btn-positive',
        property: 'background-color',
        properties: `background-color: ${value};`
      }
    }),
    positiveTxtColor: (value = '#fff') => ({
      css: {
        selector: '.part-btn-positive',
        property: 'color',
        properties: `color: ${value};`
      }
    }),
    positiveBorderColor: (value = '#df332a') => ({
      css: {
        selector: '.part-btn-positive',
        property: 'border-color',
        properties: `border-color: ${value};`
      }
    }),
    positiveBorderWidth: (value = '0px') => ({
      css: {
        selector: '.part-btn-positive',
        property: 'border-width',
        properties: `border-width: ${value};`
      }
    }),
    positiveButtonHover: {
      css: {
        selector: '.part-btn-positive:hover',
        property: 'opacity',
        properties: 'opacity: 0.8;'
      }
    },
    positiveButtonActive: {
      css: {
        selector: '.part-btn-positive:active',
        property: 'opacity',
        properties: 'opacity: 0.8;'
      }
    },
    positiveBorderStyle: [
      {
        displayName: 'Solid',
        field: 'solid',
        css: {
          selector: '.part-btn-positive',
          properties: 'border-style: solid;'
        }
      },
      {
        displayName: 'Dashed',
        field: 'dashed',
        css: {
          selector: '.part-btn-positive',
          properties: 'border-style: dashed;'
        }
      },
      {
        displayName: 'Dotted',
        field: 'dotted',
        css: {
          selector: '.part-btn-positive',
          properties: 'border-style: dotted;'
        }
      },
      {
        displayName: 'None',
        field: 'none',
        css: {
          selector: '.part-btn-positive',
          properties: 'border-style: none;'
        }
      }
    ],
    negativeBgColor: (value = '#f6f6f6') => ({
      css: {
        selector: '.part-btn-negative',
        property: 'background-color',
        properties: `background-color: ${value};`
      }
    }),
    negativeTxtColor: (value = '#141414') => ({
      css: {
        selector: '.part-btn-negative',
        property: 'color',
        properties: `color: ${value};`
      }
    }),
    negativeBorderColor: (value = '#f6f6f6') => ({
      css: {
        selector: '.part-btn-negative',
        property: 'border-color',
        properties: `border-color: ${value};`
      }
    }),
    negativeBorderWidth: (value = '0px') => ({
      css: {
        selector: '.part-btn-negative',
        property: 'border-width',
        properties: `border-width: ${value};`
      }
    }),
    negativeButtonHover: {
      css: {
        selector: '.part-btn-negative:hover',
        property: 'opacity',
        properties: 'opacity: 0.8;'
      }
    },
    negativeButtonActive: {
      css: {
        selector: '.part-btn-negative:active',
        property: 'opacity',
        properties: 'opacity: 0.8;'
      }
    },
    negativeBorderStyle: [
      {
        displayName: 'Solid',
        field: 'solid',
        css: {
          selector: '.part-btn-negative',
          properties: 'border-style: solid;'
        }
      },
      {
        displayName: 'Dashed',
        field: 'dashed',
        css: {
          selector: '.part-btn-negative',
          properties: 'border-style: dashed;'
        }
      },
      {
        displayName: 'Dotted',
        field: 'dotted',
        css: {
          selector: '.part-btn-negative',
          properties: 'border-style: dotted;'
        }
      },
      {
        displayName: 'None',
        field: 'none',
        css: {
          selector: '.part-btn-negative',
          properties: 'border-style: none;'
        }
      }
    ],
    neutralBgColor: (value = '#e7e7e7') => ({
      css: {
        selector: '.part-btn-neutral',
        property: 'background-color',
        properties: `background-color: ${value};`
      }
    }),
    neutralTxtColor: (value = '#444444') => ({
      css: {
        selector: '.part-btn-neutral',
        property: 'color',
        properties: `color: ${value};`
      }
    }),
    neutralBorderColor: (value = '#e7e7e7') => ({
      css: {
        selector: '.part-btn-neutral',
        property: 'border-color',
        properties: `border-color: ${value};`
      }
    }),
    neutralBorderWidth: (value = '0px') => ({
      css: {
        selector: '.part-btn-neutral',
        property: 'border-width',
        properties: `border-width: ${value};`
      }
    }),
    neutralButtonHover: {
      css: {
        selector: '.part-btn-neutral:hover',
        property: 'opacity',
        properties: 'opacity: 0.8;'
      }
    },
    neutralButtonActive: {
      css: {
        selector: '.part-btn-neutral:active',
        property: 'opacity',
        properties: 'opacity: 0.8;'
      }
    },
    neutralBorderStyle: [
      {
        displayName: 'Solid',
        field: 'solid',
        css: {
          selector: '.part-btn-neutral',
          properties: 'border-style: solid;'
        }
      },
      {
        displayName: 'Dashed',
        field: 'dashed',
        css: {
          selector: '.part-btn-neutral',
          properties: 'border-style: dashed;'
        }
      },
      {
        displayName: 'Dotted',
        field: 'dotted',
        css: {
          selector: '.part-btn-neutral',
          properties: 'border-style: dotted;'
        }
      },
      {
        displayName: 'None',
        field: 'none',
        css: {
          selector: '.part-btn-neutral',
          properties: 'border-style: none;'
        }
      }
    ]
  },

  // Background colors
  bgColors: {
    bgColorOne: (value = '#000') => ({
      css: {
        selector: '.part-accent-1',
        property: 'background-color',
        properties: `background-color: ${value};`
      }
    }),
    bgColorOneTxtColor: (value = '#fff') => ({
      css: {
        selector: '.part-accent-1',
        property: 'color',
        properties: `color: ${value};`
      }
    }),
    bgColorTwo: (value = '#fff') => ({
      css: {
        selector: '.part-accent-2',
        property: 'background-color',
        properties: `background-color: ${value};`
      }
    }),
    bgColorTwoTxtColor: (value = '#000') => ({
      css: {
        selector: '.part-accent-2',
        property: 'color',
        properties: `color: ${value};`
      }
    }),
    bgColorThree: (value = '#ccc') => ({
      css: {
        selector: '.part-accent-3',
        property: 'background-color',
        properties: `background-color: ${value};`
      }
    }),
    bgColorThreeTxtColor: (value = '#fff') => ({
      css: {
        selector: '.part-accent-3',
        property: 'color',
        properties: `color: ${value};`
      }
    }),
    bgColorFour: (value = '#fff') => ({
      css: {
        selector: '.part-accent-4',
        property: 'background-color',
        properties: `background-color: ${value};`
      }
    }),
    bgColorFourTxtColor: (value = '#000') => ({
      css: {
        selector: '.part-accent-4',
        property: 'color',
        properties: `color: ${value};`
      }
    })
  },

  // Text colors
  textColors: {
    textColorOne: (value = '#444') => ({
      css: {
        selector: '.part-accent-1-txt',
        property: 'color',
        properties: `color: ${value};`
      }
    }),
    textColorTwo: (value = '#444') => ({
      css: {
        selector: '.part-accent-2-txt',
        property: 'color',
        properties: `color: ${value};`
      }
    }),
    textColorThree: (value = '#444') => ({
      css: {
        selector: '.part-accent-3-txt',
        property: 'color',
        properties: `color: ${value};`
      }
    })
  },

  // Borders and dividers
  borders: {
    borderColorOne: (value = '') => ({
      css: {
        selector: '.part-border-1',
        property: 'border-color',
        properties: `border-color: ${value};`
      }
    }),
    borderWidthOne: (value = '0px') => ({
      css: {
        selector: '.part-border-1',
        property: 'border-width',
        properties: `border-width: ${value};`
      }
    }),
    borderStyleOne: [
      {
        displayName: 'Solid',
        field: 'solid',
        css: {
          selector: '.part-border-1',
          properties: 'border-style: solid;'
        }
      },
      {
        displayName: 'Dashed',
        field: 'dashed',
        css: {
          selector: '.part-border-1',
          properties: 'border-style: dashed;'
        }
      },
      {
        displayName: 'Dotted',
        field: 'dotted',
        css: {
          selector: '.part-border-1',
          properties: 'border-style: dotted;'
        }
      },
      {
        displayName: 'None',
        field: 'none',
        css: {
          selector: '.part-border-1',
          properties: 'border-style: none;'
        }
      }
    ],
    borderColorTwo: (value = '') => ({
      css: {
        selector: '.part-border-2',
        property: 'border-color',
        properties: `border-color: ${value};`
      }
    }),
    borderWidthTwo: (value = '0px') => ({
      css: {
        selector: '.part-border-2',
        property: 'border-width',
        properties: `border-width: ${value};`
      }
    }),
    borderStyleTwo: [
      {
        displayName: 'Solid',
        field: 'solid',
        css: {
          selector: '.part-border-2',
          properties: 'border-style: solid;'
        }
      },
      {
        displayName: 'Dashed',
        field: 'dashed',
        css: {
          selector: '.part-border-2',
          properties: 'border-style: dashed;'
        }
      },
      {
        displayName: 'Dotted',
        field: 'dotted',
        css: {
          selector: '.part-border-2',
          properties: 'border-style: dotted;'
        }
      },
      {
        displayName: 'None',
        field: 'none',
        css: {
          selector: '.part-border-2',
          properties: 'border-style: none;'
        }
      }
    ],
    borderColorThree: (value = '') => ({
      css: {
        selector: '.part-border-3',
        property: 'border-color',
        properties: `border-color: ${value};`
      }
    }),
    borderWidthThree: (value = '0px') => ({
      css: {
        selector: '.part-border-3',
        property: 'border-width',
        properties: `border-width: ${value};`
      }
    }),
    borderStyleThree: [
      {
        displayName: 'Solid',
        field: 'solid',
        css: {
          selector: '.part-border-3',
          properties: 'border-style: solid;'
        }
      },
      {
        displayName: 'Dashed',
        field: 'dashed',
        css: {
          selector: '.part-border-3',
          properties: 'border-style: dashed;'
        }
      },
      {
        displayName: 'Dotted',
        field: 'dotted',
        css: {
          selector: '.part-border-3',
          properties: 'border-style: dotted;'
        }
      },
      {
        displayName: 'None',
        field: 'none',
        css: {
          selector: '.part-border-3',
          properties: 'border-style: none;'
        }
      }
    ],
    borderColorFour: (value = '') => ({
      css: {
        selector: '.part-border-4',
        property: 'border-color',
        properties: `border-color: ${value};`
      }
    }),
    borderWidthFour: (value = '0px') => ({
      css: {
        selector: '.part-border-4',
        property: 'border-width',
        properties: `border-width: ${value};`
      }
    }),
    borderStyleFour: [
      {
        displayName: 'Solid',
        field: 'solid',
        css: {
          selector: '.part-border-4',
          properties: 'border-style: solid;'
        }
      },
      {
        displayName: 'Dashed',
        field: 'dashed',
        css: {
          selector: '.part-border-4',
          properties: 'border-style: dashed;'
        }
      },
      {
        displayName: 'Dotted',
        field: 'dotted',
        css: {
          selector: '.part-border-4',
          properties: 'border-style: dotted;'
        }
      },
      {
        displayName: 'None',
        field: 'none',
        css: {
          selector: '.part-border-4',
          properties: 'border-style: none;'
        }
      }
    ],
    footerBorderColor: (value = '#cccccc') => ({
      css: {
        selector: '.part-footer-divider',
        property: 'border-color',
        properties: `border-color: ${value};`
      }
    }),
    footerBorderWidth: (value = '1px') => ({
      css: {
        selector: '.part-footer-divider',
        property: 'border-width',
        properties: `border-width: ${value};`
      }
    }),
    footerBorderStyle: [
      {
        displayName: 'Solid',
        field: 'solid',
        css: {
          selector: '.part-footer-divider',
          properties: 'border-style: solid;'
        }
      },
      {
        displayName: 'Dashed',
        field: 'dashed',
        css: {
          selector: '.part-footer-divider',
          properties: 'border-style: dashed;'
        }
      },
      {
        displayName: 'Dotted',
        field: 'dotted',
        css: {
          selector: '.part-footer-divider',
          properties: 'border-style: dotted;'
        }
      },
      {
        displayName: 'None',
        field: 'none',
        css: {
          selector: '.part-footer-divider',
          properties: 'border-style: none;'
        }
      }
    ]
  }
};
