import React, { useState } from 'react';
import { Accordion, AccordionPanel, Box, Text, ThemeContext } from 'grommet';
import Icon from '@mdi/react';
import { mdiChevronDoubleLeft, mdiChevronDoubleRight } from '@mdi/js';
import AdunitModalSettings from './adunit-modal-settings';
import AdunitButtonSettings from './adunit-button-settings';
import AdunitAccentBGText from './adunit-accent-bg-text';
import AdunitAccentTextOnly from './adunit-accent-text-only';
import AdunitExpFontsColors from './adunit-exp-fonts-colors';
import AdUnitOfferContainerSettings from './adunit-offer-container-settings';
import AdunitBordersDividers from './adunit-borders-dividers';

const accordionTheme = {
  accordion: {
    border: {
      color: 'none'
    }
  }
};

const panelStyles = { padding: '15px 0' };

const renderPanelTitle = label => (
  <Box direction='row'>
    <Text size='medium' weight='bold'>
      {label}
    </Text>
  </Box>
);

const AdunitThemePanel = () => {
  const [accordionToggle, setAccordionToggle] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  return (
    <Box
      border='border400'
      pad='small'
      round='small'
      style={{
        maxWidth: !isCollapsed ? '270px' : '50px',
        minWidth: !isCollapsed ? '270px' : '50px'
      }}
      overflow={{ vertical: 'auto' }}
    >
      <Icon
        style={{ cursor: 'pointer' }}
        path={isCollapsed ? mdiChevronDoubleLeft : mdiChevronDoubleRight}
        size={1}
        onClick={() => setIsCollapsed(!isCollapsed)}
      />
      {!isCollapsed && (
        <ThemeContext.Extend value={accordionTheme}>
          <Accordion
            multiple={false}
            activeIndex={accordionToggle}
            onActive={i => setAccordionToggle(i)}
          >
            <AccordionPanel
              style={panelStyles}
              label={renderPanelTitle('Page Overlay Settings')}
            >
              <AdunitModalSettings />
            </AccordionPanel>
            <AccordionPanel
              style={panelStyles}
              label={renderPanelTitle('Offer Container Settings')}
            >
              <AdUnitOfferContainerSettings />
            </AccordionPanel>
            <AccordionPanel
              style={panelStyles}
              label={renderPanelTitle('Fonts & Text Colors')}
            >
              <AdunitExpFontsColors />
            </AccordionPanel>
            <AccordionPanel
              style={panelStyles}
              label={renderPanelTitle('Buttons')}
            >
              <AdunitButtonSettings />
            </AccordionPanel>
            <AccordionPanel
              style={panelStyles}
              label={renderPanelTitle('Accent Colors: BG + Text')}
            >
              <AdunitAccentBGText />
            </AccordionPanel>
            <AccordionPanel
              style={panelStyles}
              label={renderPanelTitle('Accent Colors: Text Only')}
            >
              <AdunitAccentTextOnly />
            </AccordionPanel>
            <AccordionPanel
              style={panelStyles}
              label={renderPanelTitle('Borders and Dividers')}
            >
              <AdunitBordersDividers />
            </AccordionPanel>
          </Accordion>
        </ThemeContext.Extend>
      )}
    </Box>
  );
};

export default AdunitThemePanel;
