import React, { useContext } from 'react';
import { Box, Text } from 'grommet';
import { CustomThemeContext } from './adunit-theme';
import {
  SELECT_BG_COLOR_ONE,
  SELECT_BG_COLOR_ONE_TEXT_COLOR,
  SELECT_BG_COLOR_TWO,
  SELECT_BG_COLOR_TWO_TEXT_COLOR,
  SELECT_BG_COLOR_THREE,
  SELECT_BG_COLOR_THREE_TEXT_COLOR,
  SELECT_BG_COLOR_FOUR,
  SELECT_BG_COLOR_FOUR_TEXT_COLOR,
  AD_UNIT_THEME
} from './adunit-theme-constant';
import ColorPicker from '../../../../../components/color-picker';
import { parseCSSProperties } from '../../../traffic-partner-utils';

const AdunitAccentBGText = () => {
  const { state, dispatch, canEdit } = useContext(CustomThemeContext);

  return (
    <Box gap='small'>
      <ColorPicker
        label='BG Color 1'
        color={parseCSSProperties(state.backgroundColorOne.css.properties)}
        onChange={newValue => {
          dispatch({
            type: SELECT_BG_COLOR_ONE,
            payload: AD_UNIT_THEME.bgColors.bgColorOne(newValue)
          });
        }}
        canEdit={canEdit}
      />
      <ColorPicker
        label='BG Color 1 Text'
        color={parseCSSProperties(
          state.backgroundColorOneTextColor.css.properties
        )}
        onChange={newValue => {
          dispatch({
            type: SELECT_BG_COLOR_ONE_TEXT_COLOR,
            payload: AD_UNIT_THEME.bgColors.bgColorOneTxtColor(newValue)
          });
        }}
        canEdit={canEdit}
      />
      <ColorPicker
        label='BG Color 2'
        color={parseCSSProperties(state.backgroundColorTwo.css.properties)}
        onChange={newValue => {
          dispatch({
            type: SELECT_BG_COLOR_TWO,
            payload: AD_UNIT_THEME.bgColors.bgColorTwo(newValue)
          });
        }}
        canEdit={canEdit}
      />
      <ColorPicker
        label='BG Color 2 Text'
        color={parseCSSProperties(
          state.backgroundColorTwoTextColor.css.properties
        )}
        onChange={newValue => {
          dispatch({
            type: SELECT_BG_COLOR_TWO_TEXT_COLOR,
            payload: AD_UNIT_THEME.bgColors.bgColorTwoTxtColor(newValue)
          });
        }}
        canEdit={canEdit}
      />
      <ColorPicker
        label='BG Color 3'
        color={parseCSSProperties(state.backgroundColorThree.css.properties)}
        onChange={newValue => {
          dispatch({
            type: SELECT_BG_COLOR_THREE,
            payload: AD_UNIT_THEME.bgColors.bgColorThree(newValue)
          });
        }}
        canEdit={canEdit}
      />
      <ColorPicker
        label='BG Color 3 Text'
        color={parseCSSProperties(
          state.backgroundColorThreeTextColor.css.properties
        )}
        onChange={newValue => {
          dispatch({
            type: SELECT_BG_COLOR_THREE_TEXT_COLOR,
            payload: AD_UNIT_THEME.bgColors.bgColorThreeTxtColor(newValue)
          });
        }}
        canEdit={canEdit}
      />
      <ColorPicker
        label='BG Color 4'
        color={parseCSSProperties(state.backgroundColorFour.css.properties)}
        onChange={newValue => {
          dispatch({
            type: SELECT_BG_COLOR_FOUR,
            payload: AD_UNIT_THEME.bgColors.bgColorFour(newValue)
          });
        }}
        canEdit={canEdit}
      />
      <ColorPicker
        label='BG Color 4 Text'
        color={parseCSSProperties(
          state.backgroundColorFourTextColor.css.properties
        )}
        onChange={newValue => {
          dispatch({
            type: SELECT_BG_COLOR_FOUR_TEXT_COLOR,
            payload: AD_UNIT_THEME.bgColors.bgColorFourTxtColor(newValue)
          });
        }}
        canEdit={canEdit}
      />
    </Box>
  );
};

export default AdunitAccentBGText;
