import { createAction } from '@reduxjs/toolkit';
import * as itemTypes from '../../items/item-types';
import {
  getItem as getItemInternal,
  saveItem as saveItemInternal
} from '../../items/item.actions';
import { DEFAULT_SORT_STRATEGY } from './campaign-placement.constants';

export const receiveCampaignPlacement = createAction(
  'receiveCampaignPlacement'
);
export const setCampaignPlacementIsBusy = createAction(
  'setCampaignPlacementIsBusy'
);
export const modifyCampaignPlacement = createAction('modifyCampaignPlacement');
export const closeCampaignPlacement = createAction('closeCampaignPlacement');

const sortStrategiesValidation = campaignPlacementSortGroups => {
  if (campaignPlacementSortGroups && campaignPlacementSortGroups.length > 0) {
    let errorMessage = '';

    // Validate each strategy in each sort strategy
    campaignPlacementSortGroups.forEach(sortStrategy => {
      sortStrategy.campaignPlacementSortGroupItems.forEach(strategy => {
        let errors = [];

        if (!strategy.sortStrategyName) {
          errors.push('Sort Strategy is required.');
        }

        if (!strategy.trafficDistribution) {
          errors.push('Traffic Distribution is required.');
        }

        if (!sortStrategy.isDefault) {
          if (sortStrategy.deviceOperatingSystems?.length === 0) {
            errors.push(
              'A rule is missing a device filter. All rules must have at least one device filter selected.'
            );
          }
          if (sortStrategy.implementationTypes?.length === 0) {
            errors.push(
              'A rule is missing an implementation type filter. All rules must have at least one implementation type filter selected.'
            );
          }
        }

        if (errors.length > 0) {
          errorMessage = errors.join(' ');
        }
      });

      // Validate traffic distribution for each sort strategy
      const totalDistribution =
        sortStrategy.campaignPlacementSortGroupItems.reduce(
          (total, strategy) =>
            total + parseFloat(strategy.trafficDistribution || 0),
          0
        ) * 100;

      if (totalDistribution > 100) {
        errorMessage = `Total traffic distribution cannot exceed 100%.`;
        return;
      }

      if (totalDistribution !== 100) {
        errorMessage = `Total traffic distribution must equal 100%.`;
        return;
      }
    });

    return errorMessage;
  }
};

export const CampaignPlacementModel = (id, name, params) => ({
  id,
  name,
  type: itemTypes.CAMPAIGN_PLACEMENT,
  campaignPlacementSortGroups: [DEFAULT_SORT_STRATEGY]
});

export const getItem = getItemInternal(
  CampaignPlacementModel,
  receiveCampaignPlacement,
  setCampaignPlacementIsBusy
);
export const saveItem = saveItemInternal(modifyCampaignPlacement, item => {
  return sortStrategiesValidation(item.campaignPlacementSortGroups);
});
