import React, {useEffect, useRef, memo } from 'react'
import { authorizedFetch } from '../utils.js';
import * as itemTypes from '../items/item-types';

const HtmlPreview = memo(({ html, css, preventInteraction }) => {
  const iframeRef = useRef();
  useEffect(() => {
    iframeRef.current.contentDocument.body.innerHTML =
      html + `<style type="text/css">${css}</style>`;

    //if there is no resolved output, and there is html defined, render the html
    if (
      iframeRef.current.contentDocument.body.innerText === '' &&
      iframeRef.current.contentDocument.body.innerHTML.indexOf('img') === -1
    ) {
      if (html) {
        //look for first content block, if found, render that
        const contentBlocks = iframeRef.current.contentDocument.body.querySelectorAll(
          'content-block'
        );

        let foundContentBlock;
        for (let i = 0; i < contentBlocks.length; i++) {
          if (contentBlocks[i].hasAttribute('block-id')) {
            const blockId = contentBlocks[i].getAttribute('block-id');
            if (blockId.length > 0) {
              foundContentBlock = true;
              authorizedFetch(`/api/${itemTypes.CONTENT_BLOCK}/${blockId}`)
                .then(response => {
                  iframeRef.current.contentDocument.body.innerHTML =
                    response.contentBlockDefinition.html +
                    `<style type="text/css">${response.contentBlockDefinition.css}</style>`;
                })
                .catch(err => {
                  console.log(err);
                });

              break;
            }
          }
        }

        if (!foundContentBlock) {
          const code = document.createElement('pre');
          code.innerText = html;
          code.style = 'white-space: pre-wrap';

          iframeRef.current.contentDocument.body.innerHTML = '';
          iframeRef.current.contentDocument.body.appendChild(code);
        }
      } else {
        iframeRef.current.contentDocument.body.innerHTML =
          '<div style="height: 100%; display: flex; align-items: center; justify-content: center"><p>No Preview Available</p></div>';
      }
    }
  });

  return (
    <section style={{ display: 'flex', position: 'relative', height: '100%' }}>
      <iframe
        style={{
          borderRadius: '5px',
          borderWidth: 0,
          width: '100%',
          height: '100%'
        }}
        title='preview pane'
        scrolling={preventInteraction ? 'no' : 'auto'}
        ref={iframeRef}
      ></iframe>
      {preventInteraction && (
        <section
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            zIndex: 1
          }}
        ></section>
      )}
    </section>
  );
});

export default HtmlPreview