import React from 'react';
import { Box, Text } from 'grommet';

const RowItem = ({ label, value, title, truncate = true }) => (
  <Box data-testid={label} direction='row' gap='xsmall'>
    <Box width='110px'>
      <Text>{label}:</Text>
    </Box>
    <Box
      fill='horizontal'
      title={title || value?.toString()}
      style={{ maxHeight: '100px', overflow: 'hidden' }}
    >
      <Text data-testid={`${label} Info`} truncate={truncate}>
        {value}
      </Text>
    </Box>
  </Box>
);

export default RowItem;
