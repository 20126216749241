import React from 'react';
import { Box, Button } from 'grommet';
import { FormClose } from 'grommet-icons';
import { FTTextInput } from './inputs';

function KeyValueInputs({
  canEdit,
  keyPair,
  onKeyPairChange,
  removeKeyPair,
  keyPlaceholder = 'Key',
  valuePlaceholder = 'Value',
  separator = ':',
  hideFirstRemoveButton = false
}) {
  return (
    <Box gap='xsmall' direction='row'>
      <FTTextInput
        size='medium'
        name='key'
        disabled={!canEdit}
        value={keyPair.key}
        placeholder={keyPlaceholder}
        onChange={value => onKeyPairChange({ key: value })}
      />
      <Box
        alignSelf='center'
        align='center'
        style={{
          fontSize: 'small'
        }}
      >
        {separator}
      </Box>
      <FTTextInput
        size='medium'
        name='value'
        disabled={!canEdit}
        value={keyPair.value}
        placeholder={valuePlaceholder}
        onChange={value => onKeyPairChange({ value: value })}
      />
      {!hideFirstRemoveButton && (
        <Button
          plain
          disabled={!canEdit}
          icon={<FormClose />}
          onClick={() => removeKeyPair(keyPair)}
        />
      )}
    </Box>
  );
}

export default KeyValueInputs;
