import React from 'react';
import { Box, Text } from 'grommet';
import { NavLink, useLocation } from 'react-router-dom';

import { generateNewId } from '../utils';
import styles from './nav-item.module.scss';
import { tabNavItems } from './tab-navigation';

const pathBuilder = path => {
  return path.indexOf(':id') !== -1
    ? path.substring(0, path.indexOf(':id')) + generateNewId()
    : path;
};

const isChildPathActive = ({
  location,
  parentLabel,
  mappedItemType,
  itemType
}) => {
  const { pathname } = location;

  // We want to get the root itemType from the url
  const pathAsArray = pathname.split('/'); // ['/', 'datacampaign', '....']
  // Remove leading slash
  pathAsArray.shift(); // -> ['datacampaign', '....']

  const itemTypeFromUrl = pathAsArray[0];

  // If we aren't check if a sub-nav item is active
  // This is for checking if the parent nav is active based on the sub-nav selection
  if (!mappedItemType) {
    // Find the parent nav object based on the nav items itemType
    const target = tabNavItems.filter(
      navItem => navItem.itemType === itemType
    )[0];

    // Exit early if there are no sub-nav items to check
    if (!target.childTabs) {
      return false;
    }

    // Check if itemTypeFromUrl is contained in the parent-nav's sub-nav items
    const targetIndex = target?.childTabs.findIndex(
      childTab => childTab.itemType === itemTypeFromUrl
    );

    return targetIndex !== -1;
  }

  // the mappedItemType is important so not every sub-nav item is marked as active
  if (mappedItemType === itemTypeFromUrl) return true;
  return false;
};

const NavItem = ({
  item,
  isCollapsed,
  isActiveTab,
  childTabs = null,
  itemType
}) => {
  const location = useLocation();
  return (
    <Box
      height={{
        min: '60px'
      }}
      style={{
        cursor: 'pointer'
      }}
      title={item.label}
      data-testid='nav-item'
      justify='center'
      align='start'
      className={`${styles.navItemContainer} ${styles.target}`}
    >
      {item.path ? (
        <NavLink
          data-testid={`${item.label} Link`}
          to={pathBuilder(item.path)}
          exact='true'
          className={({ isActive }) =>
            isActive || isChildPathActive({ location, itemType })
              ? `${styles.tabNavLink} ${styles.direct} ${styles.active}`
              : `${styles.tabNavLink} ${styles.direct}`
          }
        >
          <Box
            gap={!isCollapsed ? 'small' : '0'}
            margin={{ left: 'medium' }}
            direction='row'
            align='center'
            justify='center'
          >
            {item.icon}
            {!isCollapsed && <Text>{item.label}</Text>}
          </Box>
        </NavLink>
      ) : (
        <Box
          data-testid={`${item.label} Link`}
          className={`${styles.tabNavLink} ${isChildPathActive({
            location,
            itemType
          }) && styles.active}`}
        >
          <Box
            gap={!isCollapsed ? 'small' : '0'}
            margin={{ left: 'medium' }}
            direction='row'
            align='center'
            justify='center'
          >
            {item.icon}
            {!isCollapsed && <Text>{item.label}</Text>}
          </Box>
        </Box>
      )}
      <Box
        className={`${styles.popover} ${isCollapsed &&
          styles.collapsed} ${item.label === 'Campaigns' && styles.campaigns}`}
      >
        {childTabs &&
          childTabs.map(({ navItem, itemType }) => {
            return (
              <NavLink
                key={navItem.label}
                data-testid={`${navItem.label} Link`}
                to={pathBuilder(navItem.path)}
                className={({ isActive }) =>
                  isActive ||
                  isChildPathActive({
                    location,
                    parentLabel: item.label,
                    mappedItemType: itemType
                  })
                    ? `${styles.childNavLink} ${styles.active}`
                    : `${styles.childNavLink}`
                }
              >
                <Box gap='small' pad='small' direction='row' align='center'>
                  {React.cloneElement(navItem.icon, {
                    style: {
                      stroke: 'inherit'
                    }
                  })}
                  <Text>{navItem.label}</Text>
                </Box>
              </NavLink>
            );
          })}
      </Box>
    </Box>
  );
};

export default NavItem;
