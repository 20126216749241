import { debounce } from 'lodash';
import { authorizedFetch } from './utils';
import * as itemTypes from './items/item-types';

const PERMISSION_EXCEPTIONS = ['log'];

let keycloak;
let refreshTokenTTL = 0;
let isUpdatingToken = false;
let permissions = [];

export const setRefreshTokenTTL = () => {
  refreshTokenTTL =
    keycloak.refreshTokenParsed['exp'] - Math.ceil(new Date().getTime() / 1000);
  return refreshTokenTTL;
};

export const shouldRefreshToken = () => {
  return (
    refreshTokenTTL -
      (keycloak.refreshTokenParsed['exp'] -
        Math.ceil(new Date().getTime() / 1000) +
        keycloak.timeSkew) >
      5 * 60 && !isUpdatingToken
  );
};

export const refreshKeycloakToken = () => {
  if (shouldRefreshToken()) {
    isUpdatingToken = true;
    keycloak
      .updateToken()
      .success(refreshed => {
        isUpdatingToken = false;
        if (refreshed) setRefreshTokenTTL();
      })
      .error(() => {
        isUpdatingToken = false;
        keycloak.logout();
      });
  }
};

export const debouncedRefreshToken = debounce(refreshKeycloakToken, 250);

const keycloakOptions = { onLoad: 'login-required' };

if (
  process.env.NODE_ENV !== 'production' ||
  process.env.REACT_APP_ENVIRONMENT === 'Development'
) {
  keycloakOptions.checkLoginIframe = false;
}

export const authenticate = new Promise((resolve, reject) => {
  keycloak = window.Keycloak();
  keycloak
    .init(keycloakOptions)
    .success(authenticated => {
      setRefreshTokenTTL();
      resolve(authenticated);
    })
    .error(() => {
      reject();
    });
});

export const getKeycloak = () => keycloak;

export const getPermissions = () => {
  return authorizedFetch('/api/permissions')
    .then(response => {
      permissions = response;
    })
    .catch(e => {
      console.log(e);
    });
};

const itemTypesResources = {
  [itemTypes.SURVEY]: 'Survey',
  [itemTypes.PAGE]: 'Page',
  [itemTypes.CONTENT_BLOCK]: 'ContentBlock',
  [itemTypes.FLOW]: 'Flow',
  [itemTypes.USER_ATTRIBUTE]: 'UserAttribute',
  [itemTypes.REWARD]: 'Reward',
  [itemTypes.ADVERTISER]: 'Advertiser',
  [itemTypes.WEB_API_INTEGRATION]: 'WebApiIntegration',
  [itemTypes.CAMPAIGN]: 'Campaign',
  [itemTypes.AUDIENCE]: 'Audience',
  [itemTypes.AUDIENCE_PRIORITY]: 'AudienceFilteredPriority',
  [itemTypes.AD_CREATIVE]: 'AdCreative',
  [itemTypes.AD_GROUP]: 'AdGroup',
  [itemTypes.REPORT]: 'Report',
  [itemTypes.JOBS_BROWSER]: 'JobsBrowser',
  [itemTypes.DATA_CAMPAIGN]: 'DataCampaign',
  [itemTypes.DATA_DELIVERY]: 'DataDelivery',
  [itemTypes.DATA_DELIVERY_GROUP]: 'DataDeliveryGroup',
  [itemTypes.JOB_CAMPAIGN]: 'JobCampaign',
  [itemTypes.CALL_CAMPAIGN]: 'CallCampaign',
  [itemTypes.JOB_AD_CREATIVE]: 'JobAdCreative',
  [itemTypes.JOB_AD_GROUP]: 'JobAdGroup',
  [itemTypes.TRAFFIC_SOURCE]: 'TrafficSource',
  [itemTypes.TRAFFIC_PARTNER]: 'TrafficPartner',
  [itemTypes.MINION_LOG]: 'Log',
  [itemTypes.CAMPAIGN_PLACEMENT]: 'CampaignPlacement',
  [itemTypes.INTEGRATION]: 'WebApiIntegration',
  [itemTypes.RULE]: 'Rule',
  [itemTypes.EMBEDDED_FLOW_CONFIG]: 'EmbeddedFlowConfig',
  [itemTypes.BUSINESS_UNIT]: 'BusinessUnit',
  [itemTypes.OFFER_SYNDICATION_CONFIG]: 'OfferSyndicationConfig',
  Profile: 'CDSProfile'
};

const ACTIONS = {
  CREATE: 'Create',
  READ: 'Read',
  UPDATE: 'Update',
  DELETE: 'Delete'
};

const getResource = itemType => {
  return permissions.find(p => p.resource === itemTypesResources[itemType]);
};

export const hasPermission = (itemType, permission) => {
  const resource = getResource(itemType);
  return (
    (typeof resource !== 'undefined' &&
      resource.permissions.indexOf(permission) !== -1) ||
    PERMISSION_EXCEPTIONS.includes(itemType)
  );
};

export const canCreate = itemType => {
  return hasPermission(itemType, ACTIONS.CREATE);
};

export const canRead = itemType => {
  return hasPermission(itemType, ACTIONS.READ);
};

export const canUpdate = itemType => {
  return hasPermission(itemType, ACTIONS.UPDATE);
};
