import React, { useEffect, useState, useRef } from 'react';
import { Box, TextInput } from 'grommet';
import TagItem from './tag-item';
import { itemTypeNames } from '../items/item-types';
import { getSuggestions, getSuggestionItem } from '../items/items';
import { getItemNames } from '../utils';

const ItemTagSelector = ({
  itemType,
  itemTypes,
  itemIds = [],
  onItemAdd,
  onItemRemove,
  width = 'auto',
  disabled = false,
  filter,
  size = 'medium',
  externalSuggestions,
  canEdit = true
}) => {
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [itemNames, setItemNames] = useState([]);
  const textRef = useRef();

  useEffect(() => {
    getItemNames(
      itemIds,
      setItemNames,
      () => {
        console.log('err');
      },
      itemTypes ?? [itemType]
    );
    //we only want this to be called when mounting
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //can pass in suggestions instead of searching indices for them
    if (Array.isArray(externalSuggestions)) {
      setSuggestions(
        externalSuggestions
          .filter(
            s =>
              itemIds.indexOf(s.id) === -1 &&
              s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
          )
          .map(getSuggestionItem)
      );
    } else {
      getSuggestions(
        value,
        itemTypes ?? itemType,
        itemIds,
        setSuggestions,
        filter
      );
    }
  }, [value, itemType, itemTypes, itemIds, filter, externalSuggestions]);

  return (
    <Box
      direction='row'
      border={{ color: 'border500' }}
      round='xsmall'
      style={{ width }}
    >
      <Box
        direction='row'
        wrap={true}
        style={{ maxWidth: '65%' }}
        align='center'
        flex={{ shrink: 0 }}
      >
        {itemIds.map(id => (
          <TagItem
            size={size}
            key={id}
            tag={itemNames[id] || id}
            disabled={disabled}
            canEdit={canEdit}
            onRemoveClick={() => onItemRemove(id)}
          />
        ))}
      </Box>
      <Box flex={true}>
        <TextInput
          size={size}
          placeholder={`Add ${
            Array.isArray(itemTypes) ? 'item' : itemTypeNames[itemType]
          }`}
          plain
          disabled={disabled || !canEdit}
          ref={textRef}
          value={value}
          onChange={e => {
            setValue(e.target.value);
          }}
          suggestions={suggestions}
          onSelect={e => {
            const { id, name } = e.suggestion.value;
            onItemAdd(id);
            const newItemNames = { ...itemNames };
            newItemNames[id] = name;
            setItemNames(newItemNames);
            setValue('');
            textRef.current.focus();
          }}
        />
      </Box>
    </Box>
  );
};

ItemTagSelector.displayName = 'ItemTagSelector';

export default ItemTagSelector;
