import { authorizedFetch } from '../utils';

export const platforms = {
  PXP: 'pxp',
  CDP: 'cdp'
};

export const environments = {
  DEV: 'dev',
  UAT: 'uat',
  PROD: 'prod',
  CDP_TEST: 'cdptest',
  CDP_PROD: 'cdpprod'
};

let environmentConfig = null;

export const getConfig = () => {
  return authorizedFetch('/api/environmentConfig')
    .then(response => {
      environmentConfig = response;
    })
    .catch(e => {
      console.log(e);
    });
};

export const getEnvironmentConfig = () => environmentConfig;

//build a function that we don't need to resolve as a promise b/c its a pain to refactor everything here
export const isApplicable = ({ environment, platform }) => {
  //component should be rendered only on a certain environment
  if (environment && environment !== environmentConfig?.environment) {
    return false;
  }

  //component should be rendered only on a certain platform
  if (platform && platform !== environmentConfig?.platform) {
    return false;
  }

  return true;
};
