import React, { useState, useEffect } from 'react';
import { Box } from 'grommet';
import { authorizedFetch } from '../utils';
import { FTSelect } from './inputs';

const NO_VERTICAL = {
  id: '',
  name: 'No verticals found'
};

export const getCampaignVerticals = async () => {
  return await authorizedFetch(`/api/vertical/getAll`, 'GET').then(response => {
    return response ?? [];
  });
};

const VerticalSelector = ({
  verticalId = '',
  subVerticalId = '',
  onChange,
  disabled,
  verticalTipContent = 'Select the market vertical that best categorizes this offer. Manage Vertical rules independently within each partner’s traffic source settings.',
  subVerticalTipContent = 'After selecting a vertical, you can further categorize this offer by selecting a subvertical.',
  addError
}) => {
  const [verticals, setVerticals] = useState([]);
  const [subverticals, setSubverticals] = useState([]);
  const [filteredVerticals, setFilteredVerticals] = useState([]);
  const [filteredSubverticals, setFilteredSubverticals] = useState([]);
  const [selectedVertical, setSelectedVertical] = useState(
    verticals.find(v => v.id === verticalId)
  );

  useEffect(() => {
    getCampaignVerticals()
      .then(fetchedVerticals => {
        if (Array.isArray(fetchedVerticals) && fetchedVerticals.length > 0) {
          setVerticals([...fetchedVerticals]);
          setFilteredVerticals([...fetchedVerticals]);

          const loadedVertical = fetchedVerticals.find(
            v => v.id === verticalId
          );
          setSelectedVertical(loadedVertical);

          if (!loadedVertical && verticalId?.length > 0) {
            onChange({ verticalId: null, subVerticalId: null });
            addError(
              `A vertical with an unmatched ID was found. Please select a new vertical.`
            );
          }
        } else {
          setVerticals(
            verticalId
              ? [{ id: verticalId, name: 'No verticals found' }]
              : [NO_VERTICAL]
          );
          setFilteredVerticals(
            verticalId
              ? [{ id: verticalId, name: 'No verticals found' }]
              : [NO_VERTICAL]
          );
        }
      })
      .catch(err => {
        addError(`There was an error fetching campaign verticals`, err.message);
        return null;
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!selectedVertical) {
      setSubverticals(selectedVertical?.subVerticals ?? []);
      setFilteredSubverticals(selectedVertical?.subVerticals ?? []);
    }
  }, [selectedVertical, verticals]);

  return (
    <Box gap='small'>
      <FTSelect
        disabled={disabled}
        title={`Vertical`}
        placeholder={`No vertical selected`}
        labelKey='name'
        valueKey='id'
        options={filteredVerticals}
        value={verticals.find(u => u.id === verticalId)}
        label={'Vertical'}
        tipContent={verticalTipContent}
        onChange={({ id }) => {
          onChange({ verticalId: id, subVerticalId: null });
          setSelectedVertical(verticals.find(v => v.id === id));
        }}
        handleOpen={() => {
          setFilteredVerticals(verticals);
        }}
        handleSearch={search => {
          const lowercaseSearch = search.toLowerCase();
          if (search === '') {
            setFilteredVerticals(verticals);
          } else {
            setFilteredVerticals(
              verticals.filter(v => {
                const lowercaseLabel = v.name.toLowerCase();
                return lowercaseLabel.includes(lowercaseSearch);
              })
            );
          }
        }}
      />
      <FTSelect
        disabled={!selectedVertical}
        title={`Subvertical`}
        placeholder={
          !!selectedVertical
            ? `No subvertical selected`
            : 'Select a vertical first'
        }
        labelKey='name'
        valueKey='id'
        options={filteredSubverticals}
        value={subverticals.find(u => u.id === subVerticalId)}
        label={'Subvertical'}
        tipContent={subVerticalTipContent}
        onChange={({ id }) => onChange({ subVerticalId: id })}
        handleOpen={() => {
          setFilteredSubverticals(subverticals);
        }}
        handleSearch={search => {
          const lowercaseSearch = search.toLowerCase();
          if (search === '') {
            setFilteredSubverticals(subverticals);
          } else {
            setFilteredSubverticals(
              subverticals.filter(sv => {
                const lowercaseLabel = sv.name.toLowerCase();
                return lowercaseLabel.includes(lowercaseSearch);
              })
            );
          }
        }}
      />
    </Box>
  );
};

export default VerticalSelector;
