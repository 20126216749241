import React, { useState } from 'react';
import { Box, Button, TextInput } from 'grommet';
import { ChromePicker } from 'react-color';
import { LabeledInput } from './inputs';

const ColorPicker = ({
  color,
  useOpacity = false,
  onChange,
  label,
  delegated,
  canEdit
}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleColorChange = newColor => {
    if (useOpacity) {
      const rgbaValue = `rgba(${newColor.rgb.r}, ${newColor.rgb.g}, ${newColor.rgb.b}, ${newColor.rgb.a})`;
      onChange(rgbaValue);
    } else {
      onChange(newColor.hex);
    }
  };

  const handleColorClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  return (
    <LabeledInput label={label} {...delegated}>
      <Box direction='row' gap='xxsmall' justify='center' align='center'>
        <TextInput
          size='medium'
          value={color}
          onChange={e => onChange(e.target.value)}
          disabled={!canEdit}
          name={label?.toLowerCase()}
          title={label}
        />
        <Box border={{ color: 'border400' }} round='xsmall' width='25%'>
          <Button
            disabled={!canEdit}
            style={{
              height: '38px',
              background: color,
              cursor: 'pointer'
            }}
            onClick={handleColorClick}
          />
        </Box>
      </Box>
      {displayColorPicker && (
        <Box style={{ position: 'relative', zIndex: '2' }}>
          <Box
            style={{
              position: 'absolute',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px'
            }}
            onClick={handleColorClick}
          />
          <ChromePicker color={color} onChange={handleColorChange} />
        </Box>
      )}
    </LabeledInput>
  );
};

export default ColorPicker;
