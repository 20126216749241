import React, { useState, useEffect } from 'react';
import { authorizedFetch } from '../utils';
import { FTSelect } from './inputs';

const NO_USER = {
  id: '',
  fullName: 'No users found'
};

const ERR_USER = {
  id: '',
  fullName: 'There was an error fetching users'
};

const UserSelector = ({
  itemUserName,
  itemUserId = '',
  onChange,
  userRole = '',
  label,
  addError
}) => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  const assignedUser =
    itemUserName && itemUserId
      ? { fullName: itemUserName, id: itemUserId }
      : undefined;

  useEffect(() => {
    const getUsers = async () => {
      const fetchedUsers = await authorizedFetch(
        `/api/lookup/users/${userRole}`,
        'GET'
      ).catch(err => {
        addError(`There was an error fetching ${userRole} users`, err.message);
        return assignedUser ? [assignedUser] : [ERR_USER];
      });

      if (Array.isArray(fetchedUsers) && fetchedUsers.length > 0) {
        setUsers(fetchedUsers);
        setFilteredUsers(fetchedUsers);
      } else {
        setUsers(assignedUser ? [assignedUser] : [NO_USER]);
        setFilteredUsers(assignedUser ? [assignedUser] : [NO_USER]);
      }
    };
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FTSelect
      title={`Select ${label}`}
      placeholder={`Select ${label}`}
      labelKey={option =>
        option['fullName'].replace(/\s/g, '') // fullName has characters other than spaces
          ? `${option['fullName']} ${
              option['username'] ? `(${option['username']})` : ''
            }`
          : `(${option['username']})`
      }
      valueKey='id'
      options={filteredUsers}
      value={users.find(u => u.id === itemUserId)}
      label={label}
      onChange={onChange}
      handleOpen={() => {
        setFilteredUsers(users);
      }}
      handleSearch={search => {
        const lowercaseSearch = search.toLowerCase();
        if (search === '') {
          setFilteredUsers(users);
        } else {
          setFilteredUsers(
            users.filter(u => {
              const fullNameAndUsername = u.fullName + u.username;
              const lowercaseName = fullNameAndUsername.toLowerCase();
              return lowercaseName.includes(lowercaseSearch);
            })
          );
        }
      }}
    />
  );
};

export default UserSelector;
