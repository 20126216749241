import { Box, Text } from 'grommet';
import React, { useEffect, useState } from 'react';
import { FTSelect } from '../../../../components/inputs';

const CustomAdUnitTemplateSelector = ({
  customAdUnitTemplates,
  setSelectedTemplate,
  currentTemplate
}) => {
  const [templateId, setTemplateId] = useState(null);
  const [filteredTemplateList, setFilteredTemplateList] = useState([]);

  useEffect(() => {
    if (customAdUnitTemplates.length > 0) {
      setFilteredTemplateList(customAdUnitTemplates);
    }
  }, [customAdUnitTemplates]);

  return (
    <>
      <Box
        margin={{
          vertical: 'xsmall'
        }}
      >
        <Text size='xlarge' fill='horizontal'>
          <strong>ADMIN ONLY</strong>: Use a Partner Specific Testing Template
        </Text>
        <Text fill='horizontal'>
          These templates are intended for one-off partner specific use cases.
          The template have not been tested and approved for global use across
          our partner network.
        </Text>
      </Box>
      <Box width={{ min: '400px', max: '400px' }}>
        <FTSelect
          disabled={customAdUnitTemplates.length === 0}
          title={`Select a template`}
          placeholder={`Select a template`}
          labelKey='templateName'
          valueKey='id'
          options={filteredTemplateList}
          value={customAdUnitTemplates.find(
            p => p.id === currentTemplate || p.id === templateId
          )}
          onChange={({ id }) => {
            setTemplateId(id);
            setSelectedTemplate(id);
          }}
          handleSearch={search => {
            const lowercaseSearch = search.toLowerCase();
            if (search === '') {
              setFilteredTemplateList(customAdUnitTemplates);
            } else {
              setFilteredTemplateList(
                customAdUnitTemplates.filter(template => {
                  const templateName = template.templateName.toLowerCase();
                  return templateName.includes(lowercaseSearch);
                })
              );
            }
          }}
          onClose={() => {
            setFilteredTemplateList(customAdUnitTemplates);
          }}
        />
      </Box>
    </>
  );
};

export default CustomAdUnitTemplateSelector;
