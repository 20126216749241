import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer
} from 'react';

const ColumnDraggingConext = createContext({
  draggingEnabled: false,
  onResizeStart: () => {},
  widthMap: {},
  isResizing: false
});

export const useColumnDragging = () => {
  const ctx = useContext(ColumnDraggingConext);
  return ctx;
};

const initialState = {
  isResizing: false,
  startX: null,
  startWidth: null,
  columnIndex: null,
  widthMap: {}
};

const actions = {
  DRAG_START: 0,
  DRAG_END: 1
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.DRAG_START: {
      return {
        ...state,
        isResizing: true,
        columnIndex: action.payload.index,
        startX: action.payload.start,
        startWidth: action.payload.width
      };
    }
    case actions.DRAG_END: {
      const newWidthMap = { ...state.widthMap };
      newWidthMap[state.columnIndex] = action.payload;

      return {
        ...initialState,
        widthMap: newWidthMap
      };
    }
    default:
      return state;
  }
};

export const DraggingProvider = ({ children, enabled }) => {
  const [{ isResizing, startX, widthMap, startWidth }, dispatch] = useReducer(
    reducer,
    initialState
  );

  const handleResizeEnd = useCallback(
    e => {
      if (isResizing) {
        const endX = e.pageX;
        const deltaX = endX - startX;
        const width = startWidth + deltaX;

        dispatch({ type: actions.DRAG_END, payload: width });
      }
    },
    [isResizing, startWidth, startX]
  );

  const handleResizeStart = useCallback((e, index, currentWidth) => {
    dispatch({
      type: actions.DRAG_START,
      payload: { index, start: e.pageX, width: currentWidth }
    });
  }, []);

  useEffect(() => {
    document.addEventListener('mouseup', handleResizeEnd);
    return () => document.removeEventListener('mouseup', handleResizeEnd);
  }, [handleResizeEnd]);

  const value = useMemo(
    () => ({
      onResizeStart: handleResizeStart,
      widthMap,
      isResizing,
      draggingEnabled: enabled
    }),
    [handleResizeStart, isResizing, widthMap, enabled]
  );

  return (
    <ColumnDraggingConext.Provider value={value}>
      {children}
    </ColumnDraggingConext.Provider>
  );
};
