import React from 'react';
import { Box, Button, Layer, Text } from 'grommet';
import { FormClose } from 'grommet-icons';
import Spinner from '../components/spinner';

const Modal = ({
  width = 'large',
  header,
  onClose,
  onCancel,
  onSave,
  canSave,
  saveLabel,
  cancelLabel,
  children,
  isSaving,
  className,
  height = 'auto',
  cancelButtonIsPrimary
}) => {
  return (
    <Layer modal={true}>
      <Box
        width={width}
        height={height}
        round='small'
        justify='between'
        className={className}
        data-testid='modal'
      >
        <Box>
          <Box
            direction='row'
            justify='between'
            align='center'
            border={{ side: 'bottom', color: 'border500' }}
            pad='medium'
            round={{ corner: 'top', size: 'xsmall' }}
          >
            <Text>{header}</Text>
            {onClose && <Button plain icon={<FormClose />} onClick={onClose} />}
          </Box>
          {children}
        </Box>
        <Box
          pad='medium'
          align='center'
          justify='center'
          direction={onCancel ? 'row' : 'column'}
          gap='small'
        >
          {onCancel && (
            <Button
              label={cancelLabel}
              onClick={onCancel}
              primary={cancelButtonIsPrimary}
            ></Button>
          )}
          {isSaving ? (
            <Button primary label={'Saving...'} icon={<Spinner />}></Button>
          ) : (
            <Button
              disabled={!canSave}
              label={saveLabel}
              onClick={onSave}
              primary
            ></Button>
          )}
        </Box>
      </Box>
    </Layer>
  );
};

Modal.displayName = 'Modal';
export default Modal;
