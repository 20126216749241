import itemReducer from '../../../items/item.reducers';
import {
  closeAdCreative,
  modifyAdCreative,
  receiveAdCreative,
  setAdCreativeIsBusy
} from './ad-creative.actions';

const adCreativeReducer = itemReducer(
  receiveAdCreative,
  setAdCreativeIsBusy,
  modifyAdCreative,
  closeAdCreative
);

export default adCreativeReducer;
