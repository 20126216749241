import { createAction } from '@reduxjs/toolkit';
import { v4 } from 'uuid';

import * as itemTypes from '../../items/item-types';
import {
  getItem as getItemInternal,
  saveItem as saveItemInternal
} from '../../items/item.actions';

export const receiveTrafficSource = createAction('receiveTrafficSource');
export const setTrafficSourceIsBusy = createAction('setTrafficSourceIsBusy');
export const modifyTrafficSource = createAction('modifyTrafficSource');
export const closeTrafficSource = createAction('closeTrafficSource');

export const trafficSourceModel = (id, name, params) => ({
  id,
  name,
  type: itemTypes.TRAFFIC_SOURCE
});

export function stagedCostFactory() {
  return { date: '', cost: '', clicks: '', id: v4() };
}

export const getItem = getItemInternal(
  trafficSourceModel,
  receiveTrafficSource,
  setTrafficSourceIsBusy,
  item => {
    item.stagedCosts = [stagedCostFactory()];
  }
);
export const saveItem = saveItemInternal(
  modifyTrafficSource,
  item => {
    if (!item.trafficPartnerName) {
      return 'Partner is required.';
    }
    if (!item.verticalId) {
      return 'Vertical is required.';
    }
    if (!item.subVerticalId) {
      return 'Subvertical is required.';
    }
  },
  item => {
    // This data doesn't really belong on the traffic source entity, but the UI presents it that way so we save it in the redux store to allow users to save drafts of cost data
    delete item.stagedCosts;
  }
);
