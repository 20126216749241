import React from 'react';
import { Box, Text } from 'grommet';
import RowItem from '../row-item';
import { cleanDate } from '../../utils.js';

const RewardDetail = ({ item }) => {
  const {
    name,
    description,
    categories,
    dateModified,
    lastModifiedBy,
    version,
    code,
    displayName,
    value,
    requiredOffers,
    claimLockoutPeriod
  } = item;
  return (
    <>
      <Box flex={false}>
        <RowItem label='Name' value={name} />
        <RowItem label='Desc' value={description} truncate={false} />
        <RowItem
          label='Tags'
          value={Array.isArray(categories) && categories.join(', ')}
          truncate={false}
        />
        <RowItem
          label='Modified'
          value={dateModified && cleanDate(dateModified)}
        />
        <RowItem label='By' value={lastModifiedBy} />
        <RowItem label='Version' value={version} />
      </Box>

      <Box margin={{ top: 'large' }} overflow='auto'>
        <Box>
          <Text title={code} truncate>
            Code: {code}
          </Text>
          <Text title={displayName} truncate>
            Display: {displayName}
          </Text>
          <Text title='Value of the reward' truncate>
            Value: {value}
          </Text>
          <Text title='Number of offers required to redeem the reward' truncate>
            Required Offers: {requiredOffers}
          </Text>
          <Text
            title='Number of months before another reward can be claimed'
            truncate
          >
            Lockout Period: {claimLockoutPeriod}
          </Text>
        </Box>
      </Box>
    </>
  );
};

export default RewardDetail;
