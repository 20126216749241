import React, { useState, useEffect } from 'react';
import { Box, Text, TextInput, Tip } from 'grommet';
import { FTSelect } from '../../components/inputs';

import {
  StatusGood,
  StatusWarning,
  StatusInfo,
  CircleQuestion
} from 'grommet-icons';
import MessageBox from '../../components/messageBox.jsx';
import { useLocation } from 'react-router-dom';
import ConversionsStrategy from './conversions-strategy';
import RevenueStrategy from './revenue-strategy';

const CAP_STRATEGY = [
  {
    name: 'None',
    type: undefined
  },
  {
    name: 'Conversions',
    type: 'conversion'
  },
  {
    name: 'Revenue',
    type: 'revenue'
  }
];

const capStrategySelect = (onChange, capStrategy) => {
  return (
    <Box>
      <FTSelect
        value={CAP_STRATEGY.find(
          cm => cm.type === capStrategy || CAP_STRATEGY[0].type
        )}
        options={CAP_STRATEGY}
        labelKey='name'
        valueKey='type'
        onChange={cap => {
          const selectedCapStrategy =
            cap.type === undefined ? 'none' : cap.type;
          onChange({ capStrategy: selectedCapStrategy });
        }}
      />
    </Box>
  );
};

const getCapStrategyHeader = (campaignInfoPath, capStrategy) => {
  if (campaignInfoPath) {
    return 'Cap Strategy';
  } else if (capStrategy === 'revenue') {
    return 'Revenue Caps';
  } else if (capStrategy === 'conversion') {
    return 'Conversions Caps';
  } else {
    return 'Cap Strategy: None';
  }
};

const getConversionPercent = (cap, count = 0) => {
  return Math.round((count / cap) * 100);
};

const getStatusIcon = (count, cap) => {
  const capPercent = getConversionPercent(cap, count);
  if (!cap) {
    return <StatusInfo color='#00739D' />;
  }
  if (capPercent >= 100) {
    return <StatusWarning color='status-warning' />;
  } else {
    return <StatusGood color='status-ok' />;
  }
};

const getStatusMessage = (
  count,
  cap,
  capConfig,
  showShortLabels,
  capStrategy
) => {
  const deliveryCap =
    capStrategy === 'revenue'
      ? `$${count?.toFixed(2)}/$${cap?.toFixed(2)}`
      : `${count}/${cap}`;
  if (!cap) {
    return `No ${capConfig?.label?.toLowerCase()} cap set `;
  } else {
    return `${getConversionPercent(cap, count)}% (${deliveryCap}) of ${
      !showShortLabels ? capConfig?.label?.toLowerCase() + ' ' : ''
    }cap reached`;
  }
};

export const DeliveryCap = ({
  capStrategy,
  capConfig,
  capValue,
  setDeliveryCap,
  conversionCount = 0,
  isDirty,
  showShortLabels
}) => {
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (!isDirty) {
      setIsEditing(false);
    }
  }, [isDirty]);

  const opacity = isEditing ? 0.5 : 1;

  return (
    <Box
      direction='row'
      align={'start'}
      margin={{ bottom: 'small' }}
      wrap={true}
    >
      <Box direction='column'>
        <Box width='75px' direction='row'>
          <Text alignSelf='center'>{capConfig?.label}</Text>
          <Tip content={capConfig?.description}>
            <span style={{ padding: '3px' }}>
              <CircleQuestion size='small' />
            </span>
          </Tip>
        </Box>
      </Box>

      <Box>
        {capStrategy === 'conversion' && (
          <TextInput
            style={{ width: '100px' }}
            value={capValue || ''}
            min='0'
            type='number'
            title={capConfig?.description}
            onChange={e => {
              setDeliveryCap(e.target.value);
              setIsEditing(true);
            }}
          />
        )}
        {capStrategy === 'revenue' && (
          <TextInput
            icon={<Text>$</Text>}
            style={{ width: '110px' }}
            value={capValue || ''}
            step='0.01'
            min='0'
            type='number'
            title={capConfig?.description}
            onChange={e => {
              const newRevValue = parseFloat(
                parseFloat(e.target.value).toFixed(2)
              );

              setDeliveryCap(newRevValue);
              setIsEditing(true);
            }}
          />
        )}
      </Box>

      <Box
        direction='row'
        margin={{ left: 'small', top: 'xsmall' }}
        gap='xsmall'
        alignContent='center'
        style={{ opacity }}
        width='auto'
      >
        {getStatusIcon(conversionCount, capValue)}
        <Text alignSelf='center' size={showShortLabels ? 'small' : 'medium'}>
          {getStatusMessage(
            conversionCount,
            capValue,
            capConfig,
            showShortLabels,
            capStrategy
          )}
        </Text>
      </Box>
    </Box>
  );
};

const DeliveryCaps = ({
  onChange,
  caps = {},
  counts = {},
  isDirty,
  showShortLabels = false
}) => {
  const { pathname } = useLocation();
  const campaignInfoPath = !pathname.includes('adgroup');
  const { capStrategy } = caps;
  const [validationMessage, setValidationMessage] = useState('');

  const renderCapStrategy = () => {
    if (capStrategy === 'conversion') {
      return (
        <ConversionsStrategy
          onChange={onChange}
          isDirty={isDirty}
          showShortLabels={showShortLabels}
          caps={caps}
          counts={counts}
          setValidationMessage={setValidationMessage}
        />
      );
    } else if (capStrategy === 'revenue') {
      return (
        <RevenueStrategy
          onChange={onChange}
          isDirty={isDirty}
          showShortLabels={showShortLabels}
          caps={caps}
          counts={counts}
          setValidationMessage={setValidationMessage}
        />
      );
    } else {
      return;
    }
  };

  return (
    <Box>
      <Box direction='row' align='center' margin={{ bottom: 'small' }}>
        <Box>
          <Text weight='bold'>
            <Text margin={{ right: 'medium' }}>
              {getCapStrategyHeader(campaignInfoPath, capStrategy)}
            </Text>
          </Text>
        </Box>
        <Box>
          {campaignInfoPath &&
            (capStrategy === 'revenue' ? (
              <Tip content='Capping is based on the estimated revenue value and will not refresh after the reconciler runs.'>
                {capStrategySelect(onChange, capStrategy)}
              </Tip>
            ) : (
              capStrategySelect(onChange, capStrategy)
            ))}
        </Box>
      </Box>

      {validationMessage && (
        <MessageBox type='alert' message={validationMessage} />
      )}

      {renderCapStrategy()}
    </Box>
  );
};

DeliveryCaps.displayName = 'DeliveryCaps';

export default DeliveryCaps;
