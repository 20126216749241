import React, { useContext } from 'react';
import { Box, Text } from 'grommet';
import { FTSelect } from '../../../../../components/inputs';
import { CustomThemeContext } from './adunit-theme';
import {
  AD_UNIT_THEME,
  SELECT_FONT_SIZE,
  SELECT_DEFAULT_FONT_FAMILY,
  SELECT_DEFAULT_FONT_COLOR,
  SELECT_SUPPORTING_FONT_FAMILY,
  SELECT_SUPPORTING_FONT_COLOR
} from './adunit-theme-constant';
import ColorPicker from '../../../../../components/color-picker';
import { parseCSSProperties } from '../../../traffic-partner-utils';

const AdunitExpFontsColors = () => {
  const { state, dispatch, canEdit } = useContext(CustomThemeContext);

  return (
    <Box gap='small'>
      <FTSelect
        disabled={!canEdit}
        label='Font Size'
        placeholder='Select Font Size'
        size='small'
        options={AD_UNIT_THEME.fontSizes}
        labelKey='displayName'
        valueKey='field'
        value={AD_UNIT_THEME.fontSizes.find(
          option => option.field === state.fontSize.field
        )}
        onChange={option => {
          dispatch({
            type: SELECT_FONT_SIZE,
            payload: option
          });
        }}
      />
      <Text size='xsmall' color='dark-4'>
        *Set the font size to desired running copy size. All other text sizes
        (headlines, buttons, etc) will scale accordingly.
      </Text>
      <FTSelect
        disabled={!canEdit}
        label='Default Font Family'
        placeholder='Default Font Family'
        size='small'
        options={AD_UNIT_THEME.defaultFontFamilies}
        labelKey='displayName'
        valueKey='field'
        value={AD_UNIT_THEME.defaultFontFamilies.find(
          option => option.field === state.defaultFontFamily.field
        )}
        onChange={option => {
          dispatch({
            type: SELECT_DEFAULT_FONT_FAMILY,
            payload: option
          });
        }}
      />
      <ColorPicker
        label='Default Font Color'
        color={parseCSSProperties(state.defaultFontColor.css.properties)}
        onChange={newValue => {
          dispatch({
            type: SELECT_DEFAULT_FONT_COLOR,
            payload: AD_UNIT_THEME.defaultFontColor(newValue)
          });
        }}
        canEdit={canEdit}
      />
      <FTSelect
        disabled={!canEdit}
        label='Supporting Font Family'
        placeholder='Supporing Font Family'
        size='small'
        options={AD_UNIT_THEME.supportingFontFamilies}
        labelKey='displayName'
        valueKey='field'
        value={AD_UNIT_THEME.supportingFontFamilies.find(
          option => option.field === state.supportingFontFamily.field
        )}
        onChange={option => {
          dispatch({
            type: SELECT_SUPPORTING_FONT_FAMILY,
            payload: option
          });
        }}
      />
      <ColorPicker
        label='Supporting Font Color'
        color={parseCSSProperties(state.supportingFontColor.css.properties)}
        onChange={newValue => {
          dispatch({
            type: SELECT_SUPPORTING_FONT_COLOR,
            payload: AD_UNIT_THEME.supportingFontColor(newValue)
          });
        }}
        canEdit={canEdit}
      />
      <Text size='xsmall' color='dark-4'>
        *If only the default font family and color is desired, define the same
        values for the supporting font family and color. The same values for
        both is the common use case.
      </Text>
    </Box>
  );
};

export default AdunitExpFontsColors;
