import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Button, Layer, Box, Heading, ThemeContext, Text } from 'grommet';
import { Document, Trash } from 'grommet-icons';
import * as itemTypes from '../items/item-types';
import { closeBlock } from '../content-blocks/content-block.actions';
import { closePage } from '../pages/page.actions';
import { closeFlow } from '../flows/flow.actions';
import { closeSurvey } from '../survey/survey.actions';
import { closeUserAttribute } from '../user-attributes/user-attribute.actions';
import { closeReward } from '../rewards/reward.actions';
import { closeCampaign } from '../campaigns/performance/campaign.actions';
import { closeDataCampaign } from '../campaigns/data/data-campaign.actions';
import { closeJobCampaign } from '../campaigns/job/job-campaign.actions';
import { closeCallCampaign } from '../campaigns/call/call-campaign.actions';
import { closeRule } from '../rules/rule.actions';
import { closeAdvertiser } from '../advertisers/advertiser.actions';
import { closeIntegration } from '../advertisers/integration/integration.actions';
import { closeAudience } from '../audiences/audience.actions';
import { closeAudiencePriority } from '../audience-priorities/audience-priority.actions';
import { closeReport } from '../reports/report.actions';
import { closeDataDelivery } from '../campaigns/data/delivery/data-delivery.actions';
import { closeDataDeliveryGroup } from '../campaigns/data/delivery-groups/delivery-group.actions';
import { closeTrafficSource } from '../traffic/traffic-sources/traffic-source.actions';
import { closeTrafficPartner } from '../traffic/traffic-partners/traffic-partner.actions';
import { closeEmbeddedFlowConfig } from '../traffic/traffic-partners/embedded-flow-config/embedded-flow-config.actions';
import { closeCampaignPlacement } from '../campaigns/placement/campaign-placement.actions';
import { closeAdCreative } from '../campaigns/performance/ad-creatives/ad-creative.actions';
import { closeAdGroup } from '../campaigns/performance/ad-groups/ad-group.actions';
import { closeOfferSyndicationConfig } from '../traffic/traffic-partners/offer-syndication-config/offer-syndication-config.actions';
import { buildPathForitem } from '../routing-utils';
import { redirectToAdUnitTemplateSelector } from '../traffic/traffic-partners/embedded-flow-config/ad-unit-template-selector';

const DraftsIcon = ({ onClick, draftcount }) => {
  return (
    <ThemeContext.Consumer key={'draftsicon'}>
      {theme => (
        <Box
          style={{ position: 'relative', cursor: 'pointer' }}
          onClick={onClick}
          title='Drafts'
        >
          <Document />
          {draftcount > 0 && (
            <Box
              round='full'
              align='center'
              justify='center'
              style={{
                position: 'absolute',
                bottom: '-5px',
                right: '-5px',
                width: 15,
                height: 15,
                background: theme.global.colors['status-error'],
                color: theme.global.colors['white'],
                fontSize: 10,
                lineHeight: '15px'
              }}
            >
              {draftcount}
            </Box>
          )}
        </Box>
      )}
    </ThemeContext.Consumer>
  );
};

class DraftsPane extends Component {
  state = {
    drafts: [],
    windowShown: false
  };

  showDraftsWindow(show) {
    this.setState({ windowShown: show });
  }

  updateDrafts(state) {
    let drafts = [];

    Object.values(state).forEach((obj, index) => {
      if (obj.byId) {
        Object.values(obj.byId).forEach(item => {
          if (item.isDirty)
            drafts.push({ ...item, type: Object.keys(state)[index] });
        });
      }
    });
    this.setState({ drafts });
    return drafts;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.updateDrafts(this.props);
    }
  }

  componentDidMount() {
    this.updateDrafts(this.props);
  }

  render() {
    const { location } = this.props;
    const { pathname } = location;
    return (
      <>
        <DraftsIcon
          onClick={() => this.showDraftsWindow(true)}
          draftcount={this.state.drafts.length}
        />

        {this.state.windowShown && (
          <Layer
            position='right'
            full='vertical'
            modal={true}
            plain={false}
            animate={true}
            responsive={true}
            onClickOutside={() =>
              this.showDraftsWindow(!this.state.windowShown)
            }
          >
            <Box
              fill='vertical'
              pad={{ vertical: 'large' }}
              elevation='xlarge'
              width='medium'
            >
              <Box
                pad={{ horizontal: 'large', vertical: 'small' }}
                direction='row'
                align='center'
                justify='between'
              >
                <Heading level={2} margin={{ vertical: 'small' }}>
                  Drafts
                </Heading>
                <Button
                  disabled={this.state.drafts.length === 0}
                  plain
                  label='Discard All'
                  hoverIndicator={true}
                  onClick={() => {
                    this.state.drafts.forEach(d => {
                      this._discardItem(d.type, d.id);
                      // redirect to config selector if a new config is discarded
                      redirectToAdUnitTemplateSelector(d, pathname);
                    });
                  }}
                />
              </Box>
              <Box overflow='auto' fill='vertical'>
                {this.state.drafts.length > 0 ? (
                  this.state.drafts.map(draft => (
                    <ThemeContext.Consumer key={draft.id}>
                      {theme => (
                        <Button plain hoverIndicator={true}>
                          <Box
                            direction='row'
                            fill='horizontal'
                            pad={{ horizontal: 'medium' }}
                            justify='between'
                          >
                            <NavLink
                              to={buildPathForitem(draft)}
                              key={draft.id}
                              onClick={() => this.showDraftsWindow(false)}
                              style={{
                                color: 'inherit',
                                textDecoration: 'inherit',
                                maxWidth: '240px'
                              }}
                            >
                              <Box
                                pad={{ vertical: 'small' }}
                                align='center'
                                gap='small'
                                direction='row'
                              >
                                {itemTypes.itemTypeIcons[draft.type]}
                                <Text
                                  title={
                                    draft.type === itemTypes.AUDIENCE_PRIORITY
                                      ? `Audience Priority: ${draft.id}`
                                      : draft.type ===
                                        itemTypes.EMBEDDED_FLOW_CONFIG
                                      ? `Ad Unit Config: ${draft.id}`
                                      : draft.type ===
                                        itemTypes.OFFER_SYNDICATION_CONFIG
                                      ? `Offer Syndication Config: ${draft.id}`
                                      : draft.name
                                  }
                                  truncate
                                >
                                  {draft.type === itemTypes.AUDIENCE_PRIORITY
                                    ? `Audience Priority: ${draft.id}`
                                    : draft.type ===
                                      itemTypes.EMBEDDED_FLOW_CONFIG
                                    ? `Ad Unit Config: ${draft.id}`
                                    : draft.type ===
                                      itemTypes.OFFER_SYNDICATION_CONFIG
                                    ? `Offer Syndication Config: ${draft.id}`
                                    : draft.name}
                                </Text>
                              </Box>
                            </NavLink>
                            <Button
                              plain
                              icon={<Trash />}
                              onClick={() => {
                                this._discardItem(draft.type, draft.id);
                                // redirect to config selector if a new config is discarded
                                redirectToAdUnitTemplateSelector(
                                  draft,
                                  pathname
                                );
                              }}
                            ></Button>
                          </Box>
                        </Button>
                      )}
                    </ThemeContext.Consumer>
                  ))
                ) : (
                  <Box pad={{ horizontal: 'large', vertical: 'small' }}>
                    <Text>You have no unsaved drafts.</Text>
                  </Box>
                )}
              </Box>
            </Box>
          </Layer>
        )}
      </>
    );
  }

  _discardItem = (itemType, id) => {
    switch (itemType) {
      case itemTypes.PAGE:
        this.props.closePage({ id });
        break;
      case itemTypes.CONTENT_BLOCK:
        this.props.closeBlock({ id });
        break;
      case itemTypes.FLOW:
        this.props.closeFlow({ id });
        break;
      case itemTypes.SURVEY:
        this.props.closeSurvey({ id });
        break;
      case itemTypes.USER_ATTRIBUTE:
        this.props.closeUserAttribute({
          id
        });
        break;
      case itemTypes.REWARD:
        this.props.closeReward({
          id
        });
        break;
      case itemTypes.CAMPAIGN:
        this.props.closeCampaign({
          id
        });
        break;
      case itemTypes.DATA_CAMPAIGN:
        this.props.closeDataCampaign({
          id
        });
        break;
      case itemTypes.JOB_CAMPAIGN:
        this.props.closeJobCampaign({
          id
        });
        break;
      case itemTypes.CALL_CAMPAIGN:
        this.props.closeCallCampaign({
          id
        });
        break;
      case itemTypes.RULE:
        this.props.closeRule({
          id
        });
        break;
      case itemTypes.ADVERTISER:
        this.props.closeAdvertiser({
          id
        });
        break;
      case itemTypes.INTEGRATION:
        this.props.closeIntegration({
          id
        });
        break;
      case itemTypes.AUDIENCE:
        this.props.closeAudience({
          id
        });
        break;
      case itemTypes.AUDIENCE_PRIORITY:
        this.props.closeAudiencePriority({
          id
        });
        break;
      case itemTypes.REPORT:
        this.props.closeReport({
          id
        });
        break;
      case itemTypes.DATA_DELIVERY:
        this.props.closeDataDelivery({
          id
        });
        break;
      case itemTypes.DATA_DELIVERY_GROUP:
        this.props.closeDataDeliveryGroup({
          id
        });
        break;
      case itemTypes.TRAFFIC_SOURCE:
        this.props.closeTrafficSource({
          id
        });
        break;
      case itemTypes.TRAFFIC_PARTNER:
        this.props.closeTrafficPartner({
          id
        });
        break;
      case itemTypes.CAMPAIGN_PLACEMENT:
        this.props.closeCampaignPlacement({
          id
        });
        break;
      case itemTypes.EMBEDDED_FLOW_CONFIG:
        this.props.closeEmbeddedFlowConfig({
          id
        });
        break;
      case itemTypes.AD_CREATIVE:
        this.props.closeAdCreative({
          id
        });
        break;
      case itemTypes.AD_GROUP:
        this.props.closeAdGroup({
          id
        });
        break;
      case itemTypes.OFFER_SYNDICATION_CONFIG:
        this.props.closeOfferSyndicationConfig({
          id
        });
        break;
      default:
      //nop
    }
  };
}
const mapStateToProps = state => ({
  flow: state.flow,
  survey: state.survey,
  page: state.page,
  contentblock: state.contentblock,
  userattribute: state.userattribute,
  reward: state.reward,
  campaign: state.campaign,
  datacampaign: state.datacampaign,
  jobcampaign: state.jobcampaign,
  callcampaign: state.callcampaign,
  rule: state.rule,
  advertiser: state.advertiser,
  integration: state.integration,
  audience: state.audience,
  audiencepriority: state.audiencepriority,
  report: state.report,
  datadelivery: state.datadelivery,
  datadeliverygroup: state.datadeliverygroup,
  trafficsource: state.trafficsource,
  trafficpartner: state.trafficpartner,
  campaignplacement: state.campaignplacement,
  embeddedflowconfig: state.embeddedflowconfig,
  creative: state.creative,
  adgroup: state.adgroup,
  offersyndicationconfig: state.offersyndicationconfig
});

const mapDispatchToProps = {
  closeBlock,
  closeSurvey,
  closePage,
  closeFlow,
  closeUserAttribute,
  closeReward,
  closeCampaign,
  closeDataCampaign,
  closeJobCampaign,
  closeCallCampaign,
  closeRule,
  closeAdvertiser,
  closeIntegration,
  closeAudience,
  closeAudiencePriority,
  closeReport,
  closeDataDelivery,
  closeDataDeliveryGroup,
  closeTrafficSource,
  closeTrafficPartner,
  closeCampaignPlacement,
  closeEmbeddedFlowConfig,
  closeAdCreative,
  closeAdGroup,
  closeOfferSyndicationConfig
};
export default connect(mapStateToProps, mapDispatchToProps)(DraftsPane);
