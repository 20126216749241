import React, { useEffect } from 'react';
import { Box } from 'grommet';
import { DeliveryCap } from './delivery-caps';
import { DELIVERY_CAPS, getCapValidationMessage } from '../campaign-utils.js';

const ConversionsStrategy = ({
  onChange,
  isDirty,
  showShortLabels,
  caps,
  counts,
  setValidationMessage
}) => {
  const {
    dailyConversionCap,
    monthlyConversionCap,
    overallConversionCap,
    capStrategy
  } = caps;

  const {
    dailyConversionCap: dailyCapCount,
    monthlyConversionCap: monthlyCapCount,
    overallConversionCap: overallCapCount
  } = counts;

  useEffect(() => {
    setValidationMessage(
      getCapValidationMessage({
        dailyCap: dailyConversionCap,
        monthlyCap: monthlyConversionCap,
        overallCap: overallConversionCap
      })
    );
  }, [
    setValidationMessage,
    dailyConversionCap,
    monthlyConversionCap,
    overallConversionCap
  ]);

  return (
    <Box>
      <Box wrap={true} margin={{ top: 'medium' }}>
        <DeliveryCap
          capStrategy={capStrategy}
          capConfig={DELIVERY_CAPS.DAILY}
          capValue={dailyConversionCap}
          setDeliveryCap={cap => {
            cap = parseInt(cap) || 0;
            onChange({ dailyConversionCap: cap });
          }}
          conversionCap={dailyConversionCap}
          conversionCount={dailyCapCount}
          isDirty={isDirty}
          showShortLabels={showShortLabels}
        />
        <DeliveryCap
          capStrategy={capStrategy}
          capConfig={DELIVERY_CAPS.MONTHLY}
          capValue={monthlyConversionCap}
          setDeliveryCap={cap => {
            cap = parseInt(cap) || 0;
            onChange({ monthlyConversionCap: cap });
          }}
          conversionCap={monthlyConversionCap}
          conversionCount={monthlyCapCount}
          isDirty={isDirty}
          showShortLabels={showShortLabels}
        />
        <DeliveryCap
          capStrategy={capStrategy}
          capConfig={DELIVERY_CAPS.OVERALL}
          capValue={overallConversionCap}
          setDeliveryCap={cap => {
            cap = parseInt(cap) || 0;
            onChange({ overallConversionCap: cap });
          }}
          conversionCap={overallConversionCap}
          conversionCount={overallCapCount}
          isDirty={isDirty}
          showShortLabels={showShortLabels}
        />
      </Box>
    </Box>
  );
};

ConversionsStrategy.displayName = 'ConversionsStrategy';

export default ConversionsStrategy;
