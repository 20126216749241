import { createAction } from '@reduxjs/toolkit';
import * as itemTypes from '../items/item-types';
import * as nodeTypes from './canvas/node-types';
import { dropPositions } from './canvas/utils';
import {
  getItem as getItemInternal,
  saveItem as saveItemInternal
} from '../items/item.actions';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export const receiveFlow = createAction('receiveFlow');
export const setFlowIsBusy = createAction('setFlowIsBusy');
export const modifyFlow = createAction('modifyFlow');
export const closeFlow = createAction('closeFlow');

export const getNewBranch = (
  defaultCase = "window.ft.queryParams.condition=='true'",
  {
    conditionType = 'window.ft.queryParams.',
    values = ['condition', '==', 'true']
  } = {}
) => ({
  case: defaultCase,
  branchConfig: { conditionType, values },
  nodes: [getNode({ type: nodeTypes.STOP })],
  branchId: uuidv4()
});

const getNode = base => {
  const node = Object.assign({}, base);
  if (!node.nodeId) {
    node.nodeId = uuidv4();
    if (node.type === nodeTypes.CONDITION && !node.branches) {
      node.branches = [getNewBranch()];
    }
  }
  return node;
};

export const flowModel = (id, name, params) => ({
  id,
  name,
  type: itemTypes.FLOW,
  publishedLocations: [],
  nodes: [
    getNode({ type: nodeTypes.START }),
    getNode({ type: nodeTypes.STOP })
  ],
  campaignGroups: []
});

const removeNodeFromNodes = (nodes, node) => {
  if (Array.isArray(nodes)) {
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].nodeId === node.nodeId) {
        return nodes.splice(i, 1)[0];
      }
      if (Array.isArray(nodes[i].branches)) {
        for (let b = 0; b < nodes[i].branches.length; b++) {
          const removedNode = removeNodeFromNodes(
            nodes[i].branches[b].nodes,
            node
          );
          if (removedNode) {
            return removedNode;
          }
        }
      }
    }
  }
};

export const findNodeInNodes = (nodes, nodeId, isGoto = false) => {
  if (Array.isArray(nodes)) {
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].nodeId === nodeId && isGoto && nodes[i].goToNodeType) {
        return nodes[i];
      }
      if (nodes[i].nodeId === nodeId && !isGoto) {
        return nodes[i];
      }
      if (Array.isArray(nodes[i].branches)) {
        for (let b = 0; b < nodes[i].branches.length; b++) {
          const matchingNode = findNodeInNodes(
            nodes[i].branches[b].nodes,
            nodeId,
            isGoto
          );
          if (matchingNode) {
            return matchingNode;
          }
        }
      }
    }
  }
};

export const findGoToNodeInNodes = (nodes, selectedNodeId) => {
  if (Array.isArray(nodes)) {
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].linkedId === selectedNodeId) {
        return nodes[i];
      }
      if (Array.isArray(nodes[i].branches)) {
        for (let b = 0; b < nodes[i].branches.length; b++) {
          const matchingNode = findGoToNodeInNodes(
            nodes[i].branches[b].nodes,
            selectedNodeId
          );
          if (matchingNode) {
            return matchingNode;
          }
        }
      }
    }
  }
};

const insertNode = (nodes, node, targetNode, dropPosition) => {
  //if we are moving an existing node, remove the node from its original spot
  if (Array.isArray(nodes)) {
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].nodeId === targetNode.nodeId) {
        nodes.splice(
          dropPosition === dropPositions.AFTER ? i + 1 : i,
          dropPosition === dropPositions.INTO ? 1 : 0,
          node
        );
        return true;
      }
      if (Array.isArray(nodes[i].branches)) {
        for (let b = 0; b < nodes[i].branches.length; b++) {
          const hasInserted = insertNode(
            nodes[i].branches[b].nodes,
            node,
            targetNode,
            dropPosition
          );
          if (hasInserted) {
            return true;
          }
        }
      }
    }
  }
};

const cloneNodes = (nodes = []) => {
  const newNodes = cloneDeep(nodes);
  for (let i = 0; i < newNodes.length; i++) {
    if (Array.isArray(newNodes[i].branches)) {
      for (let b = 0; b < newNodes[i].branches[b].length; b++) {
        newNodes[i].branches[b].nodes = cloneNodes(
          newNodes[i].branches[b].nodes
        );
      }
    }
  }
  return newNodes;
};

export const addNode = (item, node, targetNode, dropPosition) => (
  dispatch,
  getState
) => {
  const newNodes = cloneNodes(item.nodes);

  let nodeToAdd;
  if (node.nodeId) {
    //moving an existing node
    nodeToAdd = removeNodeFromNodes(newNodes, node);
  } else {
    //dropping from the toolbar
    nodeToAdd = getNode({
      type: node.type,
      id: node.id
    });
  }

  //no target, add it to the end of the list (before the stop node)
  if (!targetNode) {
    newNodes.splice(newNodes.length - 1, 0, nodeToAdd);
  } else {
    //look for the targetNode recursively and insert at appropriate position
    insertNode(newNodes, nodeToAdd, targetNode, dropPosition);
  }

  dispatch(
    modifyFlow({
      id: item.id,
      newProperties: {
        nodes: newNodes,
        needsCommitMessage: true
      }
    })
  );
};

export const removeNode = (item, node) => (dispatch, getState) => {
  const newNodes = cloneDeep(item.nodes) || [];

  if (node.goToNodeType && node.type === nodeTypes.PAGE) {
    dispatch(
      updateNode(
        item,
        node.nodeId,
        {
          type: nodeTypes.STOP,
          nodeId: uuidv4(),
          goToNodeType: false,
          linkedId: null
        },
        node.goToNodeType
      )
    );
  } else {
    removeNodeFromNodes(newNodes, node);
    dispatch(
      modifyFlow({
        id: item.id,
        newProperties: {
          nodes: newNodes,
          needsCommitMessage: true
        }
      })
    );
  }
};

export const updateNode = (item, nodeId, newProperties, isGoto) => (
  dispatch,
  getState
) => {
  const newNodes = cloneDeep(item.nodes) || [];
  const matchingNode = findNodeInNodes(newNodes, nodeId, isGoto);
  if (matchingNode) {
    Object.assign(matchingNode, newProperties);

    dispatch(
      modifyFlow({
        id: item.id,
        newProperties: {
          nodes: newNodes,
          needsCommitMessage: true
        }
      })
    );
  }
};

// temporary - builds new node structure from old onPageComplete structure
const getPageNodeAndConditionals = (pageId, conditions = []) => {
  const result = [];

  if (conditions.length > 0) {
    const conditionalNode = getNode({
      type: nodeTypes.CONDITION,
      branches: []
    });

    for (let i = 0; i < conditions.length; i++) {
      conditionalNode.branches.push({
        case: conditions[i].case,
        nodes: [
          getNode({
            type: nodeTypes.PAGE,
            id: conditions[i].args[0]
          }),
          getNode({ type: nodeTypes.STOP })
        ],
        branchId: uuidv4()
      });
    }
    result.push(conditionalNode);
  }
  if (pageId) {
    result.push(
      getNode({
        type: nodeTypes.PAGE,
        id: pageId
      })
    );
  }

  return result;
};

const convertOldProgramJsonToNodes = programJson => {
  const program = JSON.parse(programJson);
  const nodes = [getNode({ type: nodeTypes.START })];

  if (program.initialState && program.initialState.fn === 'showPage') {
    nodes.push(
      ...getPageNodeAndConditionals(
        program.initialState.args && program.initialState.args[0],
        program.initialState.conditions
      )
    );
  }

  while (
    program.eventTransitions &&
    program.eventTransitions.onPageComplete[nodes[nodes.length - 1].id] &&
    program.eventTransitions.onPageComplete[nodes[nodes.length - 1].id].fn ===
      'showPage'
  ) {
    const currentNode =
      program.eventTransitions.onPageComplete[nodes[nodes.length - 1].id];

    nodes.push(
      ...getPageNodeAndConditionals(
        currentNode.args && currentNode.args[0],
        currentNode.conditions
      )
    );
  }
  nodes.push(getNode({ type: nodeTypes.STOP }));

  return nodes;
};

export const getItem = getItemInternal(
  flowModel,
  receiveFlow,
  setFlowIsBusy,
  item => {
    //transform programJson into usable nodes array
    if (
      item.programJson &&
      (!Array.isArray(item.nodes) || item.nodes.length < 2)
    ) {
      item.nodes = convertOldProgramJsonToNodes(item.programJson);
    }
  }
);

const generateProgramNodeMap = (nodes, nodeMap = {}) => {
  if (Array.isArray(nodes)) {
    for (let i = 0; i < nodes.length; i++) {
      const currentNode = nodes[i];

      if (currentNode.type === nodeTypes.CONDITION) {
        nodeMap[currentNode.nodeId] = {
          conditions: []
        };
        //if we have a subsequent node on the default branch, add it as the default condition
        if (i + 1 < nodes.length && nodes[i + 1].type !== nodeTypes.STOP) {
          if (nodes[i + 1]?.goToNodeType) {
            nodeMap[currentNode.nodeId].fn = 'goToNode';
            nodeMap[currentNode.nodeId].args = [nodes[i + 1].linkedId];
          } else {
            nodeMap[currentNode.nodeId].fn = 'goToNode';
            nodeMap[currentNode.nodeId].args = [nodes[i + 1].nodeId];
          }
        }

        // add goToNode for the next node if it is a stop node
        if (i + 1 < nodes.length && nodes[i + 1].type === nodeTypes.STOP) {
          nodeMap[currentNode.nodeId].fn = 'goToNode';
          nodeMap[currentNode.nodeId].args = [nodes[i + 1].nodeId];
        }

        for (let b = 0; b < currentNode.branches.length; b++) {
          if (
            Array.isArray(currentNode.branches[b].nodes) &&
            currentNode.branches[b].nodes.length > 0
          ) {
            const condition = {
              case: currentNode.branches[b].case,
              args: [
                currentNode.branches[b].nodes[0]?.goToNodeType
                  ? currentNode.branches[b].nodes[0].linkedId
                  : currentNode.branches[b].nodes[0].nodeId
              ]
            };
            if (condition.case === 'isFilter') {
              condition.case += `|${currentNode.branches[b].branchId}`;
            }
            nodeMap[currentNode.nodeId].conditions.push(condition);

            //recursively add child nodes
            generateProgramNodeMap(currentNode.branches[b].nodes, nodeMap);
          }
        }
      } else if (currentNode.type === nodeTypes.PAGE) {
        nodeMap[currentNode.nodeId] = {};

        //not just a placeholder node
        if (currentNode.id) {
          nodeMap[currentNode.nodeId].fn = 'showPage';
          nodeMap[currentNode.nodeId].args = [currentNode.id];
        }

        //back magic support
        if (currentNode.onBack) {
          //generate a key for this pseudo node
          const pseudoNodeId = uuidv4();

          //assocaite the page with the key
          nodeMap[pseudoNodeId] = {
            fn: 'showPage',
            args: [currentNode.onBack.id]
          };

          //assocaite the onBack handler on the current node with the pseudoNode
          nodeMap[currentNode.nodeId].onBack = {
            fn: 'goToNode',
            args: [pseudoNodeId]
          };
        }
      } else if (currentNode.type === nodeTypes.REFRESH_CONTEXT) {
        nodeMap[currentNode.nodeId] = {
          fn: nodeTypes.REFRESH_CONTEXT,
          args: []
        };
      } else if (currentNode.type === nodeTypes.EVENT_TRACKER) {
        nodeMap[currentNode.nodeId] = {
          fn: nodeTypes.EVENT_TRACKER,
          args: currentNode.args
        };
      }
      //if we have nodes after this node, add it as the onComplete gotonode
      if (
        currentNode.type !== nodeTypes.CONDITION &&
        currentNode.type !== nodeTypes.START
      ) {
        if (i + 1 < nodes.length && nodes[i + 1].type !== nodeTypes.STOP) {
          nodeMap[currentNode.nodeId].onComplete = {
            fn: 'goToNode',
            args: [nodes[i + 1].nodeId]
          };
        }
        if (currentNode?.goToNodeType) {
          if (i >= 1) {
            nodeMap[nodes[i - 1].nodeId].onComplete = {
              fn: 'goToNode',
              args: [currentNode.linkedId]
            };
          }
        }
      }
    }
  }
  return nodeMap;
};

const isInvalidNode = node => {
  switch (node.type) {
    case nodeTypes.EVENT_TRACKER:
      return (
        !Array.isArray(node.args) || !node.args.length || !node.args[0].length
      );
    case nodeTypes.CONDITION:
      return iterateNodes(node.branches, node => {
        const subConditions = node.filter?.include?.subConditions;
        if (node.case === 'isFilter') {
          if (!subConditions.length) return true;
          else {
            const invalidBranches = subConditions[0].subConditions.filter(
              sc => !sc.propertyValues?.length
            );
            return invalidBranches.length > 0;
          }
        }
      });
    default:
      return false;
  }
};

const getInvalidNodeMessage = node => {
  switch (node.type) {
    case nodeTypes.EVENT_TRACKER:
      return 'Event Tracker node is missing Event Attribute';
    case nodeTypes.CONDITION:
      return 'Condition node is missing a filter definition';
    default:
      return;
  }
};

//iterates through the nested nodes tree, if iterator returns anything, then it stops iterating and returns the result of the iterator
export const iterateNodes = (nodes, iterator) => {
  if (Array.isArray(nodes)) {
    for (let i = 0; i < nodes.length; i++) {
      const result = iterator(nodes[i]);
      if (typeof result !== 'undefined') {
        return result;
      }
      if (Array.isArray(nodes[i].branches)) {
        for (let b = 0; b < nodes[i].branches.length; b++) {
          const childResult = iterateNodes(
            nodes[i].branches[b].nodes,
            iterator
          );
          if (typeof childResult !== 'undefined') {
            return childResult;
          }
        }
      }
    }
  }
};

export const saveItem = saveItemInternal(
  modifyFlow,
  item => {
    if (!item.product) {
      return 'Product is required.';
    } else {
      const invalidMessage = iterateNodes(item.nodes, node => {
        if (isInvalidNode(node)) {
          return getInvalidNodeMessage(node);
        }
      });
      if (invalidMessage) return invalidMessage;
    }
  },
  item => {
    //transform nodes array back into programJson
    const programJson = {
      initialState: {
        fn: 'goToNode'
      }
    };

    //get initial state
    if (Array.isArray(item.nodes) && item.nodes.length > 2) {
      programJson.initialState.args = [item.nodes[1].nodeId];
    }

    programJson.nodes = generateProgramNodeMap(item.nodes);

    if (item.pages && Array.isArray(item.pages)) {
      item.pages.forEach(pageId => {
        programJson.nodes[pageId] = {
          fn: 'showPage',
          args: [pageId]
        };
      });
    }

    //generate filters on the programJson
    const filters = {};
    iterateNodes(item.nodes, node => {
      if (node.type === nodeTypes.CONDITION && Array.isArray(node.branches)) {
        node.branches.forEach(branch => {
          if (branch.case === 'isFilter') {
            filters[branch.branchId] = branch.filter;
          }
        });
      }
    });
    programJson.filters = filters;

    item.programJson = JSON.stringify(programJson);
  }
);

//campaignGroups: [
//   {
//     groupId: '123',
//     name: 'ben cool group',
//     groupItems: [
//       {
//         campaignId: '54354',
//         campaignType: 'jobCampaign',
//         trafficeDistribution: 0.33
//       }
//     ]
//   }
// ]

const removeCampaignFromCampaignGroup = (campaignGroups, campaignId) => {
  for (let i = 0; i < campaignGroups.length; i++) {
    const currentGroup = campaignGroups[i];

    if (Array.isArray(currentGroup.groupItems)) {
      const matchingCampaignIndex = currentGroup.groupItems.findIndex(
        cgi => cgi.campaignId === campaignId
      );
      if (matchingCampaignIndex !== -1) {
        currentGroup.groupItems.splice(matchingCampaignIndex, 1);

        //no omre campaigns in group - get rid of the group
        if (currentGroup.groupItems.length === 0) {
          campaignGroups.splice(i, 1);
        }
        break;
      }
    }
  }
};

export const removeCampaignsFromGroup = (item, campaignIds) => (
  dispatch,
  getState
) => {
  const newCampaignGroups = cloneDeep(item.campaignGroups) || [];
  campaignIds.forEach(cId =>
    removeCampaignFromCampaignGroup(newCampaignGroups, cId)
  );

  dispatch(
    modifyFlow({
      id: item.id,
      newProperties: {
        campaignGroups: newCampaignGroups
      }
    })
  );
};

export const addCampaignsToGroup = (item, group, groupItems) => (
  dispatch,
  getState
) => {
  const newCampaignGroups = cloneDeep(item.campaignGroups) || [];

  //remove from any existing groups
  groupItems.forEach(cgi =>
    removeCampaignFromCampaignGroup(newCampaignGroups, cgi.campaignId)
  );

  //find the group to add it to
  let matchingGroup = newCampaignGroups.find(
    cg => cg.groupId === group.groupId
  );
  if (!matchingGroup) {
    matchingGroup = group;
    matchingGroup.groupItems = [];
    newCampaignGroups.push(group);
  }

  //add it to the group's campaigns list
  matchingGroup.groupItems.push(...groupItems);

  dispatch(
    modifyFlow({
      id: item.id,
      newProperties: {
        campaignGroups: newCampaignGroups
      }
    })
  );
};

export const modifyGroup = (item, groupId, newProperties) => (
  dispatch,
  getState
) => {
  const newCampaignGroups = cloneDeep(item.campaignGroups) || [];

  let matchingGroup = newCampaignGroups.find(cg => cg.groupId === groupId);
  if (matchingGroup) {
    Object.assign(matchingGroup, newProperties);

    dispatch(
      modifyFlow({
        id: item.id,
        newProperties: {
          campaignGroups: newCampaignGroups
        }
      })
    );
  }
};
