import { createAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

export const addToast = createAction('addToast');
export const removeToast = createAction('removeToast');

export const addError = (primaryText, secondaryText) =>
  addToast({
    id: uuidv4(),
    primaryText,
    secondaryText,
    status: 'status-error'
  });

export const addSuccess = (primaryText, secondaryText) =>
  addToast({
    id: uuidv4(),
    isEphemeral: true,
    primaryText,
    secondaryText,
    status: 'status-ok'
  });

export const addMessage = (primaryText, secondaryText) =>
  addToast({
    id: uuidv4(),
    primaryText,
    secondaryText,
    status: 'status-ok'
  });

