import { Box, CheckBox, Text, TextInput } from 'grommet';
import React, { useState } from 'react';
import Modal from '../../../../components/modal';
import SimpleTable from '../../../../components/simple-table';
import { IMPLEMENTATION_TYPES_LABELS } from '../embedded-flow-config-grid';
import { authorizedFetch } from '../../../../utils';
import * as itemTypes from '../../../../items/item-types';

const getColumns = (
  gridData,
  selectedItems,
  setSelectedItems,
  adUnitTemplates
) => [
  {
    property: 'embeddedFlowConfig.sourceName',
    header: 'Source Name',
    render: embeddedFlowConfig => {
      return (
        <Box direction='row' align='center' gap='small'>
          <Text
            truncate
            title={`${embeddedFlowConfig.trafficSourceName} | ${embeddedFlowConfig.trafficSourceAffiliateId}`}
          >
            {`${embeddedFlowConfig.trafficSourceName} | ${embeddedFlowConfig.trafficSourceAffiliateId}`}{' '}
          </Text>
          {embeddedFlowConfig.default && <Text>Default</Text>}
        </Box>
      );
    },
    sort: 'trafficSourceAffiliateId.keyword',
    defaultOrder: 'asc'
  },
  {
    property: 'embeddedFlowConfig.implementationType',
    header: 'Implementation Type',
    render: props => {
      const getAdUnitTemplate = id => {
        return adUnitTemplates.find(template => template.id === id);
      };
      const implementationType =
        props.hasOwnProperty('adUnitTemplateId') &&
        props.adUnitTemplateId !== null
          ? `Ad Unit: ${getAdUnitTemplate(props.adUnitTemplateId)?.name ||
              'Custom Template'}`
          : `Flow: ${IMPLEMENTATION_TYPES_LABELS[props.implementationType]}`;

      return (
        <Box direction='row' align='center' gap='small'>
          <Text truncate title={implementationType}></Text>
          {implementationType}
        </Box>
      );
    },
    sort: 'implementationType.keyword',
    defaultOrder: 'asc'
  },
  {
    property: 'embeddedFlowConfig.assignedFlow',
    header: 'Assigned Flow',
    render: embeddedFlowConfig => {
      return (
        <Text title={embeddedFlowConfig.assignedFlow} truncate>
          {embeddedFlowConfig.assignedFlow}
        </Text>
      );
    },
    sort: 'assignedFlow.keyword',
    defaultOrder: 'asc'
  },
  {
    property: 'embeddedFlowConfig.revSharePerSession',
    header: 'Current Session Value',
    width: '170px',
    render: embeddedFlowConfig => {
      const revSession = embeddedFlowConfig?.revSharePerSession || 0;
      return <Text>{`$${revSession.toFixed(2)}`}</Text>;
    },
    sort: 'revSharePerSession',
    defaultOrder: 'asc'
  },
  {
    property: 'embeddedFlowConfig.revSharePercent',
    header: 'Revenue %',
    width: '100px',
    render: embeddedFlowConfig => {
      const revPercent = embeddedFlowConfig?.revSharePercent || 0;
      return <Text>{`${(revPercent * 100).toFixed(2)}%`}</Text>;
    },
    sort: 'revSharePercent',
    defaultOrder: 'asc'
  },
  {
    property: 'selected',
    header: (
      <>
        <Box flex direction='row' justify='center'>
          <CheckBox
            checked={selectedItems.length === gridData.length}
            onClick={event => {
              const isChecked = event.target.checked;
              if (isChecked) {
                setSelectedItems(gridData);
              } else {
                setSelectedItems([]);
              }
            }}
          />
        </Box>
      </>
    ),
    width: '80px',
    render: row => {
      return (
        <Box direction='row' align='center' justify='center'>
          <CheckBox
            id={row.id}
            checked={selectedItems.find(i => i.id === row.id) ? true : false}
            onChange={event => {
              const isChecked = event.target.checked;
              if (isChecked) {
                setSelectedItems([...selectedItems, row]);
              } else {
                setSelectedItems(selectedItems.filter(i => i.id !== row.id));
              }
            }}
          />
        </Box>
      );
    }
  }
];

const PartnerRevShareModal = ({
  setShowPlaceholdersModal,
  gridData,
  trafficPartnerId,
  addError,
  canEdit,
  adUnitTemplates
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [revSharePerSession, setRevSharePerSession] = useState('');
  const [revSharePercent, setRevSharePercent] = useState('');

  const applyFormula = async () => {
    const revObj = {};
    if (revSharePerSession) {
      revObj.revSharePerSession = revSharePerSession;
    }
    if (revSharePercent) {
      revObj.revSharePercent = revSharePercent / 100;
    }

    const payload = {
      embeddedFlowConfigIds: selectedItems.map(s => s.id),
      ...revObj
    };
    try {
      await authorizedFetch(
        `/api/${itemTypes.TRAFFIC_PARTNER}/${trafficPartnerId}/embeddedFlowConfig/revsharebulkupdate`,
        'POST',
        payload
      );
      gridData.forEach(item => {
        if (payload.embeddedFlowConfigIds.includes(item.id)) {
          item.revSharePercent = payload.revSharePercent;
          item.revSharePerSession = payload.revSharePerSession;
        }
      });
      setShowPlaceholdersModal(false);
    } catch (error) {
      setShowPlaceholdersModal(true);
      addError(error);
    }
  };

  return (
    <Modal
      header='Apply Formula'
      saveLabel='Apply'
      onClose={() => setShowPlaceholdersModal(false)}
      onSave={() => applyFormula()}
      canSave={
        !canEdit ||
        (selectedItems.length > 0 &&
          revSharePerSession >= 0 &&
          revSharePercent >= 0)
      }
      width='xlarge'
    >
      <Box pad='small'>
        <Box
          pad={{
            horizontal: 'medium'
          }}
        >
          <Text
            margin={{
              top: 'small',
              bottom: 'medium'
            }}
          >
            Select which ad unit configuration the revenue share formula should
            be applied to.
          </Text>
          <Box
            direction='row'
            gap='large'
            margin={{
              bottom: 'medium'
            }}
            align='center'
          >
            <Box direction='row' width='medium'>
              <Box width='medium' justify='center'>
                Adflow Session:
              </Box>
              <TextInput
                icon={<Text>$</Text>}
                type='number'
                value={revSharePerSession}
                title={'Adflow Session'}
                disabled={!canEdit}
                onChange={e => setRevSharePerSession(Number(e.target.value))}
              />
            </Box>
            <Box direction='row' width='medium'>
              <Box width='medium' justify='center'>
                Revenue Share:
              </Box>
              <TextInput
                icon={<Text>%</Text>}
                reverse
                type='number'
                value={revSharePercent}
                title={'Revenue Share'}
                disabled={!canEdit}
                onChange={e => setRevSharePercent(Number(e.target.value))}
              />
            </Box>
          </Box>
        </Box>
        <SimpleTable
          data={gridData}
          columns={getColumns(
            gridData,
            selectedItems,
            setSelectedItems,
            adUnitTemplates
          )}
          noItemsText={'No ad unit configurations found.'}
          keyBuilder={config => config.id}
        />
      </Box>
    </Modal>
  );
};

export default PartnerRevShareModal;
