import React, { useContext } from 'react';
import { Box, Text } from 'grommet';
import { FTSelect, FTTextInput } from '../../../../../components/inputs';
import { CustomThemeContext } from './adunit-theme';
import {
  SELECT_BUTTON_STYLE,
  SELECT_BUTTON_POSITIVE_BG_COLOR,
  SELECT_BUTTON_POSITIVE_TEXT_COLOR,
  SELECT_BUTTON_POSITIVE_BORDER_COLOR,
  SELECT_BUTTON_POSITIVE_BORDER_WIDTH,
  SELECT_BUTTON_POSITIVE_BORDER_STYLE,
  SELECT_BUTTON_NEGATIVE_BG_COLOR,
  SELECT_BUTTON_NEGATIVE_TEXT_COLOR,
  SELECT_BUTTON_NEGATIVE_BORDER_COLOR,
  SELECT_BUTTON_NEGATIVE_BORDER_WIDTH,
  SELECT_BUTTON_NEGATIVE_BORDER_STYLE,
  SELECT_BUTTON_NEUTRAL_BG_COLOR,
  SELECT_BUTTON_NEUTRAL_TEXT_COLOR,
  SELECT_BUTTON_NEUTRAL_BORDER_COLOR,
  SELECT_BUTTON_NEUTRAL_BORDER_WIDTH,
  SELECT_BUTTON_NEUTRAL_BORDER_STYLE,
  SELECT_CTA_BUTTON_ORDER,
  AD_UNIT_THEME,
  SELECT_BUTTON_POSITIVE_TEXT_STYLE,
  SELECT_BUTTON_NEGATIVE_TEXT_STYLE,
  SELECT_BUTTON_NEUTRAL_TEXT_STYLE,
  SELECT_BUTTON_POSITIVE_TEXT_WEIGHT,
  SELECT_BUTTON_NEGATIVE_TEXT_WEIGHT,
  SELECT_BUTTON_NEUTRAL_TEXT_WEIGHT
} from './adunit-theme-constant';
import ColorPicker from '../../../../../components/color-picker';
import { parseCSSProperties } from '../../../traffic-partner-utils';

const AdunitButtonSettings = () => {
  const { state, dispatch, canEdit } = useContext(CustomThemeContext);

  return (
    <Box gap='small'>
      <Box>
        <FTSelect
          disabled={!canEdit}
          label='Button Style'
          placeholder='Button Style'
          size='small'
          options={AD_UNIT_THEME.buttons.styles}
          labelKey='displayName'
          valueKey='field'
          value={AD_UNIT_THEME.buttons.styles.find(
            option => option.field === state.buttonStyle.field
          )}
          onChange={option => {
            dispatch({
              type: SELECT_BUTTON_STYLE,
              payload: option
            });
          }}
        />
        <Text size='xsmall' color='dark-4'>
          *All button active and hover states are set to an 80% opacity of the
          defined values below.
        </Text>
      </Box>
      <FTSelect
        disabled={!canEdit}
        label='Button Order'
        placeholder='Button Order'
        size='small'
        options={AD_UNIT_THEME.buttons.ctaOrder}
        labelKey='displayName'
        valueKey='field'
        value={AD_UNIT_THEME.buttons.ctaOrder.find(
          option => option.field === state.buttonCtaOrder.field
        )}
        onChange={option => {
          dispatch({
            type: SELECT_CTA_BUTTON_ORDER,
            payload: option
          });
        }}
      />
      <Text size='medium' weight='bold' color='brand500'>
        Positive Button Settings
      </Text>
      <FTSelect
        disabled={!canEdit}
        label='Button Text Style'
        placeholder='Button Text Style'
        size='small'
        options={AD_UNIT_THEME.buttons.positiveTextStyles}
        labelKey='displayName'
        valueKey='field'
        value={AD_UNIT_THEME.buttons.positiveTextStyles.find(
          option => option.field === state.buttonPositiveTextStyle.field
        )}
        onChange={option => {
          dispatch({
            type: SELECT_BUTTON_POSITIVE_TEXT_STYLE,
            payload: option
          });
        }}
      />
      <FTSelect
        disabled={!canEdit}
        label='Button Text Weight'
        placeholder='Button Text Weight'
        size='small'
        options={AD_UNIT_THEME.buttons.positiveTextWeight}
        labelKey='displayName'
        valueKey='field'
        value={AD_UNIT_THEME.buttons.positiveTextWeight.find(
          option => option.field === state.buttonPositiveTextWeight.field
        )}
        onChange={option => {
          dispatch({
            type: SELECT_BUTTON_POSITIVE_TEXT_WEIGHT,
            payload: option
          });
        }}
      />
      <ColorPicker
        label='Button BG Color'
        color={parseCSSProperties(state.buttonPositiveBgColor.css.properties)}
        onChange={newValue => {
          dispatch({
            type: SELECT_BUTTON_POSITIVE_BG_COLOR,
            payload: AD_UNIT_THEME.buttons.positiveBgColor(newValue)
          });
        }}
        canEdit={canEdit}
      />
      <ColorPicker
        label='Button Text Color'
        color={parseCSSProperties(state.buttonPositiveTextColor.css.properties)}
        onChange={newValue => {
          dispatch({
            type: SELECT_BUTTON_POSITIVE_TEXT_COLOR,
            payload: AD_UNIT_THEME.buttons.positiveTxtColor(newValue)
          });
        }}
        canEdit={canEdit}
      />
      <ColorPicker
        label='Button Border Color'
        color={parseCSSProperties(
          state.buttonPositiveBorderColor.css.properties
        )}
        onChange={newValue => {
          dispatch({
            type: SELECT_BUTTON_POSITIVE_BORDER_COLOR,
            payload: AD_UNIT_THEME.buttons.positiveBorderColor(newValue)
          });
        }}
        canEdit={canEdit}
      />
      <FTTextInput
        label='Button Border Size'
        value={parseCSSProperties(
          state.buttonPositiveBorderWidth.css.properties
        )}
        onChange={newValue => {
          dispatch({
            type: SELECT_BUTTON_POSITIVE_BORDER_WIDTH,
            payload: AD_UNIT_THEME.buttons.positiveBorderWidth(newValue)
          });
        }}
        disabled={!canEdit}
      />
      <FTSelect
        disabled={!canEdit}
        label='Button Border Style'
        placeholder='Button Border Style'
        size='small'
        options={AD_UNIT_THEME.buttons.positiveBorderStyle}
        labelKey='displayName'
        valueKey='field'
        value={AD_UNIT_THEME.buttons.positiveBorderStyle.find(
          option => option.field === state.buttonPositiveBorderStyle.field
        )}
        onChange={option => {
          dispatch({
            type: SELECT_BUTTON_POSITIVE_BORDER_STYLE,
            payload: option
          });
        }}
      />
      <Text size='medium' weight='bold' color='brand500'>
        Negative Button Settings
      </Text>
      <FTSelect
        disabled={!canEdit}
        label='Button Text Style'
        placeholder='Button Text Style'
        size='small'
        options={AD_UNIT_THEME.buttons.negativeTextStyles}
        labelKey='displayName'
        valueKey='field'
        value={AD_UNIT_THEME.buttons.negativeTextStyles.find(
          option => option.field === state.buttonNegativeTextStyle.field
        )}
        onChange={option => {
          dispatch({
            type: SELECT_BUTTON_NEGATIVE_TEXT_STYLE,
            payload: option
          });
        }}
      />
      <FTSelect
        disabled={!canEdit}
        label='Button Text Weight'
        placeholder='Button Text Weight'
        size='small'
        options={AD_UNIT_THEME.buttons.negativeTextWeight}
        labelKey='displayName'
        valueKey='field'
        value={AD_UNIT_THEME.buttons.negativeTextWeight.find(
          option => option.field === state.buttonNegativeTextWeight.field
        )}
        onChange={option => {
          dispatch({
            type: SELECT_BUTTON_NEGATIVE_TEXT_WEIGHT,
            payload: option
          });
        }}
      />
      <ColorPicker
        label='Button BG Color'
        color={parseCSSProperties(state.buttonNegativeBGColor.css.properties)}
        onChange={newValue => {
          dispatch({
            type: SELECT_BUTTON_NEGATIVE_BG_COLOR,
            payload: AD_UNIT_THEME.buttons.negativeBgColor(newValue)
          });
        }}
        canEdit={canEdit}
      />
      <ColorPicker
        label='Button Text Color'
        color={parseCSSProperties(state.buttonNegativeTextColor.css.properties)}
        onChange={newValue => {
          dispatch({
            type: SELECT_BUTTON_NEGATIVE_TEXT_COLOR,
            payload: AD_UNIT_THEME.buttons.negativeTxtColor(newValue)
          });
        }}
        canEdit={canEdit}
      />
      <ColorPicker
        label='Button Border Color'
        color={parseCSSProperties(
          state.buttonNegativeBorderColor.css.properties
        )}
        onChange={newValue => {
          dispatch({
            type: SELECT_BUTTON_NEGATIVE_BORDER_COLOR,
            payload: AD_UNIT_THEME.buttons.negativeBorderColor(newValue)
          });
        }}
        canEdit={canEdit}
      />
      <FTTextInput
        label='Button Border Size'
        value={parseCSSProperties(
          state.buttonNegativeBorderWidth.css.properties
        )}
        onChange={newValue => {
          dispatch({
            type: SELECT_BUTTON_NEGATIVE_BORDER_WIDTH,
            payload: AD_UNIT_THEME.buttons.negativeBorderWidth(newValue)
          });
        }}
        disabled={!canEdit}
      />
      <FTSelect
        disabled={!canEdit}
        label='Button Border Style'
        placeholder='Button Border Style'
        size='small'
        options={AD_UNIT_THEME.buttons.negativeBorderStyle}
        labelKey='displayName'
        valueKey='field'
        value={AD_UNIT_THEME.buttons.negativeBorderStyle.find(
          option => option.field === state.buttonNegativeBorderStyle.field
        )}
        onChange={option => {
          dispatch({
            type: SELECT_BUTTON_NEGATIVE_BORDER_STYLE,
            payload: option
          });
        }}
      />
      <Text size='medium' weight='bold' color='brand500'>
        Neutral Button Settings
      </Text>
      <FTSelect
        disabled={!canEdit}
        label='Button Text Style'
        placeholder='Button Text Style'
        size='small'
        options={AD_UNIT_THEME.buttons.neutralTextStyles}
        labelKey='displayName'
        valueKey='field'
        value={AD_UNIT_THEME.buttons.neutralTextStyles.find(
          option => option.field === state.buttonNeutralTextStyle.field
        )}
        onChange={option => {
          dispatch({
            type: SELECT_BUTTON_NEUTRAL_TEXT_STYLE,
            payload: option
          });
        }}
      />
      <FTSelect
        disabled={!canEdit}
        label='Button Text Weight'
        placeholder='Button Text Weight'
        size='small'
        options={AD_UNIT_THEME.buttons.neutralTextWeight}
        labelKey='displayName'
        valueKey='field'
        value={AD_UNIT_THEME.buttons.neutralTextWeight.find(
          option => option.field === state.buttonNeutralTextWeight.field
        )}
        onChange={option => {
          dispatch({
            type: SELECT_BUTTON_NEUTRAL_TEXT_WEIGHT,
            payload: option
          });
        }}
      />
      <ColorPicker
        label='Button BG Color'
        color={parseCSSProperties(state.buttonNeutralBGColor.css.properties)}
        onChange={newValue => {
          dispatch({
            type: SELECT_BUTTON_NEUTRAL_BG_COLOR,
            payload: AD_UNIT_THEME.buttons.neutralBgColor(newValue)
          });
        }}
        canEdit={canEdit}
      />
      <ColorPicker
        label='Button Text Color'
        color={parseCSSProperties(state.buttonNeutralTextColor.css.properties)}
        onChange={newValue => {
          dispatch({
            type: SELECT_BUTTON_NEUTRAL_TEXT_COLOR,
            payload: AD_UNIT_THEME.buttons.neutralTxtColor(newValue)
          });
        }}
        canEdit={canEdit}
      />
      <ColorPicker
        label='Button Border Color'
        color={parseCSSProperties(
          state.buttonNeutralBorderColor.css.properties
        )}
        onChange={newValue => {
          dispatch({
            type: SELECT_BUTTON_NEUTRAL_BORDER_COLOR,
            payload: AD_UNIT_THEME.buttons.neutralBorderColor(newValue)
          });
        }}
        canEdit={canEdit}
      />
      <FTTextInput
        label='Button Border Size'
        value={parseCSSProperties(
          state.buttonNeutralBorderWidth.css.properties
        )}
        onChange={newValue => {
          dispatch({
            type: SELECT_BUTTON_NEUTRAL_BORDER_WIDTH,
            payload: AD_UNIT_THEME.buttons.neutralBorderWidth(newValue)
          });
        }}
        disabled={!canEdit}
      />
      <FTSelect
        disabled={!canEdit}
        label='Button Border Style'
        placeholder='Button Border Style'
        size='small'
        options={AD_UNIT_THEME.buttons.neutralBorderStyle}
        labelKey='displayName'
        valueKey='field'
        value={AD_UNIT_THEME.buttons.neutralBorderStyle.find(
          option => option.field === state.buttonNeutralBorderStyle.field
        )}
        onChange={option => {
          dispatch({
            type: SELECT_BUTTON_NEUTRAL_BORDER_STYLE,
            payload: option
          });
        }}
      />
    </Box>
  );
};

export default AdunitButtonSettings;
