import React, { useState } from 'react';
import { Box, CheckBox, DateInput, Text, Tip } from 'grommet';

import { FTTextInput } from '../components/inputs';
import { DEFAULT_END_DATE_STRING } from './campaign-utils';

export const validateEpcOverride = item => {
  if (
    item.epcOverrideChecked &&
    item.epcOverrideExpiry === DEFAULT_END_DATE_STRING
  ) {
    return 'EPC Override requires an Expiry date.';
  }
};

export const EPCOverride = ({ onChange: handleChange, item }) => {
  const [active, setActive] = useState(
    item.epcOverrideExpiry
      ? new Date(item.epcOverrideExpiry) > new Date()
      : false
  );

  // Show the past expired date to the user to indicate that it has been set+expired
  // If the expiry is the default string, it hasn't been set yet
  // so we show a placeholder INSTEAD of today + 7 days to call user action
  // and in turn save the new expiry date
  const overrideEndDate =
    item.epcOverrideExpiry === DEFAULT_END_DATE_STRING
      ? ''
      : item.epcOverrideExpiry;

  return (
    <Box gap='small'>
      <Tip content='Set a manual EPC value to control how this campaign is sorted. The manual EPC value will expire after the selected date, resulting in the campaign using the newly established EPC value for sorting. Typically used to help establish an EPC value for a new performance campaign.'>
        <Box direction='row' gap='small'>
          <Text>EPC Manual Override</Text>
          <CheckBox
            toggle
            checked={active}
            onChange={event => {
              setActive(event.target.checked);
              handleChange({ epcOverrideChecked: event.target.checked });
              if (!event.target.checked) {
                handleChange({ epcOverrideExpiry: DEFAULT_END_DATE_STRING });
              }
            }}
          />
        </Box>
      </Tip>
      {active && (
        <Box direction='row' gap='small'>
          <Box justify='around' align='start' width='100px'>
            <Text>Manual EPC</Text>
            <Text>Expires after</Text>
          </Box>
          <Box gap='small' justify='start' width='80%'>
            <FTTextInput
              icon={<Text>$</Text>}
              type='number'
              min={0}
              value={item.epcOverride}
              onChange={newValue => handleChange({ epcOverride: newValue })}
            />
            <DateInput
              placeholder='Enter an Expiry Date'
              format='mm/dd/yyyy'
              onChange={({ value }) =>
                handleChange({ epcOverrideExpiry: value })
              }
              value={overrideEndDate}
              calendarProps={{ size: 'small' }}
              dropProps={{
                align: { top: 'bottom', left: 'left' },
                stretch: false
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
