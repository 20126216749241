import React from 'react';
import { Box, Text, Button } from 'grommet';
import { More } from 'grommet-icons';
import { isItemNew } from '../../../utils';
import { EMBEDONPAGE, PAGEOVERLAY } from './embedded-flow-config.actions';

const ProgressionOverlay = 'Progression | Overlay';
const ProgressionPageEmbed = 'Progression | Page Embed';

// Get the ad unit template by id
const getAdUnitTemplate = (adUnitTemplates, id) => {
  return adUnitTemplates.find(template => template.id === id);
};

// Get the legacy template
const getLegacyTemplate = embeddedFlowConfig => {
  switch (embeddedFlowConfig.implementationType) {
    case EMBEDONPAGE:
      return `${ProgressionPageEmbed}`;
    case PAGEOVERLAY:
      return `${ProgressionOverlay}`;
    default:
      return `${ProgressionOverlay}`;
  }
};

const renderTemplateName = (embeddedFlowConfig, templateType) => {
  if (
    !embeddedFlowConfig.implementationType ||
    embeddedFlowConfig.implementationType === PAGEOVERLAY ||
    embeddedFlowConfig.implementationType === EMBEDONPAGE
  ) {
    return `Configuration Template: Flow: ${templateType}`;
  } else {
    return `Configuration Template: ${templateType}`;
  }
};

const ConfigurationTemplateButton = ({
  adUnitTemplates,
  customAdUnitTemplates,
  embeddedFlowConfig,
  setOpenModal,
  setTemplateType = () => {}
}) => {
  const templates = [...adUnitTemplates, ...customAdUnitTemplates];
  const templateType =
    embeddedFlowConfig.adUnitTemplateId !== null
      ? `${getAdUnitTemplate(templates, embeddedFlowConfig.adUnitTemplateId)
          ?.name ||
          getAdUnitTemplate(templates, embeddedFlowConfig.adUnitTemplateId)
            ?.templateName}`
      : `${getLegacyTemplate(embeddedFlowConfig)}`;

  // Set the template type and remove any whitespace
  setTemplateType(templateType.replace(/\s+/g, ''));

  return (
    <Box margin={{ bottom: 'medium' }} gap='medium'>
      {embeddedFlowConfig.adUnitTemplateId === null && (
        <Box background='brand300' pad='small' round='small'>
          <Text color='#000'>
            <strong>Note:</strong>
            {isItemNew(embeddedFlowConfig.id)
              ? ` By selecting the Flow Configuration, you are not leveraging the most efficient or functional version of ad unit serving. Though it is not required, it is recommended to use an Ad Unit Configuration. To do so, click the discard button to pick a new template.`
              : ` By using the Flow Configuration, you are not leveraging the most efficient or functional version of ad unit serving. Switch to an Ad Unit Configuration for optimal performance.`}
          </Text>
        </Box>
      )}

      <Box direction='row' align='center'>
        <Text weight='bold' margin={{ right: 'small' }}>
          {renderTemplateName(embeddedFlowConfig, templateType)}
        </Text>
        <Button
          onClick={() => setOpenModal(true)}
          icon={<More size='small' />}
          style={{ padding: '5px 15px' }}
        />
      </Box>
    </Box>
  );
};

export default ConfigurationTemplateButton;
