import React from 'react';
import { Box, Button, Text } from 'grommet';
import { FormPrevious } from 'grommet-icons';
import RowItem from './row-item';
import HtmlPreview from './html-preview';
import {
  UserAttributeDetail,
  RewardDetail,
  FlowDetail,
  PageDetail,
  BlockDetail,
  AdvertiserDetail,
  CampaignDetail,
  DataCampaignDetail,
  DataDeliveryDetail,
  DataDeliveryGroupDetail,
  JobCampaignDetail,
  CallCampaignDetail
} from './preview-details/details-index.js';
import { cleanDate } from '../utils.js';
import * as itemTypes from '../items/item-types';
import RecentItemList from './recent-item-list';

const noPreviewIcon = type => (
  <Box
    align='center'
    justify='center'
    fill='horizontal'
    border='all'
    round='xsmall'
    pad='xsmall'
    height='small'
    overflow='auto'
  >
    <Box style={{ transform: 'scale(3)' }}>{itemTypes.itemTypeIcons[type]}</Box>
  </Box>
);

const parseSurveyData = json => {
  if (!json.pages || json.pages.length === 0) return '';

  let questions = 0;
  let attributes = [];

  for (let p in json.pages) {
    let items = json.pages[p].elements;
    if (Array.isArray(items)) {
      questions += items.length;
      items.forEach(q => {
        if (q.valueName) attributes.push(q.valueName);
      });
    }
  }

  return (
    <Box>
      <Text>Pages: {json.pages.length}</Text>
      <Text>Questions: {questions}</Text>
      <Text>Attributes: {attributes.join(', ')}</Text>
    </Box>
  );
};

const getPreviewContent = (item, onClosePreview) => {
  let preview = '';
  let detail;

  switch (item.type) {
    case itemTypes.FLOW:
      preview = noPreviewIcon(itemTypes.FLOW);
      detail = <FlowDetail item={item} />;
      break;

    case itemTypes.SURVEY:
      preview = noPreviewIcon(itemTypes.SURVEY);
      const json = item.surveyJson
        ? JSON.parse([item.surveyJson])
        : [{ pages: [] }];
      detail = (
        <Box>
          <Box margin={{ bottom: 'large' }}>{parseSurveyData(json)}</Box>
          <BlockDetail item={item} />
        </Box>
      );
      break;

    case itemTypes.PAGE:
    case itemTypes.CONTENT_BLOCK:
      preview = (
        <Box
          border='all'
          round='xsmall'
          pad='xsmall'
          height='small'
          overflow='auto'
        >
          <HtmlPreview html={item.html} css={item.css} />
        </Box>
      );

      if (item.type === itemTypes.PAGE) {
        detail = <PageDetail item={item} />;
      } else {
        detail = <BlockDetail item={item} />;
      }
      break;

    case itemTypes.USER_ATTRIBUTE:
      preview = noPreviewIcon(itemTypes.USER_ATTRIBUTE);
      detail = <UserAttributeDetail item={item} />;
      break;

    case itemTypes.REWARD:
      if (item.thumbnail) {
        preview = (
          <img
            src={item.thumbnail}
            alt='Thumbnail'
            style={{ maxWidth: '100%', maxHeight: '200px' }}
          />
        );
      }
      detail = <RewardDetail item={item} />;
      break;

    case itemTypes.ADVERTISER:
      preview = noPreviewIcon(itemTypes.ADVERTISER);
      detail = <AdvertiserDetail item={item} />;
      break;

    case itemTypes.AUDIENCE:
      preview = noPreviewIcon(itemTypes.AUDIENCE);
      detail = null;
      break;

    case itemTypes.CAMPAIGN:
      preview = noPreviewIcon(itemTypes.CAMPAIGN);
      detail = <CampaignDetail item={item} />;
      break;

    case itemTypes.REPORT:
      preview = noPreviewIcon(itemTypes.REPORT);
      detail = null;
      break;

    case itemTypes.DATA_CAMPAIGN:
      preview = noPreviewIcon(itemTypes.DATA_CAMPAIGN);
      detail = <DataCampaignDetail item={item} />;
      break;

    case itemTypes.DATA_DELIVERY:
      preview = noPreviewIcon(itemTypes.DATA_DELIVERY);
      detail = <DataDeliveryDetail item={item} />;
      break;

    case itemTypes.DATA_DELIVERY_GROUP:
      preview = noPreviewIcon(itemTypes.DATA_DELIVERY_GROUP);
      detail = <DataDeliveryGroupDetail item={item} />;
      break;

    case itemTypes.JOB_CAMPAIGN:
      preview = noPreviewIcon(itemTypes.JOB_CAMPAIGN);
      detail = <JobCampaignDetail item={item} />;
      break;

    case itemTypes.CALL_CAMPAIGN:
      preview = noPreviewIcon(itemTypes.CALL_CAMPAIGN);
      detail = <CallCampaignDetail item={item} />;
      break;

    case itemTypes.RULE:
      preview = noPreviewIcon(itemTypes.RULE);
      detail = null;
      break;

    default:
      break;
  }

  let {
    name,
    description,
    categories,
    version,
    dateModified,
    lastModifiedBy
  } = item;

  return (
    <Box data-testid={`${item.type} Preview Pane`} gap='small' direction='row'>
      {typeof onClosePreview === 'function' && (
        <Box justify='top'>
          <Button plain onClick={onClosePreview} icon={<FormPrevious />} />
        </Box>
      )}
      <Box
        margin={
          typeof onClosePreview === 'function'
            ? { vertical: 'medium' }
            : { vertical: 'medium', left: 'medium' }
        }
        width='100%'
      >
        {preview && (
          <Box pad={{ bottom: 'medium' }} flex={false}>
            {preview}
          </Box>
        )}
        {!detail ? (
          <Box flex={false}>
            <RowItem label='Name' value={name} />
            <RowItem label='Desc' value={description} truncate={false} />
            <RowItem
              label='Tags'
              value={Array.isArray(categories) && categories.join(', ')}
              truncate={false}
            />
            <RowItem
              label='Modified'
              value={dateModified && cleanDate(dateModified)}
            />
            <RowItem label='By' value={lastModifiedBy} />
            <RowItem label='Version' value={version} />
          </Box>
        ) : (
          detail
        )}
      </Box>
    </Box>
  );
};

const PreviewPane = ({ item, onClosePreview, recentItems, itemType }) => (
  <Box
    direction='column'
    style={{ width: '285px', position: 'relative' }}
    flex={{ shrink: 0 }}
    overflow='hidden'
    pad={
      typeof onClosePreview === 'function' && {
        left: 'small',
        right: 'xxsmall',
        vertical: 'small'
      }
    }
  >
    {item ? (
      getPreviewContent(item, onClosePreview)
    ) : Array.isArray(recentItems) ? (
      <RecentItemList recentItems={recentItems} itemType={itemType} />
    ) : (
      <Box align='center' justify='center' flex={true}>
        <Text>No item selected</Text>
      </Box>
    )}
  </Box>
);
export default PreviewPane;
