import React, { useState, useEffect } from 'react';
import { Box, Button, Text, CheckBox } from 'grommet';
import { CaretNext, CaretPrevious } from 'grommet-icons';
import { debounce } from 'lodash';
import useLocalStorageState from 'use-local-storage-state';

import * as itemTypes from '../items/item-types';
import Loader from './loader';
import SimpleTable from './simple-table';
import PreviewPane from '../previewpane/previewpane';
import { searchItemsByType, fetchItemsByType } from '../items/items.actions';
import Search from './search';
import { dateFormatOptions } from '../utils';
import { FTSelect } from './inputs';
import { getEnvironmentConfig } from '../environment-config/environment-config';

export const DEFAULT_COLUMNS = [
  {
    property: 'name',
    header: 'Name',
    sort: 'name.keyword',
    defaultOrder: 'asc'
  },
  {
    property: 'description',
    header: 'Description',
    sort: 'description.keyword',
    defaultOrder: 'asc'
  },
  {
    property: 'dateModified',
    header: 'Last Modified',
    sort: 'dateModified',
    defaultOrder: 'desc',
    render: ({ dateModified }) => (
      <Text truncate>
        {dateModified &&
          new Date(dateModified).toLocaleString('en-US', dateFormatOptions)}
      </Text>
    )
  }
];

export const defaultColumnFactory = (itemKey, columnsToInsert = []) => {
  return [
    {
      property: itemKey ? `${itemKey}.name` : 'name',
      header: 'Name',
      sort: 'name.keyword',
      defaultOrder: 'asc'
    },
    {
      property: itemKey ? `${itemKey}.description` : 'description',
      header: 'Description',
      sort: 'description.keyword',
      defaultOrder: 'asc'
    },
    ...columnsToInsert,
    {
      property: itemKey ? `${itemKey}.dateModified` : 'dateModified',
      header: 'Last Modified',
      sort: 'dateModified',
      defaultOrder: 'desc',
      render: ({ dateModified }) => (
        <Text truncate>
          {dateModified &&
            new Date(dateModified).toLocaleString('en-US', dateFormatOptions)}
        </Text>
      )
    }
  ];
};

export const PAGE_SIZE = 20;
const ALL_ITEMS = {
  type: 'all',
  name: 'All'
};
const FETCH_ITEMS_TYPE = [itemTypes.CAMPAIGN, itemTypes.JOB_CAMPAIGN];

const fetchItems = debounce(
  (
    itemTypes,
    filter,
    searchValue,
    currentPageNumber = 0,
    excludeIds,
    sortOrder,
    setItems,
    setTotalMatches,
    setIsLoadingItems,
    onError
  ) => {
    setItems([]);
    setIsLoadingItems(true);

    // TODO: Refactor once we move over to use new `search` endpoint for all entities
    const foundItemType = itemTypes.some(type =>
      FETCH_ITEMS_TYPE.includes(type)
    );
    if (foundItemType) {
      const itemType = itemTypes.join();
      const orderByField = Object.keys(sortOrder).join();
      const orderByAsc =
        Object.values(sortOrder).join() === 'asc' ? true : false;

      fetchItemsByType({
        itemType,
        query: searchValue,
        orderByField,
        orderByAsc,
        from: currentPageNumber * PAGE_SIZE,
        size: PAGE_SIZE
      })
        .then(({ items, totalMatches }) => {
          setItems(items);
          setTotalMatches(totalMatches);
          setIsLoadingItems(false);
        })
        .catch(err => {
          onError(
            'Unable to retrieve Items',
            err && err.message ? err.message : JSON.stringify(err)
          );
          setIsLoadingItems(false);
        });
    } else {
      searchItemsByType({
        itemTypes,
        additionalFilters: filter,
        query: searchValue,
        excludeIds,
        sort: sortOrder,
        from: currentPageNumber * PAGE_SIZE,
        size: PAGE_SIZE
      })
        .then(({ items, totalMatches }) => {
          setItems(items);
          setTotalMatches(totalMatches.value);
          setIsLoadingItems(false);
        })
        .catch(err => {
          onError(
            'Unable to retrieve Items',
            err && err.message ? err.message : JSON.stringify(err)
          );
          setIsLoadingItems(false);
        });
    }
  },
  250
);

const ItemGrid = ({
  elasticSortBuilder,
  onError,
  itemTypeFilter: propItemTypeFilter,
  itemTypes: propItemTypes,
  filter,
  excludeIds,
  headerButtons,
  testType,
  columns,
  onItemDoubleClick,
  keyBuilder,
  hidePreviewPane,
  hideSearchField,
  multiSelect,
  footerButtons,
  onItemsChange,
  gridData,
  selectedItems: propSelectedItems,
  previewPaneItem = r => r,
  currentlyAssignedItemId,
  gridDataLoading = false
}) => {
  const [isLoadingItems, setIsLoadingItems] = useState(true);
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItems, setSelectedItems] = useState(propSelectedItems ?? []);
  const [searchValue, setSearchValue] = useLocalStorageState(
    `_minion_${propItemTypes[0]}`,
    {
      defaultValue: ''
    }
  );
  const [itemTypeFilter, setItemTypeFilter] = useState(
    propItemTypeFilter ?? ALL_ITEMS
  );
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [sortField, setSortField] = useState('dateModified');
  const [sortDirection, setSortDirection] = useState('desc');
  const [totalMatches, setTotalMatches] = useState(0);
  const [gridColumns, setGridColumns] = useState(columns || DEFAULT_COLUMNS);
  const [elasticSort, setElasticSort] = useState(null);
  const [assignedItemName, setAssignedItemName] = useState('');
  const [recentlyOpenedList, setRecentlyOpenedList] = useState(null);

  const getSearchOrder = column => {
    if (sortField === column.sort) {
      return sortDirection === 'desc' ? 'asc' : 'desc';
    } else {
      return column.defaultOrder;
    }
  };

  const sortByColumn = column => {
    if (column.sort) {
      const sortDirection = getSearchOrder(column);

      setSortDirection(sortDirection);
      setSortField(column.sort);
      // If applicable update state so the following request will use
      // the custom sorting behaviour
      if (elasticSortBuilder) {
        setElasticSort(elasticSortBuilder(column.sort, sortDirection));
      }
    }
  };

  useEffect(() => {
    if (multiSelect) {
      const newGridColumns = [...gridColumns].filter(
        c => c.property !== 'selected'
      );
      setGridColumns([
        {
          property: 'selected',
          header: (
            <CheckBox
              checked={selectedItems.length === items.length}
              onClick={event => {
                const isChecked = event.target.checked;
                if (isChecked) {
                  setSelectedItems(items);
                } else {
                  setSelectedItems([]);
                }
              }}
            />
          ),
          width: '40px',
          render: row => {
            return (
              <Box direction='row' align='center' gap='small'>
                <CheckBox
                  id={keyBuilder(row)}
                  checked={
                    selectedItems.find(i => keyBuilder(i) === keyBuilder(row))
                      ? true
                      : false
                  }
                />
              </Box>
            );
          }
        },
        ...newGridColumns
      ]);
    }
  }, [selectedItems, items]);

  useEffect(() => {
    setSelectedItem(null);
    setSelectedItems(multiSelect && propSelectedItems ? propSelectedItems : []);

    // If we have a custom elastic sort in state, use that instead of the default sorting behaviour
    if (elasticSort) {
      fetchItems(
        itemTypeFilter === ALL_ITEMS ? propItemTypes : [itemTypeFilter.type],
        filter,
        searchValue,
        currentPageNumber,
        excludeIds,
        elasticSort,
        setItems,
        setTotalMatches,
        setIsLoadingItems,
        onError
      );
    } else {
      fetchItems(
        itemTypeFilter === ALL_ITEMS ? propItemTypes : [itemTypeFilter.type],
        filter,
        searchValue,
        currentPageNumber,
        excludeIds,
        { [sortField]: sortDirection },
        setItems,
        setTotalMatches,
        setIsLoadingItems,
        onError
      );
    }
  }, [
    itemTypeFilter,
    propItemTypes,
    filter,
    excludeIds,
    searchValue,
    currentPageNumber,
    sortField,
    sortDirection,
    setItems,
    setTotalMatches,
    setIsLoadingItems,
    elasticSort
  ]);

  useEffect(() => {
    if (typeof onItemsChange === 'function') {
      onItemsChange(items);
    }
    const environment = getEnvironmentConfig().environment;
    const type = propItemTypes[0];
    const itemHistoryJson =
      JSON.parse(
        localStorage.getItem(`_minion_${environment}_${type}_history_`)
      ) || [];
    if (localStorage.getItem(`_minion_${environment}_${type}_history_`)) {
      setRecentlyOpenedList(itemHistoryJson);
    }
  }, [items]);

  useEffect(() => {
    if (currentlyAssignedItemId && items?.length > 0) {
      if (
        currentlyAssignedItemId === 'None' ||
        currentlyAssignedItemId?.length === 0
      ) {
        setAssignedItemName('None');
      } else {
        const assignedItem = items.find(i => i.id === currentlyAssignedItemId);
        setAssignedItemName(assignedItem?.name ?? '');

        if (assignedItem == null) {
          let idFilter = [
            {
              term: {
                'id.keyword': currentlyAssignedItemId
              }
            }
          ];

          searchItemsByType({
            itemTypes: propItemTypes,
            additionalFilters: idFilter
          })
            .then(({ items }) => {
              if (items?.length > 0) {
                setAssignedItemName(items[0].name);
              }
            })
            .catch(err => {
              onError(
                'Unable to retrieve Items',
                err && err.message ? err.message : JSON.stringify(err)
              );
              setIsLoadingItems(false);
            });
        }
      }
    }
  }, [propItemTypes, currentlyAssignedItemId, onError, items]);

  return (
    <Box direction='column' flex>
      <Box
        direction='row'
        justify='between'
        align='center'
        pad={{ vertical: 'small' }}
      >
        {Array.isArray(propItemTypes) && propItemTypes.length > 1 && (
          <FTSelect
            value={itemTypeFilter}
            options={[
              ALL_ITEMS,
              ...propItemTypes.map(iT => {
                return {
                  type: iT,
                  name: itemTypes.itemTypeNames[iT]
                };
              })
            ]}
            labelKey='name'
            valueKey='type'
            onChange={newValue => {
              setItemTypeFilter(newValue);
            }}
          />
        )}
        <Box
          direction='row'
          justify='between'
          gap='small'
          align='center'
          fill
          flex
        >
          {headerButtons}
          {!hideSearchField && (
            <Box pad={{ left: 'small' }}>
              <Search
                data-testid='item-search'
                value={searchValue}
                onChange={newValue => {
                  setSearchValue(newValue);
                  setCurrentPageNumber(0);
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
      {currentlyAssignedItemId && (
        <Box margin='small' width='95%'>
          <Text title={assignedItemName} size='large' truncate>
            <strong>Item Currently Assigned: </strong>
            {assignedItemName}
          </Text>
        </Box>
      )}

      <Box justify='between' flex>
        {isLoadingItems ? (
          <Loader />
        ) : (
          <Box direction='row' flex>
            <SimpleTable
              testType={testType}
              columns={gridColumns}
              data={gridData ?? items}
              selectedItem={selectedItem}
              onSelectItem={
                multiSelect
                  ? item => {
                      if (
                        !selectedItems.find(
                          i => keyBuilder(i) === keyBuilder(item)
                        )
                      ) {
                        setSelectedItems([...selectedItems, item]);
                      } else {
                        setSelectedItems(
                          selectedItems.filter(
                            i => keyBuilder(i) !== keyBuilder(item)
                          )
                        );
                      }
                    }
                  : item => setSelectedItem(item)
              }
              onItemDoubleClick={multiSelect ? null : onItemDoubleClick}
              noItemsText='No items available'
              searchFilter={sortField}
              searchOrder={sortDirection}
              onHeaderClick={sortByColumn}
              keyBuilder={keyBuilder}
              loadingData={gridDataLoading || isLoadingItems}
            />
            {!hidePreviewPane && !multiSelect && (
              <PreviewPane
                item={previewPaneItem(selectedItem)}
                itemType={testType}
                items={items}
                recentItems={
                  Array.isArray(recentlyOpenedList) ? recentlyOpenedList : null
                }
                onClosePreview={
                  Array.isArray(recentlyOpenedList)
                    ? () => setSelectedItem(null)
                    : null
                }
              />
            )}
          </Box>
        )}
        <Box
          as='footer'
          pad='small'
          direction='row'
          justify='between'
          flex={{ shrink: 0 }}
        >
          <Box direction='row' gap='xsmall' align='center'>
            <Button
              plain
              disabled={currentPageNumber === 0}
              onClick={() => {
                const newPageNumber = currentPageNumber - 1;

                setCurrentPageNumber(newPageNumber);
              }}
            >
              <CaretPrevious size='small' />
            </Button>
            <Button
              plain
              disabled={(currentPageNumber + 1) * PAGE_SIZE > totalMatches}
              onClick={() => {
                const newPageNumber = currentPageNumber + 1;

                setCurrentPageNumber(newPageNumber);
              }}
            >
              <CaretNext size='small' />
            </Button>

            <Text size='small'>
              {totalMatches > PAGE_SIZE
                ? `Displaying matches ${currentPageNumber * PAGE_SIZE +
                    1}-${Math.min(
                    currentPageNumber * PAGE_SIZE + PAGE_SIZE,
                    totalMatches
                  )} of ${totalMatches}`
                : typeof totalMatches !== 'undefined' &&
                  `Displaying ${totalMatches} matches`}
            </Text>
          </Box>

          {typeof footerButtons === 'function' &&
            footerButtons({ selectedItem, selectedItems })}
        </Box>
      </Box>
    </Box>
  );
};

export default ItemGrid;
